.spinner {
  position: relative !important;

  transform: translateX(-50%) !important;

  :global(.spinner) {
    position: relative;
    margin-top: 0 !important;
  }
}
