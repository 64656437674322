@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AvailabilityForm
.list {
  margin-bottom: 1.2rem;
}

.item {
  &:not(:last-of-type) {
    // margin-bottom: 1.2rem;
  }
}

//---------------------------------------------AvailabilityForm###
