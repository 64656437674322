@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------SubscriptionCard
.container {
  @extend %signup_input;

  display: flex;
  gap: 3rem;
}

.detail {
  font-size: 1.3rem;
  color: #646464;
}

.action {
  margin-left: auto;
}
//---------------------------------------------SubscriptionCard###
