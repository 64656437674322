.how_head {
  margin-bottom: 1rem;
  max-width: 32rem;

  &.margin_mod {
    max-width: 100%;
    padding-bottom: 1.5rem;
  }
}

.how_img_w {
  margin: 0px -2.2rem -9.8rem 15%;
  width: 38rem;
  max-width: 100%;
  height: 24rem;
}

.how_img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}
