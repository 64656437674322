@include font_face('FuturaPTBook', 'FuturaPT', $font_path, 300);
@include font_face('FuturaPTMedium', 'FuturaPT', $font_path, 500);
@include font_face('FuturaPTDemi', 'FuturaPT', $font_path, 700);
@include font_face('FuturaPTBold', 'FuturaPT', $font_path, 900);

@include font_face('SegoeUI-Light', 'SegoeUI', $font_path, 300);
@include font_face('SegoeUI', 'SegoeUI', $font_path, 400);
@include font_face('SegoeUI-Bold', 'SegoeUI', $font_path, 700);

@include font_face('Sohne-Extraleicht', 'Sohne', $font_path, 200);
@include font_face('Sohne-Leicht', 'Sohne', $font_path, 300);
@include font_face('Sohne-Buch', 'Sohne', $font_path, 400);
@include font_face('Sohne-Halbfett', 'Sohne', $font_path, 500);
@include font_face('Sohne-Dreiviertelfett', 'Sohne', $font_path, 600);
@include font_face('Sohne-Fett', 'Sohne', $font_path, 700);
@include font_face('Sohne-Extrafett', 'Sohne', $font_path, 800);
@include font_face('Sohne-Kraftig', 'Sohne', $font_path, 900);