.popup {
  @extend %global_flex_block_row_nowrap_flex-start;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 2rem;

  background-color: rgba(26, 28, 29, 0.7);

  z-index: 1000;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.v2_mod {
    padding: 0;
  }

  &.active_mod {
    opacity: 1;
    pointer-events: initial;
  }
}

.popup_in {
  @extend %global_flex_block_row_nowrap_flex-start;
  max-width: 100%;
  width: 62.2rem;
  margin: auto;

  padding: 3rem 2.6rem;
  background-color: $white;
  border-radius: 0.8rem;

  &.side_mod {
    margin: 0 0 0 auto;
    width: 58rem;
    height: 100vh;
    border-radius: 0;

    @include bp($point_4 - 1) {
      width: 100%;
    }
  }

  &.pos_relative {
    position: relative;
  }
}

.popup_content {
  width: 100%;
  margin: auto;

  &.limit_mod {
    max-width: 42rem;
    margin: auto;
  }
}

.report_sent {
  padding-top: 10px;
  color: $green_1;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_form_item {
  & + & {
    margin-top: 0.8rem;
  }
}

.popup_form_list {
  margin: 0 0 2rem;
}

.popup_container {
  display: none;

  &.show {
    display: block;
  }
}

.popup_container_striked {
  display: none;

  &.show {
    display: block;
  }
}

.popup_close_btn {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: auto;
  cursor: pointer;
}

.popup_confirm_container {
  display: none;
  align-items: center;
  justify-content: center;

  &.showConfirm {
    display: flex;
  }
}

.popup_copy_confirm {
  font-size: 2em;
}
