@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------SignUpPopup
.popup {
  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
  z-index: 100;

  background-color: rgba(#000, 0.7);
}

.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

.container {
  height: 100%;

  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;

  background-color: $white;

  @include bp($point_2, min) {
    width: 83.6rem;
    padding: 6.6rem 3.4rem 5rem 5rem;
    margin-left: auto;
  }

  @include bp($point_3) {
    padding: 3rem $gl_mobile_indent;
  }

  .popup-enter-done &,
  .popup-enter-active & {
    transform: translateX(0%);
  }
}

.closeButton {
  @include bp($point_2, min) {
    display: none;
  }

  @include bp($point_3) {
    position: relative;

    display: block;
    width: 1.4rem;
    height: 1.4rem;
    margin-bottom: 3rem;
    margin-left: auto;

    &:before {
      position: absolute;
      top: -1rem;
      left: -1rem;
      right: -1rem;
      bottom: -1rem;

      content: '';
    }
  }
}

.btnWrap {
  margin-bottom: 2.6rem;

  border-bottom: 1px solid #EDEDED;
  border-top: 1px solid #EDEDED;
  padding: 25px 0;
}

.title {
  margin-bottom: 0.4rem;

  font-size: 2rem;
}

.description {
  margin-bottom: 1rem;

  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6;
  color: #9a9a9a;
  letter-spacing: -0.01rem;

  @include bp($point_4, min) {
    max-width: 90%;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  min-width: 16rem;

  font-size: 1.5rem;
  font-weight: 500;
  color: #1a1c1d;

  cursor: pointer;

  border: 1px solid #707070;
  background-color: $white;

  transition: all 0.3s;

  background-color: #030303;
  color: #fff;
  width: 100%;
  height: 52px;

  &:hover,
  &:focus {
    background-color: transparent;
    color: #030303;
  }
}

// table

.tableWrap {
  width: 100%;
  overflow-x: auto;

  @include bp($point_3) {
    width: calc(100% + #{$gl_mobile_indent} * 2);
    margin: 0 #{$gl_mobile_indent * -1};
    padding: 0 $gl_mobile_indent;
  }
}

.table {
  @include bp($point_3) {
    min-width: 80rem;
  }
}

.tableHead {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1.8rem;

  border-bottom: 1px solid #ededed;

  display: none;
}

.tableRow {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2.6rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #ededed;
  }
}

.tableItem {
  padding-right: 1rem;

  font-size: 1.8rem;
  font-weight: 500;
  color: #1d1f20;

  overflow: hidden;

  font-size: 16px;
  line-height: 22px;

  &:nth-child(1) {
    width: 15%;
    width: 20%;
  }

  &:nth-child(2) {
    width: 20.6%;
    width: 27%;
  }

  &:nth-child(3) {
    width: 21%;
    width: 25%;
  }

  &:nth-child(4) {
    width: 13%;
    width: 28%;
  }

  &:nth-child(5) {
    width: 10%;
  }

  &:nth-child(6) {
    width: 12%;
  }

  &:nth-child(7) {
    width: 8.4%;
  }

  &.tableItemV2 {
    font-size: 1.5rem;
    color: #acacac;
  }
}