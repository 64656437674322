// menu

.result_menu_list {
  @include bp($point_3) {
    display: flex;
    overflow-y: auto;
    margin: 0 -1.6rem;
    padding: 0 1.6rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.result_menu_item {
  position: relative;
  flex-shrink: 0;

  @include bp($point_2, min) {
    padding: 0 0 1.6rem;

    & + & {
      margin: 1.6rem 0 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 1.6rem;
      right: 1.6rem;

      height: 1px;
      background-color: #eee;
    }
  }

  &:last-child {
    padding: 0;

    &::after {
      display: none;
    }
  }
}

.result_menu_link {
  @extend %global_flex_block_column_nowrap_center_stretch;

  position: relative;

  min-height: 7.2rem;
  padding: 1rem 2.2rem;

  border-radius: 0.6rem;
  background-color: transparent;

  transition: all 0.3s;

  &.active_mod,
  &:hover,
  &:focus {
    background-color: $gray_6;
    text-decoration: none;
  }

  &.account_mod {
    padding-left: 12rem;

    &.active_mod,
    &:hover,
    &:focus {
      background-color: transparent;
    }

    @include bp($point_3) {
      padding: 2rem 0;
      border-bottom: 1px solid $gray;

      .result_menu_item:first-child & {
        padding-top: 0;
      }
    }
  }

  @include bp($point_3) {
    min-height: 0;
    height: 100%;
    padding: 1rem 4rem 1rem 2rem;
  }
}

.result_menu_title {
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: $black;

  .result_menu_link.active_mod &,
  .result_menu_link:hover &,
  .result_menu_link:focus & {
    color: $green_1;
  }

  @include bp($point_5) {
    margin-top: 0.6rem;
  }
}

.result_menu_text {
  font-size: 1.4rem;
  color: $lilac;

  opacity: 0;
  transition: all 0.3s;

  .result_menu_link.active_mod &,
  .result_menu_link:hover &,
  .result_menu_link:focus & {
    opacity: 1;

    @include bp($point_3) {
      display: block;
    }
  }

  .result_menu_link.account_mod & {
    opacity: 1;
  }

  @include bp($point_3) {
    display: none;
  }
}

.result_menu_count {
  @extend %global_flex_block_row_nowrap_center_center;

  position: absolute;

  min-width: 3rem;
  height: 2rem;

  font-size: 1.4rem;
  font-weight: 700;
  color: $white;

  background-color: #28c345;
  border-radius: 1.2rem;
  text-align: center;

  opacity: 0;
  transition: all 0.3s;

  @include bp($point_2, min) {
    top: 1.4rem;
    right: 2.2rem;
  }

  @include bp($point_3) {
    top: 1rem;
    right: 1.2rem;
  }

  .result_menu_link.active_mod &,
  .result_menu_link:hover &,
  .result_menu_link:focus & {
    opacity: 1;
  }
}

// list

.result_list {
  @extend %global_flex_block_row_wrap_flex-start;

  margin: 0 -3.2rem -2.6rem 0;
}

.result_item_wrap {
  width: 33.33%;
  padding: 0 3.2rem 2.6rem 0;

  @include bp($point_4 - 1) {
    width: 50%;
  }

  @include bp($point_6) {
    width: 100%;
  }
}

.result_item {
  display: block;
  background-color: $white;
  border-radius: 1.2rem;
  overflow: hidden;

  transition: all 0.3s;

  @include bp($point_3) {
    box-shadow: 0 0 0.6rem rgba($black, 0.25);
  }

  &:hover,
  &:focus {
    background-color: $gray;
    text-decoration: none;
  }
}

.result_img_wrap {
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 56.66%;
  }
}

.result_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.result_content {
  padding: 1.6rem 1.8rem;
}

.result_title {
  margin: 0 0 0.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: $black_2;
}

.result_info {
  @extend %global_flex_block_row_wrap_flex-start;
}

.result_info_item {
  position: relative;

  padding: 0 1rem 0 0;
  margin: 0 1rem 0 0;
  font-size: 1.4rem;
  color: $lilac;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;

    width: 2px;
    height: 2px;
    margin: -1px 0 0;

    border-radius: 50%;
    background-color: currentColor;
  }
}
