.talk_col {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.talk_col_in {
  flex-grow: 1;
  overflow-y: auto;

  @include bp($point_3) {
    margin: 0 -2rem;
    padding: 4rem 2rem 0rem;
  }

  @include bp($point_5) {
    margin: 0 -2rem;
    padding: 1rem 2rem 0rem;
  }
}

.talk_content {
  &.v2_mod {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.talk_wrap {
  margin-top: 0;
  padding: 2em 1em 1em 0.8em;
  max-height: 50vh;
  overflow-y: scroll;
}

.talk_item {
  @extend %global_flex_block_column_wrap_flex-start;

  //overflow-wrap: break-word;
  //word-wrap: break-word;
  word-break: break-word;

  @include bp($point_2, min) {
    width: 65%;
  }

  @include bp($point_3) {
    width: 100%;
  }

  &.v2_mod {
    align-items: flex-end;
    align-content: flex-end;
    margin-left: auto;
  }

  & + & {
    margin-top: 0.8rem;

    @include bp($point_4 - 1) {
      margin: 1.2rem 0 0;
    }
  }
}

.talk_main {
  @extend %global_flex_block_row_nowrap_flex-start;
}

.talk_avatar {
  position: relative;
  width: 3.4rem;
  height: 3.4rem;
  margin: 0 0.8rem 0 0;

  &.online_mod {
    &:after {
      content: '';
      position: absolute;
      bottom: -0.4rem;
      right: -0.4rem;

      width: 1.2rem;
      height: 1.2rem;

      background-color: #28c345;
      border-radius: 50%;
      border: 2px solid $white;
    }
  }

  .talk_item.v2_mod & {
    order: 2;
    margin: 0 0 0 0.8rem;

    &:after {
      right: auto;
      left: -0.4rem;
    }
  }
}

.talk_head {
  @extend %global_flex_block_row_nowrap_flex-start;

  margin: 0 0 0.6rem;
  padding: 0 0 0 0.4rem;

  .talk_item.v2_mod & {
    justify-content: flex-end;
    padding: 0;
  }
}

.talk_name {
  margin: 0 0.6rem 0 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: $black_2;

  .talk_item.v2_mod & {
    order: 2;
    margin: 0 0 0 0.6rem;
  }
}

.talk_time {
  font-size: 1.4rem;
  color: $lilac;
}

.talk_message {
  padding: 0.8rem 2.2rem 0.8rem 1.6rem;

  font-size: 1.4rem;
  color: $black_2;

  background-color: #f3f4f9;
  border-radius: 1.8rem;
  border-top-left-radius: 0;

  width: fit-content;
  min-width: fit-content;
  max-width: 50%;

  .talk_item.v2_mod & {
    padding: 0.8rem 1.6rem 0.8rem 2.2rem;

    color: $white;

    border-radius: 1.8rem;
    border-top-right-radius: 0;
    background-color: $green_1;
  }
}

// event

.talk_event {
  @extend %global_flex_block_row_nowrap_flex-start_center;
  padding: 2rem 3rem 2.4rem;
  margin: 2.6rem 0 0;
  min-width: 37.6rem;
  max-width: 100%;

  border: 1px solid $gray;
  border-radius: 1rem;

  @include bp($point_4 - 1) {
    min-width: 0;
  }

  &:first-child {
    margin: 0;
  }
}

.talk_event_icon {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 4.2rem;
  height: 4.8rem;
  margin: 0 1.2rem 0 0;
  flex-shrink: 0;

  color: #fa6400;

  background-color: rgba(246, 147, 62, 0.15);
  border-radius: 0.8rem;
}

.talk_event_title {
  margin: 0 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: $black_2;
}

// talk gallery

.talk_gallery {
  @extend %global_flex_block_row_nowrap_flex-start;
  margin: 0.8rem -0.8rem -0.8rem 4.2rem;

  .talk_item.v2_mod & {
    justify-content: flex-end;
    margin: 0.8rem 3.4rem -0.8rem -0.8rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.talk_gallery_item {
  padding: 0 0.8rem 0.8rem 0;
}

.talk_gallery_img_wrap {
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 1.2rem;
  overflow: hidden;

  .talk_gallery_item:first-child:last-child & {
    width: 32.6rem;
    height: 14.6rem;
    max-width: 100%;

    @include bp($point_4 - 1) {
      width: 24rem;
      height: 12rem;
    }
  }
}

.talk_gallery_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

// talk start

.talk_start {
  padding: 0 0 3.8rem;
  border-bottom: 1px solid #eee;

  &.bottom_mod {
    margin: 3.8rem 0 0;
    padding: 6.6rem 0 0;
    border-top: 1px solid #eee;
    border-bottom: none;
  }
}

.talk_start_in {
  width: 44.4rem;
  max-width: 100%;
  margin: 0 auto;

  &.active_call_margin {
    margin-top: 3em;
  }
}

.talk_start_img_wrap {
  width: 22rem;
  height: 12.2rem;
  margin: 0 auto 3rem;
}

.talk_start_img {
  display: block;
  width: 100%;
  height: 100%;
}

.talk_start_btns {
  @extend %global_flex_block_row_wrap_center;

  margin: 1.4rem 0 0;
}

.talk_start_btn_item {
  & + & {
    margin-left: 1.4rem;
  }
}

// talk send

.talk_send {
  margin: auto -2rem 0;
  padding: 1.8rem 2rem 0;
  border-top: 1px solid $gray;

  &.join_mod {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: none;
    padding: none;
  }
}

.talk_send_in {
  position: relative;
}

.talk_send_controls {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: absolute;
  top: 50%;
  right: 0;
  margin: -2.2rem 0 0;
}

.talk_btn {
  @extend %global_flex_block_row_nowrap_center_center;

  margin: 30% 40% 0 0;
  width: 6.4rem;
  height: 4.4rem;

  color: $white;

  background-color: $green_1;
  border-radius: 50%;

  transition: all 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($lilac_2, 10%);
  }
}

.talk_file {
  position: relative;
  margin: 0 1.2rem 0 0;
}

.talk_file_label {
  @extend %global_flex_block_row_nowrap_center_center;

  position: relative;

  width: 4.4rem;
  height: 4.4rem;

  color: $lilac_2;

  border: 2px solid $lilac_2;
  border-radius: 50%;

  transition: all 0.3s;
  cursor: pointer;

  .talk_file:hover &,
  .talk_file:focus & {
    background-color: $lilac_2;
    color: $white;
  }
}

.talk_file_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

// bottom nav

.bottom_nav {
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 2.4rem 1.6rem;
  //margin: 0 -1.6rem;
  background-color: $white;
  z-index: 5;

  // will-change:transform;
  // transform: translateZ(0);

  box-shadow: 0 -2rem 4rem rgba(153, 155, 168, 0.1);
}

.bottom_nav_list {
  @extend %global_flex_block_row_nowrap_center_center;
}

.bottom_nav_item {
  & + & {
    margin: 0 0 0 2.6rem;
  }
}

.bottom_nav_link {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 4.4rem;
  height: 4.4rem;

  color: $lilac;
  border-radius: 4px;

  &.active_mod {
    background-color: rgba(107, 89, 204, 0.1);
  }

  &.selected_mod {
    color: $purple_2;
  }
}

// banner

.talk_banner {
  margin: 0 0 3rem;
  padding: 2.7rem 1rem 0;
  border-radius: 1.2rem;
  background-color: $lilac_2;
}

.talk_banner_title {
  margin: 0 0 0.4rem;

  font-size: 1.8rem;
  font-weight: 700;
  color: $white;
  text-align: center;
}

.talk_banner_text {
  margin: 0 0 1rem;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.talk_banner_img_wrap {
  margin: 0 -1rem;
}

.talk_banner_img {
  display: block;
  width: 100%;
}

.dashboard_strike {
  width: 100%;
  font-size: 1.4rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $gray;

  @include bp($point_2 - 1) {
    padding-top: 1.5rem;
  }
}
