@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_project_extends.scss';

.select {
  position: relative;
  width: 100%;
  z-index: 20;

  :global(.select__control) {
    border-radius: 0;
    border: 1px solid $gray_4;

    @include bp($point_2, min) {
      height: 5.3rem;
    }

    @include bp($point_3) {
      height: 4.3rem;
    }
  }

  :global(.select__single-value),
  :global(.select__placeholder) {
    font-weight: 300;
    color: #5d5d5d;

    @include bp($point_2, min) {
      font-size: 1.5rem;
    }

    @include bp($point_3) {
      font-size: 1.3rem;
    }
  }

  :global(.select__indicators) {
    margin-left: auto;
  }

  :global(.select__indicator-separator) {
    display: none;
  }

  :global(.select__dropdown-indicator) {
    position: relative;

    // margin-right: 1.8rem;

    svg {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1rem 0.7rem 0 0.7rem;
      border-color: #7e7e7e transparent transparent transparent;
      transform: translate(-50%, -50%);
    }
  }

  :global(.select__option) {
    font-size: 1.5rem;
    font-weight: 300;
    color: #5d5d5d;
  }

  :global(.select__option--is-selected) {
    background-color: #eee;
  }

  &.selectV2 {
    :global(.select__control) {
      height: 3.4rem;
      min-height: 3.4rem;
    }

    :global(.select__indicators) {
      display: none;
    }

    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 1.3rem;
      font-weight: 400;
      color: $blue_3;
    }

    :global(.select__value-container) {
      flex: unset;
    }
  }

  &.selectV3 {
    :global(.select__control) {
      height: 5.4rem;
      min-height: 5.4rem;
    }

    :global(.select__indicators) {
      display: none;
    }

    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 1.3rem;
      font-weight: 400;
      color: $blue_3;
    }

    :global(.select__value-container) {
      flex: unset;
    }

    :global(.select__control) {
      border-color: $blue_3;
    }
  }

  &.selectV4 {
    :global(.select__single-value),
    :global(.select__placeholder) {
      color: $green_3;
    }
  }
}

.controller {
  display: flex;
  align-items: center;
  width: 100%;

  @include bp($point_2, min) {
    padding: 0 1.6rem;
  }

  @include bp($point_3) {
    padding: 0 1rem;
  }
}

.controllerIcon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.6rem;
}

.timepicker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1.6rem;
}

.timepickerIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: -0.4rem;
  color: $blue_3;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.defaultSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1.6rem;
}
