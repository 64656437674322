@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.section {
  color: $white;

  padding-left: var(--gl-block-indent);
  padding-right: var(--gl-block-indent);

  @include bp($point_2, min) {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  @include bp($point_3) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.logout {
  position: absolute;
  z-index: 5;

  @include bp($point_2, min) {
    top: 3.4rem;
    right: 5.6rem;
  }

  @include bp($point_3) {
    top: 2rem;
    right: var(--gl-block-indent);
  }
}

.head {
  position: relative;

  text-align: center;

  &:not(:last-child) {
    @include bp($point_4, min) {
      margin-bottom: 3.2rem;
    }

    @include bp($point_4 - 1) {
      margin-bottom: 6rem;
    }
  }
}

.logo {
  display: block;
  width: 4rem;
  height: 2.6rem;
  margin-left: auto;
  margin-right: auto;

  color: inherit;
  opacity: 0.187;

  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }
}

.title {
  font-size: 2rem;
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

.date {
  font-size: 1.5rem;
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 1.8rem;
  }
}

.share {
  position: relative;

  display: flex;
  align-items: center;

  min-width: 22.5rem;
}

.spinner {
  :global(.spinner) {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
  }
}

.tabs {
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.8rem;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}

.button {
  font-size: 1.5rem;
  font-weight: 450;
  color: inherit;
  color: #919191;

  cursor: pointer;
  transition: color 0.3s ease;

  &.active {
    pointer-events: none;
    color: $white;
  }

  &:hover,
  &:focus {
    color: #e0dede;
  }
}

.body {
  position: relative;

  max-width: 54rem;
  margin-left: auto;
  margin-right: auto;

  border: 1px solid #394d65;
  border-radius: 1.4rem;
  overflow: hidden;
  transition: border-color 0.3s ease;

  &.editMode {
    border-color: $green_4;
  }
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;
}

.error,
.success {
  position: absolute;
  top: calc(100% + 1rem);

  width: 100%;

  font-size: 1.6rem;
  line-height: 1;
  color: $green;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.error {
  color: $error_color;
}

.back {
  position: absolute;
  z-index: 5;

  display: flex;
  align-items: center;

  @include bp($point_2, min) {
    top: 3.4rem;
    right: 15.6rem;
  }

  @include bp($point_3) {
    top: 2rem;
    left: var(--gl-block-indent);
  }

  &.backV2 {
    @include bp($point_2, min) {
      right: auto;
      left: 4rem;
    }
  }

  display: flex;
  align-items: center;
  gap: 1rem;

  white-space: nowrap;

  font-size: 1.5rem;
  font-weight: 300;
  color: inherit;
  color: $white;

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 0.6;
    text-decoration: none;
  }
}

.backIcon {
  width: 0.8rem;
  height: 1rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    margin-top: -0.1rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}
