.settings_title {
  margin-bottom: 3rem;

  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.5;

  &.v2_mod {
    font-size: 1.8rem;
  }
}

.settings_table_w {
  @include bp($point_4, min) {
    padding-top: 2rem;
  }
}

.settings_table {
  @include bp($point_4, min) {
    //border-radius: 2rem;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;

    &:first-of-type {
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      border-top: 1px solid $gray;
    }
    &:last-of-type {
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}

.settings_table_row {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  min-height: 7rem;
  padding: 2.4rem 0;

  border-bottom: 1px solid $gray;

  @include bp($point_5, min) {
    &:last-child {
      border-bottom: none;
    }
  }

  @include bp($point_5 - 1) {
    display: grid;
    grid-template-columns: 43% 39% 18%;

    &:first-child {
      //padding-top: 0;
    }

    &.last_child {
      border-bottom: none;
    }
  }
}

.settings_table_item {
  padding: 0 1rem;

  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;

  &:nth-child(1) {
    color: $lilac;

    @include bp($point_4, min) {
      width: 16%;
    }

    @include bp($point_4 - 1) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  &:nth-child(2) {
    font-weight: 500;

    @include bp($point_4, min) {
      width: 19%;
    }

    @include bp($point_4 - 1) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }

  &:nth-child(3) {
    font-weight: 500;
    @include bp($point_4, min) {
      width: 17%;
    }

    @include bp($point_4 - 1) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  &:nth-child(4) {
    font-size: 1.4rem;
    color: $lilac;

    @include bp($point_4, min) {
      width: 38%;
      padding-left: 3rem;
      text-align: left;
    }

    @include bp($point_4 - 1) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  &:nth-child(5) {
    font-weight: 500;
    @include bp($point_4, min) {
      width: 12%;
      margin-left: auto;
    }

    @include bp($point_4 - 1) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      text-align: right;
    }
  }
  &:nth-child(6) {
    font-weight: 500;
    color: $lilac_2;
    @include bp($point_4, min) {
      width: 9%;
    }

    @include bp($point_4 - 1) {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
      text-align: right;
    }
  }

  @include bp($point_4 - 1) {
    text-align: left;
    padding: 0 1rem 0 0;
  }
}
