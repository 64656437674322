@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------CallInfo
.component {
  color: $white;

  @include bp($point_2, min) {
    max-width: 35rem;
    width: 100%;
    margin: 0 auto;

    text-align: center;

    &.variantSecondary {
      max-width: 46rem;
    }
  }
}

.logout {
  @include bp($point_2, min) {
    position: absolute;
    top: 5rem;
    right: 8.4rem;
  }

  @include bp($point_3) {
    margin-bottom: 5rem;
  }
}

.logo {
  margin-bottom: 1.4rem;
}

.title {
  margin-bottom: 1rem;

  color: $white;
  font-weight: 500;

  @include bp($point_2, min) {
    font-size: 2rem;

    .variantSecondary & {
      font-size: 2.3rem;
    }
  }

  @include bp($point_3) {
    font-size: 1.8rem;
  }
}

.content {
  margin-bottom: 3.2rem;

  font-size: 1.6rem;
  letter-spacing: -0.02rem;

  .variantSecondary & {
    color: #92a5ba;
  }
}

.buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

//---------------------------------------------CallInfo###
