%image_in {
  display: block;
  width: 100%;
  height: 100%;
}

.signup__row {
  height: 100%;
  min-height: 100vh;

  @include bp($point_2, min) {
    @include flex_block(row, nowrap, flex-start, stretch);
  }
  @include bp($point_3) {
    @include flex_block(row, wrap, center, stretch);
  }
}

.signup__col {
  &:first-child {
    position: relative;

    max-width: var(--side-col);
    width: 100%;
    height: 100%;

    border-right: 1px solid $gray_4;

    @include bp($point_3) {
      display: none;
    }
  }
  &:last-child {
    @include bp($point_2, min) {
      width: 64%;
      padding: 2.8rem 2rem 4rem 5.6rem;
      flex-grow: 1;
    }

    @include bp($point_3) {
      width: 100%;
      padding: 2.4rem $gl_mobile_indent 4rem $gl_mobile_indent;
    }
  }
}

.signup__logo_w {
  @include bp($point_2, min) {
    margin-bottom: 1.8rem;
  }

  @include bp($point_3) {
    margin-bottom: 6.2rem;
  }
}

.signup__logo {
  display: block;

  @include bp($point_2, min) {
    width: 3.7rem;
    height: 2.6rem;
  }

  @include bp($point_3) {
    width: 3.2rem;
    height: 2.4rem;
  }
}

.signup__logo_in {
  @extend %image_in;
}

.signup__info {
  // margin-bottom: 3rem;
  width: 100%;
  // padding-bottom: 2.3rem;
  // border-bottom: 1px solid #eee;

  &.signup__info--offset_mod {
    @include bp($point_3) {
      padding-bottom: 1.8rem;
      border-bottom: 1px solid #eee;
      margin-bottom: 1.4rem;
    }
  }

  &.signup__info--offset_2_mod {
    margin-bottom: 2rem;
  }

  &.signup__info--offset_3_mod {
    @include bp($point_4 - 1) {
      margin-bottom: 2rem;
    }
  }

  @include bp($point_2, min) {
    max-width: 48.5rem;
  }
}

.signup__title {
  color: $black_2;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.29;

  @include bp($point_2, min) {
    margin-bottom: 1rem;
  }

  @include bp($point_3) {
    margin-bottom: 1.2rem;
  }

  &.signup__title--lg_mod {
    line-height: 1;

    @include bp($point_2, min) {
      font-size: 3rem;
    }

    @include bp($point_3) {
      font-size: 2.4rem;
    }
  }
}

.signup__subtitle {
  margin-bottom: 1.4rem;
  padding-bottom: 1.4rem;

  font-size: 1.3rem;
  font-weight: 500;
  color: $blue_3;
  line-height: 0.88;

  border-bottom: 1px solid $gray_10;
}

.signup__descr {
  color: $gray_8;
  font-size: 1.3rem;
  font-weight: 300;

  @include bp($point_2, min) {
    max-width: 48.7rem;
    line-height: 1.3;
  }

  @include bp($point_3) {
    line-height: 1.5;
  }

  &.signup__descr--offset_mod {
    margin-bottom: 1.4rem;
  }
}

.signup__descr_list {
  padding: 1.6rem 0 1.8rem;
  margin-top: 1.6rem;

  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  @include bp($point_3) {
    margin-bottom: 2.4rem;
  }
}

.signup_descr_list_item {
  font-size: 1.5rem;
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 0.7rem;
  }
}

.signup__img {
  height: 100%;
  width: 100%;
}

.signup__img_in {
  @extend %image_in;
  // position: fixed;
  // top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup__form:not(:last-of-type) {
  margin-bottom: 5rem;
}

.signup__why {
  @extend %global_flex_block_row_wrap_flex-start;
}

.signup__why_col {
  &:first-child {
    @include bp($point_3) {
      margin-bottom: 2rem;
      width: 100%;
    }
  }

  &:last-child {
    max-width: 27.5rem;

    @include bp($point_2, min) {
      // margin-left: 9rem;
      margin-left: 2rem;
    }
  }
}

.signup__why_img {
  padding: 1.3rem 0.9rem 1.4rem;
  border: 1px solid #c7c7c7;
  border-radius: 1.5rem;

  @include bp($point_2, min) {
    width: 6.1rem;
    height: 6rem;
  }

  @include bp($point_3) {
    width: 7rem;
    height: 6.8rem;
  }
}

.signup__why_img_in {
  @extend %image_in;
}

.signup__why_title {
  font-size: 1.6rem;
  font-weight: 500;
  color: $black_4;
  line-height: 1.5;
}

.signup__why_descr {
  font-size: 1.6rem;
  font-weight: 300;
  color: $black_4;
  line-height: 1.2;
}

//------------- tabs
.signup__tabs {
  @include bp($point_2, min) {
    max-width: 48.5rem;
  }
}
.signup__tabs_head {
  @extend %global_flex_block_row_wrap_flex-start;

  counter-reset: steps;
  margin: 0 -2rem 1rem 0;
}

.signup__tabs_item {
  @extend %global_flex_block_row_wrap_flex-start_center;

  width: 50%;

  padding: 0 2rem 1.2rem 0;
}

.signup__tabs_item_descr {
  width: 100%;
  padding-right: 2rem;
  font-size: 1.6rem;
  font-weight: 300;
  color: $gray_8;
}

.signup__tabs_link {
  position: relative;
  padding: 0 0 0 3.8rem;

  color: $gray_8;
  font-size: 1.6rem;
  font-weight: 500;

  &:after {
    position: absolute;
    left: 1.1rem;
    top: 0;
    z-index: 1;
    color: $white;
    font-size: 1.6rem;
    font-weight: 300;
    counter-increment: steps;
    content: counter(steps);
  }

  &:before {
    position: absolute;
    left: 0;
    top: -0.4rem;

    display: block;
    width: 3.1rem;
    height: 3.1rem;

    border-radius: 50%;
    background-color: $gray_8;
    content: '';
  }

  &.signup__tabs_link--active {
    color: $blue_3;

    &:before {
      background-color: $blue_3;
    }
  }

  &:hover {
    text-decoration: none;
    color: $blue_3;
  }
}

.signup__tabs_content {
  @include bp($point_4, min) {
    padding-top: 2rem;
  }

  &.signup__tabs_content--no_offset_mod {
    padding-top: 0;
  }

  &.signup__tabs_content--border_mod {
    border-top: 1px solid $gray_10;
  }
}

.signup__tabs_content_item {
  display: none;

  &.signup__tabs_content_item--active {
    display: block;
  }
}

.signup__payouts_btn {
  @extend %global_flex_block_row_wrap_center_center;
  height: 5.3rem;
  padding: 1rem;
  margin-bottom: 1.6rem;

  background-color: $blue_3;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: lighten($blue_3, 10%);
  }
}

.signup__payouts_btn_img {
  display: block;
  height: 1.9rem;
}

.signup__payouts_links {
  position: relative;
  color: $blue_3;
  font-size: 15px;
  font-weight: 300;

  &:after {
    position: absolute;
    bottom: -2px;
    left: 0;

    display: block;
    height: 1px;
    width: 100%;

    opacity: 0;
    background-color: $blue_3;
    transition: opacity ease-in 0.3s;
    content: '';
  }

  &:hover {
    text-decoration: none;
  }

  &:hover:after {
    opacity: 1;
  }
}

.payment_form {
  margin-bottom: 2rem;
}
