@if $local_var != onload {
  %global_flex_block_e {
    @include flex_block();
  }

  %global_flex_block_row_wrap_flex-start {
    @include flex_block(row, wrap, flex-start);
  }

  %global_flex_block_row_wrap_flex-start_stretch {
    @include flex_block(row, wrap, flex-start, stretch);
  }

  %global_flex_block_row_wrap_flex-start_center {
    @include flex_block(row, wrap, flex-start, center, center);
  }

  %global_flex_block_row_wrap_flex-start_flex-end {
    @include flex_block(row, wrap, flex-start, flex-end, flex-end);
  }

  %global_flex_block_row_wrap_flex-start_baseline {
    @include flex_block(row, wrap, flex-start, baseline);
  }

  %global_flex_block_row_wrap_flex-end {
    @include flex_block(row, wrap, flex-end);
  }

  %global_flex_block_row_wrap_flex-end_center {
    @include flex_block(row, wrap, flex-end, center, center);
  }

  %global_flex_block_row_wrap_flex-end_stretch {
    @include flex_block(row, wrap, flex-end, stretch);
  }

  %global_flex_block_row_wrap_flex-end_baseline {
    @include flex_block(row, wrap, flex-end, baseline);
  }

  %global_flex_block_row_wrap_center {
    @include flex_block(row, wrap, center);
  }

  %global_flex_block_row_wrap_center_center {
    @include flex_block(row, wrap, center, center, center);
  }

  %global_flex_block_row_wrap_center_stretch {
    @include flex_block(row, wrap, center, stretch);
  }

  %global_flex_block_row_wrap_center_baseline {
    @include flex_block(row, wrap, center, baseline);
  }

  %global_flex_block_row_wrap_space_between {
    @include flex_block(row, wrap, space-between);
  }

  %global_flex_block_row_wrap_space_between_stretch {
    @include flex_block(row, wrap, space-between, stretch);
  }

  %global_flex_block_row_wrap_space_between_center {
    @include flex_block(row, wrap, space-between, center, center);
  }

  %global_flex_block_row_wrap_space_between_baseline {
    @include flex_block(row, wrap, space-between, baseline);
  }

  %global_flex_block_row_wrap_space_between_flex-start {
    @include flex_block(row, wrap, space-between, flex-start);
  }

  %global_flex_block_row_wrap_space_between_flex-end {
    @include flex_block(row, wrap, space-between, flex-end);
  }

  %global_flex_block_row_nowrap_flex-start {
    @include flex_block(row, nowrap, flex-start);
  }

  %global_flex_block_row_nowrap_flex-start_stretch {
    @include flex_block(row, nowrap, flex-start, stretch);
  }

  %global_flex_block_row_nowrap_flex-start_center {
    @include flex_block(row, nowrap, flex-start, center, center);
  }

  %global_flex_block_row_nowrap_flex-start_flex-end {
    @include flex_block(row, nowrap, flex-start, flex-end);
  }

  %global_flex_block_row_nowrap_flex-start_baseline {
    @include flex_block(row, nowrap, flex-start, baseline);
  }

  %global_flex_block_row_nowrap_flex-end {
    @include flex_block(row, nowrap, flex-end);
  }

  %global_flex_block_row_nowrap_flex-end_center {
    @include flex_block(row, nowrap, flex-end, center, center);
  }

  %global_flex_block_row_nowrap_flex-end_stretch {
    @include flex_block(row, nowrap, flex-end, stretch);
  }

  %global_flex_block_row_nowrap_flex-end_baseline {
    @include flex_block(row, nowrap, flex-end, baseline);
  }

  %global_flex_block_row_nowrap_center {
    @include flex_block(row, nowrap, center);
  }

  %global_flex_block_row_nowrap_center_center {
    @include flex_block(row, nowrap, center, center, center);
  }

  %global_flex_block_row_nowrap_center_stretch {
    @include flex_block(row, nowrap, center, stretch);
  }

  %global_flex_block_row_nowrap_center_baseline {
    @include flex_block(row, nowrap, center, baseline);
  }

  %global_flex_block_row_nowrap_space_between {
    @include flex_block(row, nowrap, space-between);
  }

  %global_flex_block_row_nowrap_space_between_stretch {
    @include flex_block(row, nowrap, space-between, stretch);
  }

  %global_flex_block_row_nowrap_space_between_center {
    @include flex_block(row, nowrap, space-between, center, center);
  }

  %global_flex_block_row_nowrap_space_between_baseline {
    @include flex_block(row, nowrap, space-between, baseline);
  }

  %global_flex_block_row_nowrap_space_between_flex-start {
    @include flex_block(row, nowrap, space-between, flex-start);
  }

  %global_flex_block_row_nowrap_space_between_flex-end {
    @include flex_block(row, nowrap, space-between, flex-end);
  }

  %global_flex_block_column_wrap_flex-start {
    @include flex_block(column, wrap, flex-start);
  }

  %global_flex_block_column_wrap_flex-start_stretch {
    @include flex_block(column, wrap, flex-start, stretch);
  }

  %global_flex_block_column_wrap_flex-start_center {
    @include flex_block(column, wrap, flex-start, center, center);
  }

  %global_flex_block_column_wrap_flex-start_flex-end {
    @include flex_block(column, wrap, flex-start, flex-end);
  }

  %global_flex_block_column_wrap_flex-start_baseline {
    @include flex_block(column, wrap, flex-start, baseline);
  }

  %global_flex_block_column_wrap_flex-end {
    @include flex_block(column, wrap, flex-end);
  }

  %global_flex_block_column_wrap_flex-end_center {
    @include flex_block(column, wrap, flex-end, center, center);
  }

  %global_flex_block_column_wrap_flex-end_stretch {
    @include flex_block(column, wrap, flex-end, stretch);
  }

  %global_flex_block_column_wrap_flex-end_baseline {
    @include flex_block(column, wrap, flex-end, baseline);
  }

  %global_flex_block_column_wrap_center {
    @include flex_block(column, wrap, center);
  }

  %global_flex_block_column_wrap_center_center {
    @include flex_block(column, wrap, center, center, center);
  }

  %global_flex_block_column_wrap_center_stretch {
    @include flex_block(column, wrap, center, stretch);
  }

  %global_flex_block_column_wrap_center_baseline {
    @include flex_block(column, wrap, center, baseline);
  }

  %global_flex_block_column_wrap_space_between {
    @include flex_block(column, wrap, space-between);
  }

  %global_flex_block_column_wrap_space_between_stretch {
    @include flex_block(column, wrap, space-between, stretch);
  }

  %global_flex_block_column_wrap_space_between_center {
    @include flex_block(column, wrap, space-between, center);
  }

  %global_flex_block_column_wrap_space_between_baseline {
    @include flex_block(column, wrap, space-between, baseline);
  }

  %global_flex_block_column_wrap_space_between_flex-start {
    @include flex_block(column, wrap, space-between, flex-start);
  }

  %global_flex_block_column_wrap_space_between_flex-end {
    @include flex_block(column, wrap, space-between, flex-end);
  }

  %global_flex_block_column_nowrap_flex-start {
    @include flex_block(column, nowrap, flex-start);
  }

  %global_flex_block_column_nowrap_flex-start_stretch {
    @include flex_block(column, nowrap, flex-start, stretch);
  }

  %global_flex_block_column_nowrap_flex-start_center {
    @include flex_block(column, nowrap, flex-start, center);
  }

  %global_flex_block_column_nowrap_flex-start_flex-end {
    @include flex_block(column, nowrap, flex-start, flex-end);
  }

  %global_flex_block_column_nowrap_flex-start_baseline {
    @include flex_block(column, nowrap, flex-start, baseline);
  }

  %global_flex_block_column_nowrap_flex-end {
    @include flex_block(column, nowrap, flex-end);
  }

  %global_flex_block_column_nowrap_flex-end_center {
    @include flex_block(column, nowrap, flex-end, center);
  }

  %global_flex_block_column_nowrap_flex-end_stretch {
    @include flex_block(column, nowrap, flex-end, stretch);
  }

  %global_flex_block_column_nowrap_flex-end_baseline {
    @include flex_block(column, nowrap, flex-end, baseline);
  }

  %global_flex_block_column_nowrap_center {
    @include flex_block(column, nowrap, center);
  }

  %global_flex_block_column_nowrap_center_center {
    @include flex_block(column, nowrap, center, center);
  }

  %global_flex_block_column_nowrap_center_stretch {
    @include flex_block(column, nowrap, center, stretch);
  }

  %global_flex_block_column_nowrap_center_baseline {
    @include flex_block(column, nowrap, center, baseline);
  }

  %global_flex_block_column_nowrap_space_between {
    @include flex_block(column, nowrap, space-between);
  }

  %global_flex_block_column_nowrap_space_between_stretch {
    @include flex_block(column, nowrap, space-between, stretch);
  }

  %global_flex_block_column_nowrap_space_between_center {
    @include flex_block(column, nowrap, space-between, center);
  }

  %global_flex_block_column_nowrap_space_between_baseline {
    @include flex_block(column, nowrap, space-between, baseline);
  }

  %global_flex_block_column_nowrap_space_between_flex-start {
    @include flex_block(column, nowrap, space-between, flex-start);
  }

  %global_flex_block_column_nowrap_space_between_flex-end {
    @include flex_block(column, nowrap, space-between, flex-end);
  }

  %clearfix {
    &:after {
      position: static;
      display: block;
      height: 0;
      clear: both;
      content: '';
      visibility: hidden;
    }
  }

  %circle_ico_style {
    $w: 24px;
    position: absolute;
    width: $w !important;
    height: $w;
    top: 50%;
    margin: (-$w/2) 0 0;
    text-align: center;
    line-height: $w;
    border-radius: 50%;
  }

  %circle_ico_style_small {
    $w: 20px;
    position: absolute;
    width: $w !important;
    height: $w;
    top: 50%;
    margin: (-$w/2) 0 0;
    text-align: center;
    line-height: $w;
    border-radius: 50%;
  }

  %translate3d {
    transform: translateZ(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  %vert_centr {
    top: 50%;
    transform: translate(0, -50%);
  }

  %hor_centr {
    left: 50%;
    transform: translate(-50%, 0);
  }

  %hor_vert_centr {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  %stretch {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  %hover_show {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  %hover_show_parent {
    &:hover,
    &:focus {
      %hover_show {
        opacity: 1;
      }
    }
  }

  %scroll_rediz_1 {
    &::-webkit-scrollbar {
      width: 6px;
      height: 12px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      height: 12px;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  %text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  %z-index_fix_vertical {
    $index: 20;
    $index2: 21;
    @while $index > 0 {
      &:nth-child(#{$index}) {
        z-index: 10 + ($index2 - $index);
      }

      $index: $index - 1;
    }
  }

  %transition_all {
    transition: all 0.3s ease;
    will-change: transform;
  }

  %transition_opacity {
    transition: opacity 0.3s ease;
    will-change: opacity;
  }

  %transition_background {
    transition: background 0.3s ease;
    will-change: background;
  }

  %transition_color {
    transition: color 0.3s ease;
    will-change: color;
  }

  %transition_color_background {
    transition: color 0.3s ease, background 0.3s ease;
    will-change: color, background;
  }

  %transition_transform {
    transition: transform 0.3s ease;
    will-change: transform;
  }

  %tranisition_fill {
    transition: fill 0.3s ease;
    will-change: fill;
  }

  %rounded_i_w {
    display: block;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
  }

  %rounded_i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }

  %responsive_animation_wrap {
    position: absolute;
    width: 10%;
    height: 10vh;
    top: 50%;
    left: 50%;
    margin: -5vh 0 0 (-5%);
    transform: translateZ(0);
    will-change: transform;
  }
}
