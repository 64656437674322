@import 'styles/helpers/_variables.scss';

//---------------------------------------------Menu
.menu {
}

.menuButtonWrap {
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}

.menuButton {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 9.6rem;
  max-width: 100%;
  height: 3.2rem;

  font-size: 1.5rem;
  font-family: $font_1;
  font-weight: 500;
  text-align: center;
  line-height: 1;

  cursor: pointer;
  appearance: none;
  overflow: hidden;

  border: 1px solid $gray_9;

  transition: all 0.3s;

  span {
    margin-right: 0.8rem;

    font-size: 2.4rem;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    background-color: $gray_9;
    color: $white;
  }
}

.menuList {
  margin-bottom: 4rem;
}

.menuItem {
  &:not(:last-child) {
    margin-bottom: 3.1rem;
  }
}

.menuLink {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: $black_2;

  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &.menuLinkActive {
    text-decoration: none;
    opacity: 0.5;
  }
}

.menuLinkIcon {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.2rem;
}
//---------------------------------------------Menu###
