@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------SignUpPopup
.popup {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: rgba(#1F2D3D, 0.7);

  @include z-index(popup);
}

.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

.popup_in {
  width: 100%;

  .centerContent & {
    margin: auto 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  margin: auto;

  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;

  background-color: $white;

  @include bp($point_4, min) {
    width: 57rem;
    padding: 4.2rem 4.2rem;
    margin: 0 0 0 auto;

    &.containerV2 {
      width: 83.6rem;
      // padding: 6.6rem 3.4rem 5rem 5rem;

      @include bp($point_4, min) {
        width: 60rem;
        width: 75rem;
      }
    }

    &.containerV4 {
      display: flex;
      flex-direction: column;

      display: flex !important;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include bp($point_4 - 1) {
    padding: 4.4rem $gl_mobile_indent;
    width: 100%;
  }

  &.containerV3 {
    width: 60rem;
    margin: auto auto;
  }

  &.containerV5 {
    display: flex;
    align-items: center;
    margin: auto auto;
    background-color: transparent;
  }

  .popup-enter-done &,
  .popup-enter-active & {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }
}

.closeButton {
  margin-left: auto;
  color: #B5B5B5;

  transition: color .3s;

  cursor: pointer;

  @include bp($point_2, min) {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 3rem;
    // display: none;
  }

  @include bp($point_3) {
    position: relative;

    display: block;
    width: 1.4rem;
    height: 1.4rem;
    margin-bottom: 3rem;


    &:before {
      position: absolute;
      top: -1rem;
      left: -1rem;
      right: -1rem;
      bottom: -1rem;

      content: '';
    }
  }

  &:hover,
  &:focus {
    color: $black;
  }
}

.title {
  margin-bottom: 0.4rem;

  font-size: 2rem;
  font-weight: 400;
  color: #1A1C1D;

  .popupOffsets & {
    margin-bottom: 0.4rem;
  }
}

.description {
  font-size: 1.5rem;
  color: #9A9A9A;
  // letter-spacing: -0.01rem;

  .popupOffsets & {
    font-size: 1.5rem;
    font-weight: 500;
    color: $gray_5;
  }

  @include bp($point_4, min) {
    // max-width: 90%;
  }
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;

  .popupOffsets & {
    margin-bottom: 0.4rem;
  }

  .popupBorder & {
    padding-bottom: 1.4rem;
    margin-bottom: 1.4rem;
    border-bottom: 1px solid $gray_10;
  }
}

.icon {
  width: 5.4rem;
  height: 5.4rem;
  margin-right: 1.6rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    color: #dc2217;
  }
}

//---------------------------------------------SignUpPopup###