@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.accordion {
  width: 100%;
  max-width: 100%;
  margin: 25px auto 0;
}
.accordion-item {
  border: 1px solid #394D65;
  border-radius: 7px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}
.accordion-item:not(:last-child){
  margin-bottom: 25px;
}
.accordion-header {
  padding: 14px;
  background-color: transparent;
  cursor: pointer;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 15px;
}
.header-icon {
  height: 35px;
}
.accordion-item.open .accordion-header {
  display: none;
}
.accordion-content {
  display: none;
  overflow: hidden;
}
.accordion-item.open .accordion-content {
  display: block;
}
.content-with-background {
  position: relative;
  /*height: 225px;*/
}
.content-with-background .background-image {
  position: relative;
  /*height: 225px;*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  padding: 20px 30px;
}
.content {
  color: white;
  font-size: 18px;
}
.btn-play {
  display: inline-flex;
  bottom: 20px;
  left: 30px;
  padding: 6px 30px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}
.video-container {
  position: relative;
  /*height: 225px;*/
  opacity: 0.8;
}
.video-background {
  width: 100%;
  height: 225px;
  object-fit: cover;
  border-radius: 7px;
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.close-button.accordion-close {
  right: 30px;
}
.content-heading{
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 5px;
}
.content-subheading {
  font-size: 16px;
  line-height: 20px;
}
.content-bottom {
  margin: 25px 0;
}
.list-item {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 20px;
}
.list-item .item {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;
}
.list-item .item .item-icon {
  display: flex;
  margin-right: 10px;
}
.list-item .item .item-icon .image{
  width: 16px;
  height: 16px;
}



@media only screen and (max-width: 767px){
  .content-heading{
    font-size: 22px;
    line-height: 28px;
  }
  .close-button.accordion-close{
    right: 20px;
  }
  .close-button.accordion-close img{
    width: 28px;
    height: 28px;
  }
}