@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.dashboardMeeting {
  width: 100%;
  display: flex;
  color: #1a1c1d;

  align-items: center;
}

.dashboardMeetingAvatar {
  flex-shrink: 0;
  width: 3.7rem;
  height: 3.7rem;
  margin-right: 1.8rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;
}

.dashboardMeetingAvatar_in {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.dashboardMeetingCol {
  display: flex;
  flex-direction: row;
  align-items: center;

  >* {
    margin-right: 24px;
  }

  &:not(:last-child) {
    flex-shrink: 0;
    padding-right: 1rem;
    font-weight: 200;
  }

  @include bp($point_4 - 1) {
    * {
      margin-right: 15px;
    }
  }
}

.dashboardMeetingUsers {
  display: flex;
  flex-wrap: wrap;
  gap: 0 2.6rem;
}

.dashboardMeetingUsersItem {
  font-size: 1.6rem;
  font-weight: 400;

  &:nth-child(n + 3) {
    display: none;
  }

  .dashboardMeetingUsersAll & {
    display: block;
  }
}

.dashboardMeetingUsersTrigger {
  color: #007EFF;

  cursor: pointer;

  transition: color .3s;

  &:hover {
    color: $black;
  }
}

.dashboardMeetingTime {
  flex-shrink: 0;

  font-size: 1.4rem;
  font-weight: 400;
  color: #1A1C1D;


  font-size: 16px !important;
  line-height: 18px !important;

  @include bp($point_0 - 1) {
    line-height: 1.7;
  }
}

.dashboardMeetingMain {
  &.dashboardMeetingMainRow {
    @include bp($point_2, min) {
      display: flex;
      align-items: center;
      gap: 2rem;

    }
  }
}

.dashboardMeetingName {
  font-weight: 500;

  font-size: 16px !important;
  line-height: 18px !important;

  @include bp($point_2, min) {
    font-size: 1.6rem;
  }

  @include bp($point_3) {
    font-size: 1.5rem;
  }
}

.dashboardMeetingDescr {
  color: #8083a3;
  margin-left: 4rem;

  margin-left: 0 !important;

  font-size: 14px !important;
  line-height: 18px !important;
}

.dashboardMeetingCost {
  // font-family: 'Lato', sans-serif;
  color: #8083a3;
}

.dashboardMeetingCost {
  font-size: 1.4rem;
  font-weight: 400;
  // margin-left: 1rem;


  &:not(:first-child) {
    margin-top: .2rem;
  }
}

.dashboardMeetingDescr {
  font-size: 1.4rem;
  font-weight: 500;
}