@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5rem;
  width: 100%;

  font-size: 1.5rem;
  font-weight: 500;
  font-family: $font_1;
  background-color: $blue_3;
  color: $white;

  transition: all 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $black_3;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.6rem;
    fill: currentColor;
  }
}
