.profile_sidebar__head {
  @include bp($point_3) {
    display: flex;
    align-items: center;

    margin-bottom: 1.2rem;
    padding-bottom: 1.6rem;

    border-bottom: 1px solid #eee;
  }
}

// profile_sidebar_img
.profile_sidebar_img {
  border-radius: 50%;

  overflow: hidden;

  @include bp($point_2, min) {
    width: 11.6rem;
    height: 11.6rem;
  }

  @include bp($point_3) {
    width: 5.4rem;
    height: 5.4rem;
    margin-right: 1rem;
  }
}

.profile_sidebar_img__in {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

// profile_sidebar_img ###

// profile_sidebar_profession
.profile_sidebar_profession {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: $font_1;
  color: #7a7a7a;
}

// profile_sidebar_profession ###

// profile_sidebar_name
.profile_sidebar_name {
  font-weight: 500;
  line-height: 1;
  font-family: $font_1;
  color: $black_2;

  @include bp($point_2, min) {
    font-size: 3.2rem;
  }

  @include bp($point_3) {
    font-size: 2.6rem;
  }
}

// profile_sidebar_name ###

.profile_sidebar_salaries_wrap {
  position: relative;
  // margin-top: auto;

  @include bp($point_2, min) {
    padding-top: 1.6rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -3.8rem;
      right: -2.5rem;

      height: 1px;

      background-color: $gray_4;
    }
  }

  @include bp($point_3) {
    display: flex;
    align-items: center;
    padding: 1.6rem 0 1.8rem;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}

// profile_sidebar_salaries
.profile_sidebar_salaries {
  @extend %global_flex_block_row_nowrap_flex-start;

  margin-top: auto;

  @include bp($point_2, min) {
    margin-bottom: 0.6rem;
  }

  @include bp($point_3) {
    margin-right: 1rem;
  }
}

.profile_sidebar_salaries__item {
  font-weight: 300;
  line-height: 1.5;
  font-family: $font_1;
  color: $lilac;

  &:first-child {
    margin-right: 5px;
  }

  @include bp($point_3) {
    border: 1px solid #eee;
    padding: 0.7rem 2rem 0.7rem 1.2rem;
  }
}

.profile_sidebar_salaries__item--accent {
  font-weight: 500;
}

.profile_sidebar_salaries__item_money {
  @include bp($point_2, min) {
    font-size: 3.2rem;
  }

  @include bp($point_3) {
    font-size: 2.6rem;
    margin-right: 0.6rem;
  }
}

.profile_sidebar_salaries__item_money_separator {
  @include bp($point_3) {
    display: none;
  }
}

.profile_sidebar_salaries__item_period {
  font-size: 1.4rem;
}

// profile_sidebar_salaries ###

// profile_sidebar_description
.profile_sidebar_description {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0.1px;
  font-family: $font_1;
  color: $black_2;
}

// profile_sidebar_description ###

// profile_sidebar_meta
.profile_sidebar_meta {
}

.profile_sidebar_meta__item {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.profile_sidebar_meta__item_icon {
  flex-shrink: 0;

  margin-right: 0.9rem;
  width: 1.4rem;
  height: 1.4rem;

  color: #212121;

  opacity: 0.4;
}

.profile_sidebar_meta__item_text {
  font-weight: 300;
  font-family: $font_1;
  font-size: 1.6rem;
  line-height: 1.6;
  color: $black_2;
}

.profile_sidebar_meta__item_text--link {
  @extend %transition_color;

  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $blue;
  }

  &:focus {
    text-decoration: none;
  }
}

// profile_sidebar_meta ###

// profile_sidebar_social
.profile_sidebar_social {
  overflow: hidden;
}

.profile_sidebar_social__list {
  @extend %global_flex_block_row_wrap_flex-start_center;

  margin: 0 -0.9rem -0.9rem 0;
}

.profile_sidebar_social__item {
  padding: 0 0.9rem 0.9rem 0;

  cursor: pointer;
}

.profile_sidebar_social__item_icon {
  @extend %transition_color;

  display: block;
  height: 1.5rem;
  width: 1.5rem;

  color: $blue_3;

  .profile_sidebar_social__item:hover & {
    color: $blue;
  }
}

.profile_sidebar_social__item_link {
  display: block;
}

.profile_sidebar_social__item_text {
  font-size: 1.6rem;
  font-weight: 500;
  color: $blue_3;
}

// profile_sidebar_social ###

// profile_sidebar
.profile_sidebar {
  @include bp($point_2, min) {
    @include flex_block(column, nowrap, flex-start, stretch);

    margin-left: auto;
    padding: 3rem 2.5rem 4rem 3.8rem;
    max-width: 34rem;
    min-height: 100%;

    border-right: 1px solid $gray_4;
  }

  @include bp($point_3) {
    padding: 1rem 1.6rem 0;
  }
}

.profile_sidebar__img {
  // margin: 0 auto;
  @include bp($point_2, min) {
    margin-bottom: 2.2rem;
  }
}

.profile_sidebar__profession {
  @include bp($point_2, min) {
    margin-bottom: 0.6rem;
  }
}

.profile_sidebar__name {
  margin-bottom: 4px;
}

.profile_sidebar__description {
  @include bp($point_2, min) {
    margin-bottom: 1.4rem;
  }

  @include bp($point_3) {
    margin: 0 0 1.6rem;
    max-width: 34rem;
  }
}

.profile_sidebar__meta {
  padding-left: 2rem;

  margin-bottom: 1.2rem;

  @include bp($point_3) {
    display: none;
  }
}

.profile_sidebar__social {
  @include bp($point_2, min) {
    margin-bottom: 2.2rem;
  }

  @include bp($point_3) {
    margin-bottom: 2rem;
  }
}

.profile_sidebar__info {
  @extend %global_flex_block_row_wrap_flex-start;

  margin: 0 0 -1rem 0;
}

.profile_sidebar__info_item {
  position: relative;
  width: 100%;
  padding: 0 0 1rem 1.4rem;

  font-size: 1.6rem;
  line-height: 1;
  font-weight: 300;
  color: #747474;

  // @include bp($point_2, min) {
  //   &:nth-child(odd) {
  //     width: 55%;
  //   }

  //   &:nth-child(even) {
  //     width: 45%;
  //   }
  // }

  &:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0;

    width: 0.8rem;
    height: 0.8rem;

    border-radius: 50%;

    background-color: $gray_3;
  }
}

.profile_sidebar__info_icon {
  display: inline-flex;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.8rem;
}

// profile_sidebar ###
