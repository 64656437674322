//---------------------------------------------assistant_actions
.assistant_actions {
  @include flex_block(row, wrap, flex-start, center);

  margin-right: -1.2rem;
  margin-bottom: -1.2rem;
}

.assistant_actions_item {
  width: 50%;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
}

.btn_assistant {
  @include flex_block(row, wrap, center, center);
  align-items: center;
  align-content: center;

  width: 100%;
  min-height: 6.8rem;

  color: $lilac;
  font-size: 1.4rem;
  font-weight: 700;

  background-color: $white;
  border: 1px solid $gray;
  border-radius: 1rem;
  text-decoration: none;

  transition: all 0.3s ease;

  &:hover,
  &:focus {
    color: $green_1;

    background-color: $gray_6;
    text-decoration: none;
  }

  &.disabled {
    color: gray;
    opacity: 0.6;
  }
}
//---------------------------------------------assistant_actions###
