// time_select_form
.time_select_form {
  @extend %global_flex_block_column_nowrap_flex-start_center;

  width: 100%;

  @include bp($point_2, min) {
    height: 100%;
  }
}

.time_select_form__head {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  width: 100%;
  // padding-bottom: 2.8rem;

  // border-bottom: 1px solid #eee;

  @include bp($point_2, min) {
    margin-bottom: 2.8rem;
  }

  @include bp($point_3) {
    margin-bottom: 1.8rem;
  }
}

.time_select_form__title {
  margin-right: auto;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 0.77;
  font-family: $font_1;
  color: $black_2;

  // @include bp($point_2, min) {
  // 	margin-bottom: 1.6rem;
  // }

  // @include bp($point_3) {
  // 	margin-bottom: 1.4rem;
  // }
}

.time_select_form__select {
  // margin-bottom: 4rem;
}

// date_select
.date_select {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  // @include bp($point_2, min) {
  // 	margin-bottom: 4rem;
  // }

  // @include bp($point_3) {
  // 	margin-bottom: 2.5rem;
  // }
  @include bp($point_3) {
    width: 100%;
    justify-content: center;
  }
}

.date_select__arrow {
  @extend %transition_color;

  width: 0.8rem;
  height: 1.2rem;

  color: $blue_3;

  cursor: pointer;

  @include bp($point_2, min) {
    display: none;
  }

  &:hover {
    color: rgba($blue, 0.8);
  }
}

.date_select__selected {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.13;
  font-family: $font_1;

  @include bp($point_2, min) {
    color: $black_2;
  }

  @include bp($point_3) {
    color: #007eff;
    margin: 0 auto;
  }
}

// time_select

.time_select__wrap {
  width: 100%;
  overflow-y: auto;
}

.time_select {
  width: 100%;
  // max-width: 37rem;
  margin: 0 auto;
}

.time_select__item {
  @extend %global_flex_block_row_nowrap_flex-start_stretch;
  &:not(:last-child) {
    @include bp($point_2, min) {
      margin-bottom: 2.3rem;
    }

    @include bp($point_3) {
      margin-bottom: 0.4rem;
    }
  }
}

.time_select__submit {
  width: calc(50% - 1rem);
  flex-shrink: 0;
  margin-left: 2rem;

  font-size: 1.6rem;
  font-weight: 500;
  color: $white;

  background-color: $blue_3;

  cursor: pointer;
}

.time_select__item_label {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 100%;

  font-weight: 500;
  font-family: $font_1;
  text-transform: uppercase;
  color: $blue_3;

  border: 0.1rem solid $blue_3;
  cursor: pointer;

  transition: color 0.3s ease, background 0.3s ease;

  @include bp($point_2, min) {
    height: 5.4rem;
    font-size: 1.6rem;
  }

  @include bp($point_3) {
    height: 4.4rem;
    font-size: 1.4rem;
  }

  // &:hover,
  // .time_select__item_input:checked + & {
  //   color: $blue_3;

  //   background: $white;
  // }
}

.time_select__item_input {
  display: none;
}

// time_select_form_button
.time_select_form_button {
  @extend %btn_size_1;
  @extend %blue_btn_2;

  margin: 2rem 0;
}
