@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.block {
  max-width: 26.8rem;

  margin: 18px 0 22px;
  max-width: 100% !important;
}

.title {
  margin-bottom: 0.4rem;

  font-size: 2rem;
  font-weight: 500;
  text-align: center;

  display: none;
}

.bar {
  height: 0.7rem;
  border-radius: 0.3rem;
  background-color: #ddd;
}

.progress {
  height: 100%;
  width: 55%;

  border-radius: 0.3rem;
  background-color: #33c739;

  background-color: #017DFF;
}