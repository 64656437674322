.payment_data {
  @extend %global_flex_block_row_wrap_flex-start_stretch;
  margin: 0 0 2.4rem;

  border: 1px solid $gray;
  border-radius: 1rem;
}

.payment_data_item {
  padding: 1.4rem 2rem;
  width: 50%;
  border-right: 1px solid $gray;
  border-top: 1px solid $gray;

  &:nth-child(1),
  &:nth-child(2) {
    width: 100%;
    border-right: none;
  }

  &:nth-child(1) {
    border-top: none;
  }

  @include bp($point_3) {
    width: 100%;
  }
}

.payment_data_title {
  margin: 0 0 0.6rem;

  font-size: 1.4rem;
  color: $lilac;
}

.payment_data_value {
  font-size: 1.7rem;
  font-weight: 700;
  color: $black_2;
}

.payment_methods {
  margin-bottom: 0.9em;

  &.border_mod {
    border-top: 1px solid $gray;
    margin-top: 3.5em;
    padding-top: 2.6rem;
  }
}
