@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------FormFile
.input {
  display: none;
}

.label {
  @extend %signup_input;

  display: flex;
  align-items: center;

  color: $blue_3;

  cursor: pointer;

  transition: border 0.3s ease;

  &.labelV2 {
    justify-content: center;
  }

  &:hover,
  &:focus {
    border-color: $blue_3;
  }
}

.icon {
  margin-right: 1rem;
  width: 1.6rem;
  height: 1.6rem;

  .labelV2 & {
    width: 2rem;
    height: 2rem;
  }
}
//---------------------------------------------FormFile###
