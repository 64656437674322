@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.dashboardChat {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: $white;

  z-index: 51;

  @include bp($point_0, min) {
    // padding: 0 2.6rem 0 1.2rem;
    padding: 0 1.2rem;
  }

  @include bp($point_0 - 1) {
    // width: auto;
    // margin: 0 #{$gl_mobile_indent * -1};
    // width: calc(100% + #{$gl_mobile_indent * 2});

    position: fixed;
    width: 100%;
    height: 100vh;
    padding: 0 1.8rem 0;
  }
}

.dashboardChatHead {
  display: flex;
  align-items: center;
  height: 6.8rem;

  @include bp($point_0, min) {
    padding: 0 0 2.4rem 0;
    border-bottom: 1px solid $gray_10;
  }

  @include bp($point_0 - 1) {
    position: relative;
    padding: 0 16px 0 20px;
    height: 5.6rem;

    margin: 0 -1.8rem 0;
    width: calc(100% + 3.6rem);

    background-color: #ededed;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;

      width: 100vw;
      height: 1px;
      margin-left: -50vw;

      background-color: $white;
    }
  }
}

.dashboardChatBack {
  width: 1.8rem;
  height: 2.2rem;
  margin-right: 1.6rem;
  flex-shrink: 0;

  color: #414141;
  transition: color 0.3s;

  cursor: pointer;

  &:hover,
  &:focus {
    color: $black;
  }
}

.dashboardChatBackIcon {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.dashboardChatAvatar {
  width: 3.6rem;
  height: 3.6rem;
  flex-shrink: 0;
  margin-right: 1.8rem;

  border-radius: 50%;
  overflow: hidden;
}

.dashboardChatAvatarImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboardChatHeadInfo {
  max-width: calc(100% - 14rem);
}

.dashboardChatName {
  margin-bottom: -0.2rem;

  font-size: 1.5rem;
  font-weight: 500;
  color: #414141;
}

.dashboardChatHeadData {
  font-size: 1.3rem;
  font-weight: 300;
  color: #414141;

  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashboardChatHeaIcons {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include bp($point_0, min) {
    display: none;
  }
}

.dashboardChatHeadSearch {
  display: block;
  width: 1.6rem;
  height: 1.6rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.dashboardChatHeadHamburger {
  display: block;
  width: 2.4rem;
  height: 1.2rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

// message

.dashboardChatMain {
  position: relative;
  flex-grow: 1;

  @include bp($point_0, min) {
    max-height: calc(100% - 6.8rem);
  }

  @include bp($point_0 - 1) {
    height: calc(100vh - 6.8rem);
  }
}

.dashboardChatMainIn {
  height: 100%;
  overflow-y: scroll;

  @include bp($point_0, min) {
    padding: 1.4rem 0 6rem;
  }

  @include bp($point_0 - 1) {
    padding: 2rem 0 12rem;
  }
}

.dashboardChatMessage {
  width: 77%;

  &.dashboardChatMessageV2Mod {
    margin-left: auto;
  }

  &:not(:last-child) {
    @include bp($point_0 - 1) {
      margin-bottom: 1rem;
    }
  }
}

.dashboardChatMessageTime {
  color: #a8a8a8;

  .dashboardChatMessageV2Mod & {
    margin-left: auto;
    margin-right: 0.8rem;
    text-align: right;
  }

  @include bp($point_0, min) {
    margin: 0 0.4rem 0 1.6rem;

    font-size: 1rem;
  }

  @include bp($point_0 - 1) {
    margin: 0 0.4rem 0 1.6rem;
    font-size: 1rem;
  }
}

.dashboardChatMessageText {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 300;
  color: #414141;

  border: 1px solid #ededed;
  border-radius: 0.7rem;

  @include bp($point_0, min) {
    padding: 1rem 1.2rem;
  }

  @include bp($point_0 - 1) {
    padding: 1rem 0.8rem 1rem 1.4rem;
  }

  .dashboardChatMessageV2Mod & {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
}

.dashboardChatMainBtnWrap {
  position: absolute;
  bottom: 0rem;

  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;

  background-color: $white;

  @include bp($point_0, min) {
    left: -3.4rem;
    right: -3.4rem;
  }

  @include bp($point_0 - 1) {
    left: -1.8rem;
    right: -1.8rem;
  }

  @include bp($point_3) {
    flex-direction: column;
    gap: 1rem;
  }
}

.dashboardChatMainBtnItem {
  @include bp($point_2, min) {
    flex: 1;
  }

  @include bp($point_3) {
    width: 100%;
  }
}

.dashboardChatTranscription {
  color: $white;
}

.dashboardChatMainSubtitle {
  margin-bottom: 2rem;

  font-weight: 700;
  font-size: 1.5rem;
}
