@import 'styles/helpers/_variables.scss';

//---------------------------------------------ShareButton
.share {
  margin-bottom: 2.6rem;
}

.shareButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  min-height: 3.6rem;
  min-width: 11rem;

  font-size: 1.6rem;
  font-family: $font_1;
  color: $gray_9;

  border: 1px solid $gray_9;
  transition: all 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;

    background-color: $gray_9;
  }

  &.shareButtonSuccess {
    background-color: $green_1;
    border-color: $green_1;
    color: $white;
  }
}
//---------------------------------------------ShareButton###
