//---------------------------------------------icons
.icon_w {
  &.talks_message_mod,
  &.btn_assistant_mod {
    display: flex;
    align-items: center;
    width: 1.6rem;
    height: 1.6rem;
  }
  &.btn_assistant_mod {
    margin-right: 0.8rem;
  }
  &.btn_base_mod {
    width: 1.8rem;
    height: 1.8rem;

    margin-right: 1rem;
  }

  &.position_mod {
    position: absolute;
    left: 39%;
    @include bp(1300px) {
      left: 37%;
    }
    @include bp($point_1) {
      left: 37%;
    }
    @include bp($point_5) {
      left: 40%;
    }
    @include bp($point_6) {
      left: 38%;
    }
    @include bp($point_7) {
      left: 37%;
    }
  }

  &.position_mod_v2 {
    position: absolute;
    left: 26%;
    @include bp(1400px) {
      left: 25%;
    }
    @include bp(1350px) {
      left: 24%;
    }
    @include bp(1300px) {
      left: 23%;
    }
    @include bp(1250px) {
      left: 21%;
    }
    @include bp(1200px) {
      left: 20%;
    }
    @include bp($point_1) {
      left: 21%;
    }
    @include bp($point_5) {
      left: 28%;
    }
    @include bp($point_6) {
      left: 24%;
    }
    @include bp($point_7) {
      left: 22%;
    }
  }

  &.position_mod_v3 {
    position: absolute;
    left: 14%;
    @include bp(1400px) {
      left: 14%;
    }
    @include bp(1350px) {
      left: 13%;
    }
    @include bp(1300px) {
      left: 12%;
    }
    @include bp(1250px) {
      left: 10%;
    }
    @include bp(1200px) {
      left: 9%;
    }
    @include bp($point_1) {
      left: 10%;
    }
    @include bp($point_5) {
      left: 17%;
    }
    @include bp($point_6) {
      left: 13%;
    }
    @include bp($point_7) {
      left: 11%;
    }
  }
}

.icon {
  &.size_mod {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.play_mod {
    width: 1rem;
    height: 1.4rem;
    margin: auto;
  }

  &.talk_mod {
    font-size: 1.8rem;
  }

  &.current_mod {
    fill: currentColor;
  }

  &.nav_mod {
    width: 2.6rem;
    height: 2.6rem;
  }

  &.social_mod {
    width: 1.4rem;
    height: 1.4rem;
  }
}
