@import 'styles/helpers/_variables.scss';

//---------------------------------------------ErrorMessage
.message {
  color: $error_color;
  font-size: 1.3rem;
  font-weight: 700;

  &.inputMod {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: 20;

    display: flex;
    align-items: center;
    padding: 1rem 1.6rem;

    font-weight: 300;

    border: 1px solid $error_color;
    background-color: $white;

    pointer-events: none;
  }

  :global(input:focus) + & {
    opacity: 0 !important;
  }

  &.formMod {
    margin-bottom: 1rem;
  }
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: auto;

  display: none;

  transform-origin: 50% 50%;
  transform: rotate(180deg);

  .inputMod & {
    display: block;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
//---------------------------------------------ErrorMessage###
