%signup_input {
  width: 100%;
  height: $input_signup_height;
  padding: 1.6rem;

  font-size: 1.5rem;
  font-weight: 400;
  color: $black;
  font-family: $font_1;

  // border: 1px solid $gray_4;
  border: none;
  box-shadow: 0 0 0 1px $gray_4;
  background-color: $white;

  &::placeholder {
    font-weight: 500;
    color: $gray_8;
    color: tomato;
  }

  &::-webkit-input-placeholder {
    font-weight: 500;
    color: $gray_8;
  }
}