.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-calendar {
  font-size: (16.08/10) * 1rem;
  width: (16.7/16.08) * 1em;
}
.icon-chat {
  font-size: (16/10) * 1rem;
  width: (16/16) * 1em;
}
.icon-check {
  font-size: (32/10) * 1rem;
  width: (32/32) * 1em;
}
.icon-check_2 {
  font-size: (19/10) * 1rem;
  width: (18/19) * 1em;
}
.icon-check_3 {
  font-size: (8/10) * 1rem;
  width: (10/8) * 1em;
}
.icon-clock {
  font-size: (19/10) * 1rem;
  width: (18/19) * 1em;
}
.icon-close {
  font-size: (9/10) * 1rem;
  width: (9/9) * 1em;
}
.icon-email {
  font-size: (12.25/10) * 1rem;
  width: (14.01/12.25) * 1em;
}
.icon-empty {
  font-size: (45.44/10) * 1rem;
  width: (45.44/45.44) * 1em;
}
.icon-file {
  font-size: (19/10) * 1rem;
  width: (17.15/19) * 1em;
}
.icon-lock {
  font-size: (19/10) * 1rem;
  width: (18/19) * 1em;
}
.icon-mail {
  font-size: (19/10) * 1rem;
  width: (18/19) * 1em;
}
.icon-plane {
  font-size: (15.89/10) * 1rem;
  width: (15.96/15.89) * 1em;
}
.icon-re-schedule {
  font-size: (15.33/10) * 1rem;
  width: (16/15.33) * 1em;
}
.icon-search {
  font-size: (17.04/10) * 1rem;
  width: (17.04/17.04) * 1em;
}
