.logout {
  display: flex;
  align-items: center;
  gap: 1rem;

  white-space: nowrap;

  font-size: 1.5rem;
  font-weight: 300;
  color: inherit;

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.logoutIcon {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}
