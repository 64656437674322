@import 'styles/helpers/_variables.scss';

//---------------------------------------------AvailableTimeSelect
.select {
  display: block;
  height: 3.4rem;
  width: 100%;
  padding: 0.4rem 0.8rem;

  font-size: 1.3rem;
  font-weight: 500;
  font-family: $font_1;
  color: $blue_3;

  border-color: $gray_4;

  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &.lgSelect {
    height: $input_signup_height;

    // font-size: 1.6rem;
  }
}
//---------------------------------------------AvailableTimeSelect###
