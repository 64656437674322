.book__cols {
  display: flex;

  width: 91rem;
  max-width: 100%;
  margin: 0 auto;

  @include bp($point_2, min) {
    min-height: 52.2rem;
    max-height: calc(100vh - var(--height-header));
    margin: auto auto;

    border: 1px solid $gray_4;
    border-radius: 2rem;
  }

  @include bp($point_3) {
    flex-direction: column;
    padding-top: 6.6rem;
  }

  &.book__cols--lg_mod {
    width: 110rem;

    @include bp($point_2, min) {
      height: 58.2rem;
    }
  }
}

.book__col {
  &.book__col--sidebar {
    @include bp($point_2, min) {
      width: 33rem;
      flex-shrink: 0;
    }
  }

  &.book__col--main {
    display: flex;

    @include bp($point_2, min) {
      padding: 3.6rem 2.4rem;
      flex-grow: 1;
      // overflow-y: auto;

      .book__cols--lg_mod & {
        padding: 0;
      }
    }

    .book__col--mobile_hidden_mod + & {
      @include bp($point_3) {
        margin: auto;
      }
    }
  }

  &.book__col--aside {
    @include bp($point_2, min) {
      width: 32.6rem;
      flex-shrink: 0;
      border-left: 1px solid $gray_4;
      overflow-y: auto;
    }

    @include bp($point_3) {
      padding: 0 2rem 8rem;
    }
  }

  &.book__col--mobile_hidden_mod {
    @include bp($point_3) {
      display: none;
    }
  }

  @include bp($point_3) {
    width: 100%;
  }
}

.book__col_content {
  margin: auto 0;
}

.book__aside_content {
  @include bp($point_2, min) {
    padding: 3.6rem 3.6rem;
  }

  &.book__aside_content--mobile_popup_mod {
    @include bp($point_3) {
      position: absolute;
      top: 3.2rem;
      left: 0;

      width: 100%;
      height: calc(100vh - 3.2rem);
      padding: 3.2rem 2.4rem 4rem;

      background-color: $white;
      overflow-y: auto;
    }
  }
}

.book__col_in {
  @include bp($point_2, min) {
    margin: auto 0;
    padding-left: 2rem;
  }

  @include bp($point_3) {
    padding: 2rem 2rem;
  }
}

.book__payment {
  @include bp($point_2, min) {
    padding-left: 3rem;
  }

  @include bp($point_3) {
    padding: 3rem 2rem;
  }
}

.book_mobile_btn {
  @include bp($point_2, min) {
    display: none;
  }

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  button {
    width: 100%;
    max-width: 100%;
  }
}
