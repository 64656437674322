@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.dashboardReminder {
  @include bp($point_0, min) {
    padding-bottom: 1.4rem;
    margin-bottom: 1.4rem;

    &:not(:last-child) {
      border-bottom: 1px solid $gray_10;
    }
  }

  @include bp($point_0) {
    // margin-bottom: 2rem;
    margin-left: -#{$gl_mobile_indent};
    margin-right: -#{$gl_mobile_indent};
  }
}

.dashboardReminderIn {
  display: flex;
  align-items: center;
  background-color: #007eff;

  @include bp($point_0, min) {
    padding: 2rem 2.4rem 2rem 2.5rem;
    min-height: 8.4rem;

    font-size: 2rem;
    border-bottom: 1px solid $gray_10;
    border-radius: 0.7rem;
  }

  @include bp($point_0 - 1) {
    padding: 1rem 1.6rem 1.2rem;

    font-size: 1.6rem;
  }
}

.dashboardReminderContent {
  color: $white;
  font-weight: 500;

  @include bp($point_0, min) {
    font-size: 2rem;
  }

  @include bp($point_0 - 1) {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  &:not(:last-child) {
    margin-right: 4rem;
  }
}

.dashboardReminderLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  color: $white;
  text-align: center;
  line-height: 1.2;

  cursor: pointer;
  border: 1px solid currentColor;

  transition: color 0.2s ease-in-out;

  @include bp($point_0, min) {
    min-width: 12rem;
    padding: 0.4rem 1.6rem;
    min-height: 3.5rem;

    font-size: 1.6rem;
  }

  @include bp($point_0 - 1) {
    min-width: 9.2rem;
    padding: 0.4rem 0.6rem;
    min-height: 2.8rem;

    font-size: 1.2rem;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: rgba($white, 0.8);
  }
}
