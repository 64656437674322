.category_sidebar {
  @include bp($point_3) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: $white;
    padding: 4rem 2rem;

    z-index: 100;

    opacity: 0;
    pointer-events: none;
  }
}

.category_sidebar_list {
  width: 100%;
}

.category_sidebar_item {
  height: 9.2rem;

  border-bottom: 1px solid #eee;

  &:first-child {
    border-top: 1px solid #eee;
  }

  @include bp($point_3) {
    height: auto;
  }
}

.category_sidebar_item_link {
  @extend %global_flex_block_row_wrap_flex-start_center;
  @extend %transition_color;

  height: 100%;

  color: $black_2;

  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 700;

  @include bp($point_3) {
    padding: 1.6rem 0;
  }

  &:hover,
  &:focus {
    color: $lilac_2;
    text-decoration: none;
  }
}

// mobile

.category_button {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: relative;
  padding: 1.6rem 0;

  cursor: pointer;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    width: 100vw;
    margin: 0 0 0 -50vw;
    height: 1px;

    background-color: $gray;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.category_button_icon {
  width: 1.8rem;
  height: 1.2rem;
  margin: 0 2.2rem 0 0;

  color: $lilac;
}

.caregory_button_info {
  margin: 0 0.6rem 0 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: $lilac;
}

.caregory_button_title {
  font-size: 1.4rem;
  font-weight: 700;
  color: $black;
}

// head

.category_head {
  @extend %global_flex_block_row_nowrap_flex-start;

  margin: 0 0 2rem;
}

.category_head_avatar {
  margin: 0 1.2rem 0 0;
  width: 4.8rem;
  height: 4.8rem;
  flex-shrink: 0;
  border-radius: 1.2rem;
  overflow: hidden;
}

.category_head_avatar_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.category_head_title {
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: 700;

  &.v2_mod {
    color: $lilac;
  }

  &.v3_mod {
    font-size: 1.4rem;
    color: $lilac;
  }
}
