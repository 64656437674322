@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

$hr_padding: 1.6rem;

//---------------------------------------------TalkerPage
.dashboard {
  @include bp($point_2, min) {
    display: flex;
    align-content: stretch;
    align-items: stretch;

    width: 100%;
    height: 100%;

    overflow: hidden;
  }

  @include bp($point_3) {
    // padding-top ($dashboard_header + 2.6rem)
    // padding: 0 $gl_mobile_indent 9.2rem;
    padding: 0 $gl_mobile_indent;
  }
}

.DashboardSidebar_wrap {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transform: translateX(100%);
  transition: all 0.3s ease-out !important;

  @include z-index(sidebar);

  &.popup-enter-done,
  &.popup-enter-active {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
  }

  &.popup-exit-active {
    transform: translateX(100%);
  }
}

.col {
  @include bp($point_2, min) {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  &.sidebarCol {
    @include bp($point_2, min) {
      padding-top: 2.8rem;
      padding-bottom: 5.4rem;
      padding-right: $hr_padding;
    }

    @include bp($point_9 + 1, min) {
      padding-left: ($hr_padding + 7.6rem);
    }

    @media (min-width: $point_2) and (max-width: $point_9) {
      padding-left: ($hr_padding + 4.8rem);
    }

    @include bp($point_2, min) {
      position: relative;

      width: 18%;

      &:after {
        position: absolute;
        top: 0;
        right: 0;

        width: 1px;
        height: 100%;

        background-color: $gray;

        content: '';
      }
    }
  }

  &.infoCol {
    @include bp($point_2, min) {
      padding: 7rem 5.8rem 5.8rem 5.8rem;
    }

    @include bp($point_2, min) {
      flex: 1 1 53.6%;
    }

    @include bp($point_3) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &.talksCol {
    @include bp($point_2, min) {
      position: relative;

      // padding: 5.8rem 1.8rem 1.8rem $hr_padding;
      padding: 9.2rem 2.2rem 0 2.2rem;
      width: 27.4%;

      &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 1px;
        height: 100%;

        background-color: $gray;

        content: '';
      }
    }
  }
}

.field {
  &:not(:last-child) {
    @include bp($point_2, min) {
      padding-bottom: 1.4rem;
      margin-bottom: 1.4rem;
      border-bottom: 1px solid $gray_10;
    }
  }

  &.accountField {
    @include bp($point_3) {
      padding-bottom: 2rem;
      margin-bottom: 2.2rem;

      border-bottom: 1px solid $gray_10;
    }
  }

  &.reminderField {
    border-bottom: 0;

    @include bp($point_3) {
      margin-bottom: 3.2rem;
    }
  }
}

.tabsContent {}

.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

//---------------------------------------------TalkerPage###