//------------------------------------------------------------layout
.header {
  position: fixed;
  top: 0;
  left: 0;

  height: var(--height-header);
  width: 100%;

  font-size: 1rem;
  background-color: white;

  will-change: transform;

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;

    content: '';

    width: 100%;
    height: 1px;

    background-color: var(--border-color);
  }

  @include z-index(header);

  &.remove {
    display: none;
    height: 0;
  }

  &.profile_mod {
    @include bp($point_2, min) {
      // padding-right: 2.4rem;
      padding: 0 3rem;

      &:before {
        display: none;
      }
    }

    @include bp($point_3) {
      width: 100%;
      // display: none;
      height: 4.6rem;

      background-color: #f8f8f8;
      transition: background-color 0.3s;

      &.scroll_mod {
        background-color: $white;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    // &.short_mod {
    //   @include bp($point_2, min) {
    //     width: 67.6%;
    //   }
    // }
  }
}

.callStrikePopup {
  text-decoration: none;
  color: $purple_2;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.header_v2 {
  position: fixed;
  top: 0;
  left: 0;

  height: var(--height-header);
  width: 100%;

  font-size: 1rem;

  will-change: transform;

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;

    content: '';

    width: 100%;
    height: 1px;

    background-color: var(--border-color);
  }

  @include z-index(header);

  // @include bp($point_5) {
  //   height: 7rem;
  // }
}

.header_in {
  @extend %global_flex_block_row_nowrap_space_between_center;

  height: 100%;

  .header.profile_mod & {
    max-width: 128rem;
    margin: 0 auto;
  }

  &-text {
    width: max-content;
    padding: 2rem;
    font-size: 1.6rem;

    &.size_mod {
      @include bp($point_5) {
        width: 25em;
        padding: 0 2rem;
      }
    }
  }

  &.limit_mod {
    align-items: center;
    max-width: 129.3rem;
    padding: 0 2rem;
    margin: 0 auto;
  }

  &.padding_mod {
    padding: 0 2rem 0 0;
  }

  &.mobile_wrap_mod {
    @include bp($point_4 - 1) {
      justify-content: flex-start;
    }
  }
}

.header_in_welcome {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  padding: 0 9.6em;
  margin: 0 auto;

  @include bp($point_3) {
    width: 100%;
    padding: 0 0;
    margin-right: 3em;
  }
}

.header_col {
  @extend %global_flex_block_row_wrap_flex-start;
  height: 100%;

  &:first-child {
    @include bp($point_2, min) {
      width: calc(100% - var(--sidebar-indent));
    }
  }

  &:last-child {
    @include bp($point_2, min) {
      width: var(--sidebar-indent);
      padding-right: 3.2rem;
    }
  }

  @include bp($point_3) {
    width: 100%;
  }
}

.header_col_v2 {
  @extend %global_flex_block_row_wrap_flex-start;
  height: 100%;

  @include bp($point_3) {
    width: 100%;
  }
}

.header_col_welcome {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;

  @include bp($point_3) {
    width: 100%;
    justify-content: space-between;
    margin: 0 2em;
  }
}

.header_welcome_logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3rem;
}

.header_welcome_logo img {
  width: 40px;
  margin-right: 0.5em;
}

.header_logo_w_block {
  @include bp($point_2, min) {
    width: 28.2%;

    // .header.short_mod & {
    //   width: 41.7%;
    // }
  }

  // @include bp($point_3) {
  //   margin-left: auto;
  // }
}

.header_logo_w_block_in {
  @include bp($point_2, min) {
    position: relative;

    max-width: 34rem;
    // margin-left: auto;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: -1px;
    //   width: 1px;
    //   height: 200%;
    //   background-color: $gray;
    // }
  }
}

.header_btn {
  margin-left: auto;

  @include bp($point_3) {
    display: none;
  }
}

.header_btn__link {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 4rem;
  min-width: 16.4rem;

  font-size: 1.5rem;
  font-weight: 300;
  color: #5a5a5a;

  border: 1px solid #d6d6d6;

  transition: all 0.3s;

  &:hover,
  &:focus {
    background-color: #5a5a5a;
    border-color: #5a5a5a;
    color: $white;
    text-decoration: none;
  }
}

.header_logo_w {
  @extend %global_flex_block_row_wrap_center_center;

  width: 8rem;
  height: 100%;

  &.border_mod {
    border-right: 1px solid var(--border-color);
  }

  &.profile_mod {
    @include bp($point_3) {
      // display: none;
      margin-left: auto;
    }
  }
}

.header_back {
  @include bp($point_2, min) {
    display: none;
  }

  @include bp($point_3) {
    width: 2rem;
    height: 2.8rem;
    color: $black;
    margin: 0 auto 0 1.6rem;
  }
}

.header_logo_z {
  @extend %global_flex_block_row_wrap_center_center;

  width: 8rem;
  height: 3rem;

  &.border_mod {
    border-right: 1px solid var(--border-color);
  }
}

.header_logo {
  @extend %global_flex_block_row_wrap_center_center;
  @extend %transition_opacity;
  margin-top: 1.3em;

  display: block;

  @include bp($point_2, min) {
    width: 4rem;
    height: 4rem;
  }

  @include bp($point_3) {
    width: 3.8rem;
    height: 3.8rem;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }

  &.pro_join_mod {
    position: absolute;
    top: 1.4rem;
    left: 38%;
    z-index: 1;
  }

  .header.profile_mod & {
    @include bp($point_3) {
      width: 3rem;
      height: 2rem;
      margin: 0;
    }
  }
}

.header_col_in_w {
  @extend %global_flex_block_row_nowrap_flex-start;

  width: calc(100% - 8rem);
  height: 100%;
}

.header_col_in_w_v2 {
  @extend %global_flex_block_row_nowrap_flex-start;
  justify-content: space-between;

  width: 80vw;
  height: 100%;
}

.header_col_in {
  position: relative;

  @extend %global_flex_block_row_nowrap_flex-start_center;
  height: 100%;

  overflow: visible;

  &:first-child {
    @include bp($point_2, min) {
      width: calc((100% - 7.2rem) * 0.518);
      flex-shrink: 0;
      padding: 1rem 1rem 1rem 2.2rem;
    }
  }

  &:last-child {
    @include bp($point_2, min) {
      flex-grow: 1;
      padding: 1rem 1rem 1rem 3.2rem;

      &:before {
        position: absolute;
        top: 0;
        left: 0;

        content: '';

        width: 1px;
        height: 100%;

        background-color: var(--border-color);
      }
    }
  }

  @include bp($point_3) {
    width: 100%;
    justify-content: space-between;
    margin-right: 2rem;
  }
}

.header_col_in_v2 {
  position: relative;

  @extend %global_flex_block_row_nowrap_flex-start_center;
  height: 100%;
  margin-left: 2rem;

  @include bp($point_3) {
    width: 100%;
    justify-content: space-between;
    margin-right: 1rem;
    margin-left: 0rem;
  }
}

.header_user {
  display: flex;
  min-width: max-content;
  align-items: center;
  justify-content: center;
}

.header_info_text {
  max-width: 56rem;

  color: $black_2;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.78;

  white-space: nowrap;

  &.mob_mod {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;

    margin: 0 2rem;
    font-weight: 700;
  }

  &.size_mod {
    font-size: 1.7rem;
    line-height: 1.5;
  }

  &.type_mod {
    padding-right: 10px;
  }

  a {
    text-decoration: none;
    color: $purple_2;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  &.wrap_mod {
    display: flex;
    flex-direction: row;
  }

  @include bp($point_3) {
    padding-left: 2rem;
  }
}

.header_nav_btn {
  @extend %global_flex_block_row_wrap_center_center;

  width: 4rem;
  height: 4rem;
  margin-right: 2.2rem !important;

  border-radius: 1rem;
  border: 1px solid $gray;

  transition: border-color 0.3s ease;
  will-change: border-color;

  cursor: pointer;

  @include bp($point_3) {
    margin-left: auto;
  }

  &:hover {
    border-color: $green_1;
  }

  &:last-child {
    margin-right: 0;
  }
}

.header_welcome_nav_btn {
  @extend %global_flex_block_row_wrap_center_center;
  display: none;

  @include bp($point_3) {
    display: flex;
    width: 4rem;
    height: 4rem;

    border-radius: 1rem;
    border: 1px solid $gray;

    transition: border-color 0.3s ease;
    will-change: border-color;

    cursor: pointer;
  }

  &:hover {
    border-color: $green_1;
  }

  &:last-child {
    margin-right: 0;
  }
}

.header_nav_trigger {
  position: relative;
  width: 1.8rem;
  height: 1.2rem;

  &:before,
  &:after {
    @extend %transition_background;

    position: absolute;
    left: 0;

    content: '';

    width: 100%;
    height: 2px;

    border-radius: 4px;
    background-color: $lilac;

    .header_nav_btn:hover & {
      background-color: $green_1;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.header_welcome_nav_trigger {
  position: relative;
  width: 1.8rem;
  height: 1.2rem;

  &:before,
  &:after {
    @extend %transition_background;

    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: $lilac;

    .header_welcome_nav_btn:hover & {
      background-color: $green_1;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.header_nav_trigger_in {
  @extend %transition_background;

  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  border-radius: 4px;
  background-color: $lilac;

  .header_nav_btn:hover & {
    background-color: $green_1;
  }
}

.header_welcome_nav_trigger_in {
  @extend %transition_background;

  position: absolute;
  top: 50%;
  left: 0;

  width: 100%;
  height: 2px;
  margin-top: -1px;

  border-radius: 4px;
  background-color: $lilac;

  .header_welcome_nav_btn:hover & {
    background-color: $green_1;
  }
}

.header_search_w {
  @extend %global_flex_block_row_wrap_center_center;

  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;

  border-radius: 1rem;
  border: 1px solid $gray;
  transition: border-color 0.3s ease;
  will-change: border-color;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-color: $green_1;
  }
}

.header_welcome_text {
  font-size: 1.5em;
  color: #6b59cc;
  cursor: pointer;
}

.header_search_icon {
  @extend %transition_color;

  width: 1.8rem;
  height: 1.8rem;
  color: $lilac;

  .header_search_w:hover & {
    color: $green_1;
  }
}

.header_side_block {
  @extend %global_flex_block_row_wrap_flex-start_center;

  height: 100%;
  margin-left: auto;
}

.header_side_block_welcome {
  @extend %global_flex_block_row_wrap_flex-start_center;

  height: 100%;
  width: 50em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
//------------------------------------------------------------
.header_btn_w {
  margin-left: auto;

  &.flex_mod {
    display: flex;
    position: relative;
  }

  &.mobile_nav_mod {
    flex-direction: column;
  }
}

.header_hamburger_icon {
  font-size: 3.2em;
  margin-left: auto;
}

.header_hamburger_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7em;
  bottom: 0em;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.header_hamburger_in {
  display: flex;
  flex-direction: column;
  margin-bottom: 15em;
}

.header_search_form_w {
  width: 100%;
}

.header_search_form {
  @extend %global_flex_block_row_wrap_flex-start_center;

  .form_field {
    width: calc(100% - 3.8rem);
  }
}

.search_btn {
  @extend %global_flex_block_row_nowrap_center_center;
  @extend %transition_color;

  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1.6rem;

  color: $green_1;
  cursor: pointer;

  &:hover {
    color: $green_1;
  }
}

.search_btn_icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
}

.header_welcome_button_container {
  display: flex;
  flex-direction: row;
  width: 40em;
  justify-content: space-between;
}

.header_text {
  display: flex;
  height: 100%;
}

.header_text_img {
  //margin-top: 2.5rem;
  height: 31%;
  align-self: center;
}

.header_info_text_title {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  margin-bottom: -8px;

  &.offset_mod {
    margin-bottom: -3px;
  }
}

.header_info_text_descr {
  font-size: 1.4rem;
  color: rgba(00, 00, 00, 0.6);
  line-height: 1.5em;

  &.title_mod {
    font-size: 1.4rem;
  }
}

.header_book_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 2em;
  margin-right: 1em;
}

.header_book_image {
  height: 60%;
  width: auto;
  border-radius: 15px;
}

.header_info_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.4rem;
}

.header_login_dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header_login_dropdown a {
  border: 1px solid #339f78;
}

// menu

.header_menu {
  position: absolute;

  @include bp($point_2, min) {
    top: calc(100% - 1rem);
    right: 2.4rem;

    min-width: 11rem;
    padding: 1.4rem 2rem;

    background-color: $white;
    border-radius: 1rem;
    border: 1px solid rgba($blue, 0.2);

    transform: translateY(-1rem);
    opacity: 0;
    pointer-events: none;

    transition: all 0.3s;

    .body--menu_open & {
      transform: translateY(0);
      opacity: 1;
      pointer-events: initial;
    }
  }

  @include bp($point_3) {
    @include flex_block(column, nowrap, flex-start, stretch);

    top: 0;
    left: 100%;

    width: 16.4rem;
    height: 100vh;
    padding: 5rem 2rem 2rem 3.2rem;

    background-color: #1f1f1f;
  }
}

.header_menu__list {
  @include bp($point_3) {
    margin-bottom: 3rem;
  }
}

.header_menu__item {
  margin-bottom: 1.8rem;

  &:last-child {
    margin: 0;
  }
}

.header_menu__link {
  display: block;

  font-size: 1.6rem;
  font-weight: 500;

  @include bp($point_2, min) {
    color: $blue_3;
    text-align: center;

    transition: color 0.3s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $black;
    }
  }

  @include bp($point_3) {
    color: $white;
  }
}

.header_menu__logo {
  width: 2.8rem;
  height: 2rem;
  margin: auto 0 0 auto;

  @include bp($point_2, min) {
    display: none;
  }
}

.header_menu__logo_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

//---------------------------------------------trigger
@mixin user_menu_butt_line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.2rem;
  margin-top: -0.2rem;
  border-radius: 0.2rem;
  background: $blue_3;
}

.menu_trigger {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 4rem;
  height: 4rem;

  border: 1px solid rgba($blue_3, 0.2);
  border-radius: 1rem;

  cursor: pointer;

  &.profile_mod {
    margin-right: 2.4rem;
    @include bp($point_3) {
      display: none;
    }
  }
}

.menu_trigger__block {
  z-index: 210;
  position: relative;
  height: 1.2rem;
  width: 1.8rem;
  margin-top: 0.1rem;
  text-transform: uppercase;

  pointer-events: none;
  color: transparent;
  cursor: pointer;

  &:before {
    content: '';
    transform: translate(0, -0.4rem);
    transition: all 0.3s ease;

    @include user_menu_butt_line;
  }
  &:after {
    content: '';
    transform: translate(0, 0.4rem);
    transition: all 0.3s ease;

    @include user_menu_butt_line;
  }
  .menu_trigger--active_mod & {
    &:before {
      transform: (rotate(45deg) translate(0, 0));
    }

    &:after {
      transform: (rotate(-45deg) translate(0, 0));
    }
  }
}

.menu_trigger_decor {
  transition: all 0.3s ease;

  .menu_trigger--active_mod & {
    opacity: 0;
    transform: translate(100%, 0);
  }

  @include user_menu_butt_line;
}

//---------------------------------------------trigger###
