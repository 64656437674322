@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------DashboardAccount
.dashboardAccount {
  display: flex;
  align-items: center;

  border-bottom: 0;

  &:not(:last-child) {
    @include bp($point_2, min) {
      padding-bottom: 1.4rem;
      margin-bottom: 1.4rem;
      border-bottom: 1px solid $gray_10;

      border-bottom: none !important;
      padding-bottom: 0 !important;
    }

    @include bp($point_3) {
      padding-bottom: 2rem;
      margin-bottom: 2.2rem;

      border-bottom: 1px solid $gray_10;
    }
  }
}

.dashboardAccountAvatarWrap {
  position: relative;

  width: 6.8rem;
  flex-shrink: 0;
  height: 6.8rem;
  margin-right: 1.6rem;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    cursor: pointer;
  }

  button {
    position: absolute;
    top: calc(100% - 0.2rem);
    left: 50%;

    font-size: 1.2rem;

    transform: translateX(-50%);
  }
}

.dashboardAccountAvatar {
  position: relative;

  width: 100%;
  height: 100%;

  border-radius: 50%;
  overflow: hidden;
}

.dashboardAccountAvatarIn {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboardAccountContent {}

.dashboardAccountName {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1;
  color: #1a1c1d;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
}

.dashboardAccountLink {
  font-size: 1.6rem;
  font-weight: 300;
  color: #7a7a7a;
  cursor: pointer;
}

.dashboardAccountLinkCopyIcon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.4rem;
  vertical-align: middle;
}

//---------------------------------------------DashboardAccount###