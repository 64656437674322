@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------DashboardHeader
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $dashboard_header;
  padding: 0 $gl_mobile_indent;
  margin: 0 (-$gl_mobile_indent) 2.6rem;

  background-color: $gray_10;

  &.headerV2 {
    @include bp($point_0) {
      padding: 0 16px 0 20px;
      margin-bottom: 0;
    }
  }
}

.hamburger {
  width: 2.4rem;
  height: 1.2rem;

  cursor: pointer;

  .headerV2 & {
    order: 3;
  }
}

.logo {
  width: 3rem;
  height: 2.2rem;
  margin-left: auto;

  .headerV2 & {
    margin-left: 0;
    margin-right: auto;
  }
}

.logoImage {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.headerSearch {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;

  order: 2;
}

.headerSearchIcon {
  display: block;
  width: 100%;
  height: 100%;
}

//---------------------------------------------DashboardHeader###
