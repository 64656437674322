@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

$checkboxSize: 2.4rem;
$checkboxDecorSize: 1.4rem;

//---------------------------------------------Checkbox
.root {
}

.checkbox {
  display: none;

  padding: 0 1.4rem;
}

.checkboxLabel {
  position: relative;
  display: flex;
  align-items: center;

  padding-left: ($checkboxSize + 1.6rem);
  min-height: $checkboxSize;

  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 50%;

    content: '';
  }

  &:before {
    left: 0;

    width: $checkboxSize;
    height: $checkboxSize;
    margin-top: -($checkboxSize / 2);

    box-shadow: 0 0 0 1px $gray_4;
    background-color: $white;
  }

  &:after {
    left: $checkboxSize / 2;

    width: $checkboxDecorSize;
    height: $checkboxDecorSize;
    margin-top: -($checkboxDecorSize / 2);
    margin-left: -($checkboxDecorSize / 2);

    opacity: 0;
    pointer-events: none;
    background-color: $blue_3;
    transition: opacity 0.3s ease;

    .checkbox:checked + & {
      opacity: 1;
    }
  }
}

.title {
  color: $black;
  font-size: 1.3rem;
  text-transform: capitalize;
}
//---------------------------------------------Checkbox###
