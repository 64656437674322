.footer {
  width: 100%;
  //padding:  2.8rem 4.8rem 2.8rem 4.4rem;
  padding: 3.8rem 4.8rem 0.8rem 4.4rem;
  min-height: $height_footer;
  position: relative;
  bottom: 0;
  font-size: 1rem;
  //height: 8.7rem;
  height: fit-content;
  max-width: 129.3rem;
  align-items: center;
  margin: 0 auto;

  border-top: 1px solid var(--border-color);
}

.footer_fixed {
  width: 100%;
  padding: 4.8rem 4.4rem;
  min-height: $height_footer;
  flex: 0 0 auto;

  display: flex;
  justify-content: center;

  font-size: 1rem;

  border-top: 1px solid $gray;
  will-change: transform;
}

.footer_in_fixed {
  width: 100%;
  max-width: 144em;
  padding: 0 9.6em;

  @include bp($point_3) {
    padding: 0 0;
  }
}

.footer_top {
  @extend %global_flex_block_row_nowrap_flex-start;

  @include bp($point_3) {
    flex-wrap: wrap;
  }
}

.footer_menu {
  margin: 0 4rem -2rem 0;
  column-count: 3;
  grid-column-gap: 7.6rem;
  column-gap: 1.6rem;
  justify-content: space-evenly;
  display: flex;

  @include bp($point_1) {
    column-gap: 2.6rem;
  }

  @include bp($point_3) {
    margin: 0 0 3rem;
    width: 100%;
    text-align: left;
  }

  @include bp($point_4 - 1) {
    display: flex;
    flex-wrap: wrap;
    column-count: inherit;
    column-gap: 0;
  }
}

.footer_menu_item {
  padding: 0 2rem 2rem 0;

  @include bp($point_4 - 1) {
    width: 50%;
  }
}

.footer_menu_link {
  font-size: 1.4rem;
  font-weight: 700;
  color: $black_2;

  @include bp($point_4 - 1) {
    font-size: 1.1rem;
  }
}

.footer_social {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @include bp($point_3) {
    width: 100%;
  }
}

.footer_social_block {
  @extend %global_flex_block_row_nowrap_flex-end_center;

  padding: 0 0 2rem;
  margin: 0 0 2.2rem;

  &:last-child {
    padding-left: 3em;
  }

  @include bp($point_3) {
    justify-content: center;
  }
}

.footer_social_title {
  margin: 0 1rem 0 0;

  font-size: 1.4rem;
  font-weight: 700;
  color: #b2b2b2;

  @include bp($point_4 - 1) {
    font-size: 1rem;
  }
}

.footer_copyright {
  font-size: 1.4rem;
  font-weight: 700;
  color: #b2b2b2;

  @include bp($point_3) {
    text-align: center;
  }

  @include bp($point_4 - 1) {
    font-size: 1rem;
  }
}

// footer_profile

.footer_profile {
  margin: auto -2rem 0 0;

  &.pos_mod {
    position: absolute;
    bottom: 4rem;
    left: 0;

    width: 100%;
    padding: 0 10%;

    @include bp(1400px) {
      padding: 0 2.6rem;
    }
  }

  @include bp($point_3) {
    display: none;
  }
}

.footer_profile__in {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  .footer_profile.pos_mod & {
    width: calc(50% - 18rem);
  }
}

.footer_profile__menu {
  @extend %global_flex_block_row_nowrap_flex-start;

  margin-right: 1rem;
}

.footer_profile__menu_item {
  position: relative;

  padding-right: 1rem;
  margin-right: 1rem;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;

    width: 0.2rem;
    height: 0.2rem;
    margin-top: -0.1rem;

    border-radius: 50%;

    background-color: #707070;
  }

  &:last-child {
    padding: 0;
    margin: 0;
    &:after {
      display: none;
    }
  }
}

.footer_profile__menu_link {
  font-size: 1.4rem;
  font-weight: 500;
  color: #707070;
}

.footer_profile__copyright {
  font-size: 1.4rem;
  font-weight: 500;
  color: #a0a0a0;
}
