.welcome_body {
  height: fit-content;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome_content {
  height: 100%;
  width: 100vw;
  padding: 0 6em;
  max-width: 90em;
  display: flex;
  flex-direction: column;

  @include bp($point_4) {
    padding: 0 0 0 0;
  }
}

.welc_section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 3em 0 1em;

  &.col {
    flex-direction: column;
  }

  &.center_mod {
    align-items: center;
  }

  &.first_section {
    margin: 1em 0 1em;
  }

  &.second_section {
    margin: 1em 0 1em;
    flex-direction: row-reverse;
  }

  @include bp($point_4) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 2em 0 1em;

    &.mobile_wrap {
      flex-direction: column;
    }
  }
}

.welc_intro_content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;

  @include bp($point_4) {
    align-items: center;
    width: 100%;
    padding: 0 1em;
  }
}

.welc_intro_text_container {
  width: 100%;
}

.welc_intro_title {
  width: 100%;
  font-size: 5rem;
  font-weight: 600;
  line-height: 1.1;
  color: $black_2;

  @include bp($point_5) {
    font-size: 4.5rem;
  }

  &.center_align {
    text-align: center;
  }

  &.mobile_center {
    @include bp($point_4) {
      text-align: center;
      line-height: 1.2em;
    }
  }

  &.margin_mod {
    margin: 1em 0;
  }

  &.sm {
    font-size: 2.8rem;
    font-weight: 500;
  }

  &.xs {
    font-size: 5rem;
    font-weight: 400;
  }
}

.welc_intro_descr {
  width: 100%;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: $black_2;
  margin: 1.5em 0 1.5em;

  &.mobile_center {
    @include bp($point_4) {
      text-align: center;
    }
  }
}

.welc_intro_img {
  width: 50%;
  display: flex;
  justify-content: center;

  @include bp($point_3) {
    width: 100%;
    padding-right: 0em;
  }
}

.welc_manage_content {
  display: flex;
  flex-direction: column;
}

.welc_manage_block {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include bp($point_4) {
    margin-left: 1em;
  }
}

.welc_manage_item {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  padding-right: 2.5em;

  @include bp($point_4) {
    padding-right: 0em;
    margin-bottom: 1em;
  }
}

.welc_manage_item img {
  height: 3em;
  max-width: 3em;
}

.welc_manage_title {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0.5em 0;
}

.welc_manage_descr {
  width: 100%;
  font-size: 1.4rem;
  min-height: 6em;

  @include bp($point_4) {
    padding-right: 10px;
  }
}

.welc_manage_left {
  width: 50%;
  display: flex;
  justify-content: center;

  @include bp($point_4) {
    width: 90%;
    margin: 2em 0 1em;
  }
}

.welc_manage_left img {
  width: 50%;
  @include bp($point_4) {
    width: 100%;
  }
}

.welc_manage_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include bp($point_4) {
    width: 100%;
  }
}

.welc_how_content {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.welc_how_items {
  display: flex;
  flex-direction: row;

  @include bp($point_4) {
    flex-direction: column;
  }
}

.welc_how_item {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
}

.welc_how_item_text {
  display: flex;
  flex-direction: column;
}

.welc_how_img_container {
  width: 7em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  @include bp($point_4) {
    align-items: flex-start;
  }
}

.welc_ellipse {
  position: absolute;
  width: 80%;
  z-index: 3;
  @include bp($point_4) {
    top: -0.3em;
  }
}

.welc_number {
  position: absolute;
  color: white;
  font-size: 1.6em;
  margin-top: -5px;
  z-index: 4;
  @include bp($point_4) {
    top: 1.5em;
    margin-top: -34px;
    margin-left: -1px;
    font-size: 1.4em;
  }
}

.welc_talkpro_preview {
  width: 100%;
}

.welc_talkpro_preview img {
  width: 100%;
  max-width: 100%;
}

.welc_talkpro_preview_descr {
  margin: 1em 0 0.5em;

  @include bp($point_4) {
    text-align: center;
    padding: 0 0.7em;
    margin: 0 0 0.5em;
  }
}

.welc_review_container {
  width: 45%;
  padding: 2em 1.3em;
  position: relative;

  &.left {
    box-shadow: 3px 3px 5px 1px #f4f4f4;
  }

  &.right {
    box-shadow: -3px 3px 5px 1px #f4f4f4;
  }

  @include bp($point_4) {
    width: 95%;
    margin-top: 1em;
  }
}

.welc_review_content {
  display: flex;
  flex-direction: column;
}

.welc_review_title {
  font-size: 1.7rem;
  font-weight: 500;
  padding-bottom: 5px;
}

.welc_review_time {
  font-size: 1.1rem;
}

.welc_review_descr {
  font-size: 1.4rem;
  padding-top: 1em;
}

.welc_review_image {
  position: absolute;
  border-radius: 50%;
  top: 1.7em;
  right: 1.7em;
  width: 3em;
}

.welc_review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2em 0;

  @include bp($point_4) {
    flex-direction: column;
    align-items: center;
    margin: 0.5em 0em 2em;
  }
}

.welc_intro_form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include bp($point_4) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.welc_intro_form {
  margin: 1em 0 1.5em;
  width: 60%;
}

.welc_google_button {
  margin-right: 0.7em;
}
