@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------SignUpPopup

.btnWrap {
  margin-bottom: 2.6rem;
}

.title {
  margin-bottom: 0.4rem;

  font-size: 2rem;
}

.description {
  margin-bottom: 1rem;

  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6;
  color: #9a9a9a;
  letter-spacing: -0.01rem;

  @include bp($point_4, min) {
    max-width: 90%;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  min-width: 16rem;

  font-size: 1.5rem;
  font-weight: 500;
  color: #1a1c1d;

  cursor: pointer;

  border: 1px solid #707070;
  background-color: $white;

  transition: all 0.3s;

  &:hover,
  &:focus {
    background-color: #707070;
    color: $white;
  }
}

.meetingInfoRow,
.meetingInfoCol {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 0.7rem;
  }
}

.meetingInfoName {
  font-size: 1.5rem;
  font-weight: 500;
  color: $gray_5;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.meetingInfoBlock {
  padding-bottom: 2rem;
  margin-bottom: 1.4rem;

  border-bottom: 1px solid $gray_10;
}

.attending {
  padding-bottom: 2rem;
  margin-bottom: 1.4rem;

  border-bottom: 1px solid $gray_10;
}

.meetingInfoColTitle {
  margin-bottom: 1rem;

  font-size: 1.3rem;
  font-weight: 300;
  color: $gray_9;
}

.meetingInfoUser {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }
}

.meetingInfoAvatar {
  border-radius: 100%;
  background-color: #e0e0e0;
}

.userAvatar {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.8rem;
  height: 3.8rem;
  margin-right: 1.2rem;

  font-size: 1.5rem;
  color: $white;
  text-transform: uppercase;

  border-radius: 50%;
  background-color: #808080;

  transition: background-color;
}
