$offset: 2.2rem;

.dashboardIndicator {
  display: flex;
  margin: 0 (-$offset) (-$offset) 0;
}

.dashboardIndicatorCol {
  padding: 0 $offset $offset 0;

  &:first-child {
    margin-right: auto;
  }

  &:last-child {
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.dashboardIndicatorValue,
.dashboardIndicatorTitle {
  font-size: 2rem;
  font-weight: 500;
  color: #1a1c1d;
  line-height: 1.1;
}

.dashboardIndicatorTitle {
}

.dashboardIndicatorValue {
  margin-bottom: 1rem;
  text-align: right;
}

.dashboardIndicatorInfo {
  font-size: 1.4rem;
  font-weight: 300;
  color: #7a7a7a;
}

.dashboardIndicatorRatio {
  width: 7rem;
  height: 0.4rem;
  margin-left: auto;
}

.dashboardIndicatorRatioValue {
  height: 0.5rem;
  margin-left: auto;

  border-radius: 3rem;
  background-color: #ff5de0;

  &.dashboardIndicatorRatioValueColorMod {
    height: 100%;

    background-color: #fa6d15;
  }
}
