@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------VideoList

.container {
  :global(.spinner) {
    margin-top: 3rem;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;

  gap: 2.6rem 2.4rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  border-bottom: 1px solid $gray_10;
}

.item {
  position: relative;

  width: calc(50% - 1.2rem);
}

.item__link {
  @include flex_block(row, wrap, flex-start, center);

  color: $black;

  opacity: 0.26;

  transition: opacity 0.3s;
  cursor: pointer;

  .checkbox:checked + & {
    opacity: 1;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.item__image_wrap {
  width: 100%;
  aspect-ratio: 1.77;
  margin-bottom: 0.4rem;

  border-radius: 1.6rem;
  overflow: hidden;
}

.item__image {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: transform 0.3s ease;

  .item__link:hover & {
    transform: scale(1.4);
  }
}

.item__content {
  padding: 0 1.4rem;

  font-size: 1.3rem;
  font-weight: 700;
}

.checkbox {
  display: none;
}

.message {
  font-size: 1.6rem;
}
//---------------------------------------------VideoList###
