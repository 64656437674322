@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------Button
.button {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-family: $font_1;
  font-weight: 500;
  text-align: center;
  line-height: 1;

  cursor: pointer;
  appearance: none;
  overflow: hidden;

  transition: background-color 0.3s ease, color 0.3s ease;
}

.buttonBaseSize {
  height: 5.4rem;
  padding: 2rem 2.3rem;

  font-size: 1.3rem;
}

.buttonMdSize {
  min-width: 22.4rem;
  min-height: 4.2rem;
  padding: 0.4rem 1rem;

  font-size: 1.4rem;
}

.buttonMd2Size {
  min-width: 22.5rem;
  padding: 1px 1rem 0;
  height: 4.1rem;
  border-radius: 0.5rem;

  font-size: 1.4rem;
  font-weight: 400;
}

.buttonLgSize {
  padding: 0 1rem;
  height: 5.4rem;
  border-radius: 0.5rem;

  font-size: 1.4rem;
  font-weight: 400;
}

.buttonSmSize {
  min-width: 12rem;
  min-height: 3.6rem;
  padding: 0.4rem 1rem;

  font-size: 1.6rem;
}

.buttonSm2Size {
  min-width: 7.4rem;
  min-height: 2.5rem;
  padding: 0.6rem;

  font-size: 1.2rem;
}

.buttonFullWidth {
  width: 100%;
  max-width: 100%;
}

.buttonPrimary {
  color: $white;

  background-color: $blue_3;

  &:hover,
  &:focus {
    background-color: darken($blue_3, 10%);
  }
}

.buttonSecondary,
.buttonDefault {
  color: $blue_3;

  background-color: $white;

  &:hover,
  &:focus {
    background-color: darken($white, 3%);
  }
}

.buttonSecondary {
  box-shadow: 0 0 0 1px $blue_3;
}

.buttonTertiary {
  color: $white;

  background-color: $green_3;

  &:hover {
    background-color: darken($green_3, 10%);
  }
}

.buttonQuaternary {
  background-color: #516f92 !important;
  color: $white !important;
}

.buttonCenterContent {
  justify-content: center;
}

.buttonQuinary {
  color: $blue_4;

  background-color: $green_4;

  &:hover {
    background-color: darken($green_4, 10%);
  }
}

.buttonSuccess {
  color: $blue_4;

  background-color: $green_2;

  &:hover,
  &:focus {
    background-color: darken($green_2, 10%);
  }
}

.buttonOutlined2 {
  color: #414141;
  font-weight: 400;
  line-height: 1.2;

  background-color: transparent;
  box-shadow: 0 0 0 1px #b9b9b9;

  &:hover,
  &:focus {
    background-color: darken(#b9b9b9, 3%);
  }
}

.buttonOutlined {
  // color: #414141;
  color: $white;

  background-color: transparent;
  box-shadow: 0 0 0 1px $white;

  &:hover,
  &:focus {
    background-color: darken($blue_4, 5%);
  }
}

.buttonDefault {
  box-shadow: 0 0 0 1px $gray_4;
}

.buttonDark {
  color: $white;

  background-color: $black_5;

  &:hover,
  &:focus {
    background-color: darken($black_5, 10%);
  }
}

.buttonLight {
  color: $white;

  box-shadow: 0 0 0 1px $white;

  &:hover,
  &:focus {
    color: $black;
    background-color: $white;
  }
}

.buttonRoundedSm {
  border-radius: 0.8rem;
}

.icon {
  position: absolute;
  left: 1.8rem;
  top: 50%;

  width: 2.8rem;
  height: 2.8rem;
  margin-top: -1.4rem;
}

.iconV2 {
  width: 1.8rem;
  height: 1.8rem;
  margin-top: -.1rem;
  margin-right: 0.8rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.iconImage {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.spinner {
  :global(.spinner) {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
  }
}

//---------------------------------------------Button###