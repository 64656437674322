@import 'styles/helpers/_variables.scss';

.player {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid $gray_9;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  text-align: center;
}
