@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------SignUpPopup

.title {
  margin-bottom: 0.8rem;

  font-size: 2rem;
}

.error {
  margin-bottom: 0.8rem;

  font-size: 1.6rem;
  color: red;
}

.description {
  margin-bottom: 2rem;

  font-size: 1.6rem;
  line-height: 1.6;
  color: $gray_8;
  letter-spacing: -0.01rem;

  @include bp($point_4, min) {
    max-width: 90%;
  }
}

// form

.formInputDropdownOverlay {
  z-index: 8;
  position: absolute;
  width: 100%;
}

.formList {
  padding-bottom: 2.4rem;
  margin-bottom: 2.2rem;

  border-bottom: 1px solid $gray_10;
}

.formItem {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}

.formInput {
  display: block;
  width: 100%;
  height: 5.4rem;
  padding: 0 5.4rem 0 1.6rem;

  font-size: 1.6rem;
  font-weight: 500;
  font-family: $font_1;
  color: black;

  border: 1px solid $gray_4;

  &::-webkit-input-placeholder {
    color: $blue_3;
  }

  &::-moz-placeholder {
    color: $blue_3;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $blue_3;
  }
}

.formIcon {
  position: absolute;
  top: 50%;
  right: 1.4rem;

  width: 3.2rem;
  height: 2.2rem;
  margin-top: -1.1rem;

  path {
    fill: #dc2217;
  }
}

.formLabel {
  color: black;
}

.formAdd {
  display: flex;
  align-items: center;
  width: 23rem;
  max-width: 100%;
  height: 5.4rem;
  padding: 0 1.4rem;

  font-size: 1.6rem;
  font-weight: 500;
  font-family: $font_1;
  color: black !important;

  border: 1px solid $gray_4;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover,
  &:focus {
    background-color: $blue_3;
    color: $white;
  }

  &:not(:last-child) {
    margin-bottom: 2.8rem;
  }
}

.formAddIcon {
  position: relative;

  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;

  border-radius: 50%;
  border: 1px solid currentColor;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: currentColor;
  }

  &:before {
    width: 1rem;
    height: 1px;
    margin: -0.5px 0 0 -0.5rem;
  }

  &:after {
    width: 1px;
    height: 1rem;
    margin: -0.5rem 0 0 -0.5px;
  }
}

//---------------------------------------------
$item_hr_offset: 2.2rem;
$item_vr_offset: 2rem;

.formContainer {
  margin-bottom: 2rem;
}

.list {
  @include bp($point_4, min) {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: wrap;

    margin-right: -$item_hr_offset;
    margin-bottom: -$item_vr_offset;
  }
}

.item {
  @include bp($point_4, min) {
    width: 100%;

    padding-right: $item_hr_offset;
    padding-bottom: $item_vr_offset;
  }

  @include bp($point_4 - 1) {
    &:not(:last-of-type) {
      margin-bottom: $item_vr_offset;
    }
  }

  &.itemHalfMod {
    @include bp($point_4, min) {
      width: 50%;
    }
  }

  &.itemCalendar {
    position: relative;
  }
}

//---------------------------------------------CreateClassForm###
