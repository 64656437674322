//------------------------------------------------------------base styles
:root {
  @include bp($point_2 + 1, min) {
    --full-screen: 100vh;
    --height-header: 7.4rem;
    --dashboard-header: 5.6rem;
  }

  @include bp($point_2) {
    --full-screen: calc(var(--vh, 1vh) * 100);
    --height-header: 7rem;
  }

  --sidebar-indent: 41rem;
  --gl-block-indent: 3.2rem;
  --border-color: #eceef5;

  --side-col: 29%;
}

// scrollbar
* {
  scrollbar-width: thin;
  scrollbar-color: $gray transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 2px;

  @include bp($point_3) {
    width: 0;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($lilac_6, 0.6);
}

::-webkit-scrollbar-thumb:active {
  background: $lilac_6;
}

html {
  max-height: 100vh;
  height: 100%;
  font: 400 calc(100vw / 1920 * 10) / 1.5 $font_1;

  font-size: 10px;

  @media (max-width: 1200px) and (min-width: 1024px) {
    font-size: calc(100vw / 1200 * 10);
  }

  @include bp(375px) {
    font-size: calc(100vw / 375 * 10);
  }

  @media (min-width: 1920px) {
    font: 400 calc(100vw / 1920 * 10) / 1.5 $font_1;
  }
}

body {
  position: relative;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  font-size: $font_size_base;
  color: $color_text_base;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $body_bg;

  &.body--menu_open .wrapper > * {
    @include bp($point_3) {
      transform: translateX(-16.4rem);
    }
  }

  @supports (display: grid) {
    display: block;
  }

  & > iframe {
    pointer-events: none;
  }
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $orange;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & > * {
    @include bp($point_3) {
      transition: transform 0.3s;
    }
  }

  @include bp($point_3) {
    // overflow: scroll;
  }
}

.base {
  width: 100%;
  position: relative;
  padding: var(--height-header) 0 0;

  &.no_header_mod {
    padding: 0;
    height: 100%;
  }

  @include bp($point_3) {
    margin-bottom: 5.5em;
    height: 100%;
  }
}

a {
  text-decoration: none;
  color: $color_link_base;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

button {
  font-family: $font_1;
}

//------------------------------------------------------------base styles###

.video-react .video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
