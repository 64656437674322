@mixin st_btn() {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  white-space: nowrap;

  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

//color extends
%liliac_btn_1 {
  font-weight: 700;
  color: $white;
  background-color: $lilac_2;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken($lilac_2, 10%);
  }
}

%green_btn_1 {
  font-weight: 500;
  color: $white;
  background-color: #4db67e;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken(#4db67e, 10%);
  }
}

%green_btn_2 {
  font-weight: 700;
  color: #4db67e;
  background-color: $white;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken(#4db67e, 10%);
    color: white;
  }
}

%green_btn_3 {
  font-weight: 400;
  color: $blue_4;
  background-color: $green_3;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken($green_3, 10%);
  }
}

%white_btn_1 {
  font-weight: 700;
  color: #535353;
  transition: 0.3s ease;
  border: 1px solid $gray;

  @include st_btn();

  &:hover {
    border-color: $black;
    color: $black;
  }
}

%gray_btn_1 {
  font-weight: 700;
  color: $lilac;
  transition: 0.3s ease;
  background-color: $gray_5;

  @include st_btn();

  &:hover {
    background: darken($gray_5, 10%);
  }
}

%gray_btn_2 {
  font-weight: 450;
  color: $white;
  transition: background 0.3s ease;
  background-color: $gray_11;

  @include st_btn();

  &:hover {
    background: lighten($gray_11, 10%);
  }
}

%blue_btn_1 {
  font-weight: 500;
  color: $blue;
  transition: 0.3s ease;
  background-color: $white;

  @include st_btn();

  &:hover {
    opacity: 0.7;
  }
}

%blue_btn_2 {
  font-weight: 500;
  color: $white;
  background-color: $blue;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken($blue, 10%);
  }
}

%blue_btn_3 {
  font-weight: 500;
  color: $blue_3;
  border: 1px solid $blue_3;
  background-color: transparent;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: $blue_3;
    color: $white;
  }
}

%blue_btn_4 {
  font-weight: 500;
  color: $white;
  background-color: $blue_3;
  background-color: transparent;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken($blue_3, 10%);
    color: $white;
  }
}

%blue_btn_5 {
  font-weight: 500;
  color: $white;
  background-color: $blue_3;
  transition: 0.3s ease;

  @include st_btn();

  &:hover {
    background: darken($blue_3, 10%);
  }
}

//size extends
%btn_size_1 {
  height: 5.4rem;
  max-width: 42rem;
  width: 100%;
  padding: 0 1.2rem;

  line-height: 5rem;
  font-size: 1.7rem;

  border-radius: 10rem;

  @include bp($point_3) {
    font-size: 1.6rem;
  }
}

%btn_size_2 {
  height: 4.6rem;
  padding: 0 1.2rem;
  width: 16.4rem;

  line-height: 4.6rem;
  font-size: 1.4rem;

  border-radius: 1rem;
}

%btn_size_3 {
  height: 3.3rem;
  padding: 0 1.2rem 0.2rem 1.2rem;
  width: 10rem;

  line-height: 2.8rem;
  font-size: 1.4rem;

  border-radius: 0.4rem;
}

%btn_size_4 {
  height: 4.4rem;
  padding: 0 1.2rem;
  min-width: 11.6rem;

  line-height: 4.4rem;
  font-size: 1.7rem;

  border-radius: 7rem;
}

%btn_size_5 {
  height: 5.4rem;
  padding: 0 1.2rem;
  width: 100%;

  line-height: 5.4rem;
  font-size: 1.4rem;

  border-radius: 0;
}

%btn_size_6 {
  height: 4.2rem;
  //padding: 0 1.2rem;
  min-width: 11.6rem;

  line-height: 4.4rem;
  font-size: 1.7rem;

  border-radius: 1rem;
}

%btn_size_7 {
  height: 2.8rem;
  padding: 0 1.2rem;

  line-height: 2.8rem;
  font-size: 1.4rem;
}

%btn_size_8 {
  height: 5.4rem;
  max-width: 25.6rem;
  width: 100%;
  padding: 0 1.2rem;

  line-height: 5.4rem;
  font-size: 1.5rem;
}

%btn_size_9 {
  height: 4rem;
  padding: 0 4.8rem;

  line-height: 4rem;
  font-size: 1.5rem;
}

%btn_size_10 {
  height: 4rem;
  padding: 0 2rem;

  line-height: 4rem;
  font-size: 1.4rem;

  border-radius: .5rem;
}