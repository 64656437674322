@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.DashboardSearch {
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;

  pointer-events: none;

  &.DashboardSearchActive {
    background-color: $white;
    pointer-events: initial;
  }

  @include bp($point_2, min) {
    position: absolute;
    height: 100%;

    padding: 0 0 0 1.2rem;
  }

  @include bp($point_3) {
    position: fixed;
    height: 100vh;

    // width: auto;
    // margin: 0 #{$gl_mobile_indent * -1};
    // width: calc(100% + #{$gl_mobile_indent * 2});
    padding: 0 1.8rem 0;
  }
}

.DashboardSearchHead {
  display: flex;
  align-items: center;
  background-color: $white;

  pointer-events: initial;

  @include bp($point_2, min) {
    padding: 1.4rem 0 1.1rem 0.6rem;
    border-bottom: 1px solid $gray_10;
  }

  @include bp($point_3) {
    position: relative;
    padding: 1.2rem 1.8rem 1.6rem;
    height: 5.6rem;

    margin: 0 -1.8rem 0;

    background-color: #ededed;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;

      display: none;
      width: 100vw;
      height: 1px;
      margin-left: -50vw;

      background-color: $white;
    }
  }
}

.DashboardSearchBack {
  width: 1.8rem;
  height: 2.2rem;
  margin-right: 1.6rem;
  flex-shrink: 0;

  color: #414141;
  transition: color 0.3s;

  cursor: pointer;

  &:hover,
  &:focus {
    color: $black;
  }
}

.DashboardSearchBackIcon {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.DashboardSearchForm {
  margin-right: 1rem;
  flex-grow: 1;
}

.DashboardSearchInput {
  display: block;
  width: 100%;

  font-size: 1.5rem;
  font-weight: 500;
  color: #414141;
  font-family: $font_1;

  border: none;
  background: none;
}

.DashboardSearchClose {
  position: relative;
  cursor: pointer;

  color: #9a9797;

  svg {
    fill: currentColor;
  }

  &:before {
    content: '';
    position: absolute;
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  @include bp($point_0, min) {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.2rem;
    margin-top: 0.2rem;
  }

  @include bp($point_0 - 1) {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.DashboardSearchCloseIcon {
  display: block;
  width: 100%;
  height: 100%;
}

// results

.DashboardSearchMain {
  flex-grow: 1;
  max-height: calc(100% - 6.8rem);
}

.DashboardSearchMainIn {
  height: 100%;
  overflow-y: auto;

  @include bp($point_2, min) {
    padding: 1.4rem 0 8.4rem;
  }

  @include bp($point_3) {
    padding: 1.6rem 0 6rem;
  }
}

.DashboardSearchItem {
  &:not(:last-child) {
    margin-bottom: 1.9rem;
  }
}

.DashboardSearchItemTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #848484;
}

.DashboardSearchItemText {
  font-size: 1.5rem;
  font-weight: 300;
  color: #848484;
  line-height: 1.4;

  b,
  strong {
    color: #414141;
  }
}

//
.DashboardSearchMainBtnWrap {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  width: 100%;

  @include bp($point_2, min) {
    bottom: 2.2rem;
  }

  @include bp($point_3) {
    bottom: 0;
  }
}

.DashboardSearchMainBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5rem;

  font-size: 1.5rem;
  font-weight: 500;
  font-family: $font_1;
  background-color: $blue_3;
  color: $white;

  transition: all 0.3s;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $black_3;
  }
}

.DashboardSearchMainBtnIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  fill: currentColor;
}