@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.popup {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  width: 100%;
  height: 100%;
  z-index: 100;

  background-color: rgba(#000, 0.7);
}

.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

.container {
  width: 60rem;
  margin: auto auto;
}

.video {
  display: block;
  width: 100%;
}
