@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------SignUpPopup
.popup {
  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
  z-index: 100;

  background-color: rgba(#000, 0.7);
}

.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

.container {
  height: 100%;

  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;

  background-color: $white;

  @include bp($point_2, min) {
    width: 57rem;
    padding: 6.6rem 5.6rem 5rem 4.2rem;
    margin-left: auto;
  }

  @include bp($point_3) {
    padding: 3rem $gl_mobile_indent;
  }

  .popup-enter-done &,
  .popup-enter-active & {
    transform: translateX(0%);
  }
}

.closeButton {
  @include bp($point_4, min) {
    display: none;
  }

  @include bp($point_4 - 1) {
    position: relative;

    display: block;
    width: 3rem;
    height: 3rem;
    margin-bottom: 3rem;
    margin-left: auto;

    &:before {
      position: absolute;
      top: -1rem;
      left: -1rem;
      right: -1rem;
      bottom: -1rem;

      content: '';
    }
  }
}

.helpWrap {
  @include bp($point_0, min) {
    margin: auto 0;

    &.helpWrapActive {
      margin: 0;
    }
  }
}

.head {
  padding-bottom: 0.6rem;
  margin-bottom: 2rem;

  border-bottom: 1px solid #ededed;
}

.title {
  margin-bottom: 0.8rem;

  font-size: 2rem;
}

.description {
  margin-bottom: 1rem;

  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6;
  color: #9a9a9a;
  letter-spacing: -0.01rem;

  @include bp($point_4, min) {
    // max-width: 90%;
  }
}

// help

.help {
  margin-bottom: 2rem;
}

.helpList {
  padding-left: 1rem;
  margin-bottom: 2.4rem;
}

.helpItem {
  &:not(:last-child) {
    margin-bottom: 2.3rem;
  }
}

.helpBtn {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 300;
  color: #2c2e2f;

  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: blue;
  }
}

.helpIcon {
  width: 1.8rem;
  height: 1.6rem;
  flex-shrink: 0;
  margin-right: 1.6rem;
}

.helpSearch {
  position: relative;
  margin-bottom: 24px;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.helpSearchSubmit {
  position: absolute;
  top: 50%;
  left: 1.8rem;

  width: 1.8rem;
  height: 1.8rem;
  margin-top: -0.9rem;

  cursor: pointer;
}

.helpSearchInput {
  display: block;
  width: 100%;
  height: 4.6rem;
  padding: 0 2rem 0 4.8rem;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: $font_1;
  color: $black;
  border: none;
  border-radius: 4.6rem;
  background-color: #ededed;

  border: 1px solid #ddd !important;
  background-color: transparent !important;

  &::-webkit-input-placeholder {
    color: #5d5d5d;
  }

  &::-moz-placeholder {
    color: #5d5d5d;
  }

  &:-ms-input-placeholder {
    color: #5d5d5d;
  }
}

// article

.article {
  // padding-right: 2.2rem;
}

.articleHead {
  margin-bottom: 2rem;
  padding-bottom: 2.2rem;

  border-bottom: 1px solid #ededed;
}

.articleBack {
  width: 1.8rem;
  height: 2.2rem;

  cursor: pointer;
}

.articleBackIcon {
  display: block;
  width: 100%;
  height: 100%;
}

.articleTitle {
  margin-bottom: 1.8rem;

  font-size: 2rem;
  font-weight: 500;
  color: #1a1c1d;
}

.articleText {
  margin-bottom: 3rem;

  font-size: 1.5rem;
  line-height: 1.7;
  font-weight: 300;
  color: #707070;

  p {
    &:not(:last-child) {
      margin-bottom: 2.8rem;
    }
  }
}

.articleBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5.4rem;
  width: 100%;

  font-size: 1.5rem;
  font-weight: 500;
  color: $white;

  background-color: #2c2e2f;
  transition: all 0.3s;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $black_4;
  }
}

// feedback

.feedbackWrap {
  padding: 2.6rem 0 0 1.4rem;
  border-top: 1px solid #ededed;
}

.feedback {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 500;
  color: #007eff;

  &:hover,
  &:focus {
    color: $black_4;
    text-decoration: none;
  }
}

.feedbackIcon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.feedbackIconEl {
  display: block;
  width: 100%;
  height: 100%;
}