@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------WaitingRoomSection
.component {
  position: relative;

  height: 100vh;
  padding-left: $gl_mobile_indent;
  padding-right: $gl_mobile_indent;
  display: flex;

  text-align: center;

  background-color: $black_4;
  overflow: hidden;
  overflow-y: auto;

  @include z-index(talker-section);

  @include bp($point_2, min) {
    flex-direction: row;

    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  @include bp($point_3) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2.4rem;
    padding-bottom: 4rem;
  }

  &.huddleVariant {
    background-color: $blue_4;
  }
}

.container {
  @include bp($point_2, min) {
    max-width: 54.2rem;
    margin: auto;
    width: 100%;
  }

  @include bp($point_3) {
    width: 100%;
    margin: auto;
  }
}

.logout {
  position: absolute;
  top: 3.2rem;

  @include bp($point_2, min) {
    right: 5.6rem;
  }

  @include bp($point_3) {
    right: $gl_mobile_indent;
  }
}

.button {
  display: inline-flex;
  align-items: center;

  font-size: 1.6rem;
  font-weight: 500;
  color: $white;

  transition: opacity 0.3s ease;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}

.buttonIconWrap {
  margin-left: 1rem;
  width: 1.4rem;
  height: 1.4rem;
}
//---------------------------------------------WaitingRoomSection###
