@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.DashboardRecentCalls {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;

  // @include bp($point_0, min) {
  //   max-height: calc(100vh - 6rem - 1.8rem);
  // }

  @include bp($point_0) {
    min-height: 100%;
  }

  @include bp($point_3) {
    .dashboardCreateMeetingWrap {
      display: none;
    }
  }
}

.DashboardRecentCallsHead {
  position: relative;
  // margin-bottom: 0.6rem;
  line-height: 1.3;
  // border-bottom: 1px solid #eeeeee;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: 1px;

    background-color: #eee;

    display: none;
  }

  @include bp($point_2, min) {
    padding: 0 4rem 1rem 1.8rem;
  }

  @include bp($point_3) {
    padding: 0 0 1.6rem 0.8rem;
  }

  @include bp($point_3) {
    display: none;
  }
}

.DashboardRecentCallsTitle {
  font-size: 2rem;
  font-weight: 500;
  color: $black_2;
}

.DashboardRecentCallsSubtitle {
  font-size: 1.6rem;
  font-weight: 300;
  color: #7a7a7a;
}

.DashboardRecentCallsSeach {
  position: absolute;
  top: 50%;
  right: 1.2rem;

  width: 1.6rem;
  height: 1.6rem;
  margin-top: -0.8rem;

  cursor: pointer;
}

.DashboardRecentCallsSeachIcon {
  display: block;
  width: 100%;
  height: 100%;
}