@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.dashboardSchedule {
  width: 30em;
  margin-right: 1.2rem;
  // flex: 1 0 30em;

  width: 100% !important;
  margin-right: 0 !important;
  clear: both;
  padding-top: 19px;
}

.dashboardScheduleFeature {
  margin-bottom: 0.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $blue_3;
}

.dashboardScheduleFeature2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #1a1c1d;
}

.dashboardInfo {
  display: flex;
  // padding: 0 1.2rem;
  max-width: 100%;

  width: 100%;
  display: block !important;

  @include bp($point_4 - 1) {
    margin-top: 20px;
  }
}

.dashboardUserInfo {
  width: 9000% !important;
}

.dashboardScheduleItem {
  &:not(:last-child) {
    border-bottom: 1px solid #eee;

    border-bottom: 0 !important;
    padding-bottom: 10px !important;

    @include bp($point_0, min) {
      margin-bottom: 1.8rem;
      padding-bottom: 2.8rem;
    }

    @include bp($point_0 - 1) {
      margin-bottom: 2.2rem;
      padding-bottom: 2.4rem;
    }
  }
}

.dashboardScheduleHeading {
  @include bp($point_0, min) {
    margin-bottom: 2.6rem;
  }

  @include bp($point_0 - 1) {
    margin-bottom: 1.6rem;
  }

  .dashboardScheduleItem:first-child & {
    margin-bottom: 22px !important;

    @include bp($point_0, min) {
      margin-bottom: 3rem;
    }

    @include bp($point_0 - 1) {
      margin-bottom: 1.5rem;
    }
  }
}

.dashboardEventScheduleDate {
  color: $blue_3;
  font-size: 2rem;
}

.dashboardScheduledEventItem {
  margin: -1rem 0 1rem 0;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #eee;
}

.dashboardScheduleDate {
  font-size: 1.6rem;
  font-weight: 400;
  color: #1A1C1D;
  line-height: 1.3;
}

.dashboardScheduleDescr {
  font-size: 1.6rem;
  font-weight: 300;
  color: #7a7a7a;
}

.dashboardScheduleMeetings {}

.dashboardScheduleMeetingsItem {
  &:not(:last-child) {
    @include bp($point_0, min) {
      margin-bottom: 2.4rem;
    }

    @include bp($point_0 - 1) {
      margin-bottom: 2.6rem;
    }
  }
}

.dashboardScheduleEventItem {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 16px 15px;

  @include bp($point_4 - 1) {
    padding: 25px 15px 10px;
  }

  &:not(:last-child) {
    margin-bottom: 20px !important;

    @include bp($point_0, min) {
      margin-bottom: 2.4rem;
    }

    @include bp($point_0 - 1) {
      margin-bottom: 2.6rem;
    }
  }

  &:hover {
    cursor: pointer;
  }
}