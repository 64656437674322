@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_project_extends.scss';

.wrap {
  width: 100%;
}

.block {
  display: block;
  position: relative;
}

.input {
  @extend %signup_input;

  .blockIcon & {
    padding-right: 4rem;
  }
}

.label {
  position: absolute;
  // top: 50%;
  top: 1.65rem;
  left: 0.8rem;

  display: block;
  padding: 0 0.8rem;

  font-size: 1.3rem;
  font-weight: 500;
  color: $blue_3;
  font-family: $font_1;

  // transform: translateY(-50%);
  transform-origin: 0% 50%;

  transition: transform 0.3s, background-color 0.3s;

  pointer-events: none;

  .textareaMod & {
    top: 1rem;
  }

  .selectMod & {
    top: 0.6rem;
    left: 0rem;
  }

  .iconOffset & {
    left: 4.2rem;
  }

  .labelColorV2 & {
    color: #d1d1d1;
  }

  .labelColorV3 & {
    color: #1CC69D;
  }

  .labelSizeV2 & {
    top: 1.5rem;

    font-size: 1.5rem;
    font-weight: 400;
  }

  .blockActive & {
    background-color: $white;
    transform: translateY(-145%) scale(0.9);
  }

  .blockActive.textareaMod & {
    transform: translateY(-110%) scale(0.9);
  }

  .blockActive.selectMod & {
    transform: translateY(-95%) scale(0.9);
    color: $black;
  }

  .blockActive.iconOffset & {
    transform: translateY(-145%) scale(0.9) translateX(-2.6rem);
  }

  .blockActive.iconOffset.labelSizeV2 & {
    transform: translateY(-125%) scale(0.9) translateX(-4rem)
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 1.6rem;

  width: 2rem;
  height: 1.6rem;
  margin-top: -0.8rem;

  color: $blue_3;

  .iconOffset & {
    right: auto;
    left: 1.6rem;
  }

  img,
  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: visible;
  }
}

.children {
  position: relative;
}