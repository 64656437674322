@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.dashboardReport {
  position: relative;
  background-color: #f8f8f8;
  height: 100%;

  border: 1px solid #ddd;
  background-color: transparent !important;
  padding: 24px !important;
  border-radius: 8px !important;

  @include bp($point_0, min) {
    border-radius: 0.7rem;
    padding: 1.8rem 2rem 3.6rem 3.3rem;
  }

  @include bp($point_0 - 1) {
    padding: 1.6rem 2rem;
    height: 100%;
  }

  &.dashboardReportV2 {
    padding-top: 1.2rem;
  }

  @include bp($point_4 - 1) {
    background-color: $white;
    padding: 0;

    :global(.swiper) & {
      background-color: #f8f8f8;
      padding: 1.6rem 2rem;
    }
  }
}

.dashboardReportHide {
  position: absolute;
  right: 1.6rem;
  top: 1.4rem;

  display: none;
  width: 1rem;
  height: 1rem;
  color: #242424;

  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:focus {
    opacity: 0.8;
    text-decoration: none;
  }

  @include bp($point_0, min) {
    display: none;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.dashboardReportHeading {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  padding-right: 2rem;
}

.dashboardReportTitle {
  margin-top: -3px;

  font-size: 2rem;
  font-weight: 500;
  color: #1a1c1d;

  font-size: 18px;
  line-height: 24px;
}

.dashboardReportIndicator {
  flex-shrink: 0;

  width: 23px !important;
  height: 23px !important;
  margin-right: 10px;

  @include bp($point_0, min) {
    margin-right: 1.2rem;
    width: 4.4rem;
    height: 4.4rem;
  }

  @include bp($point_0 - 1) {
    margin-right: 1.6rem;
    width: 3.6rem;
    height: 3.6rem;
  }

  &.dashboardReportIndicatorV2 {
    @include bp($point_0, min) {
      width: 3.4rem;
      height: 3.8rem;
      // margin-left: 1.2rem;
    }

    @include bp($point_0 - 1) {
      width: 3rem;
      height: 3.2rem;
      // margin-left: 0.8rem;
    }
  }
}

.dashboardReportIndicatorImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashboardReportContent {
  font-size: 1.4rem;
  color: #707070;
  line-height: 1.55;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }

  a {
    display: inline;
    color: inherit;
    line-height: 1.1;
    border-bottom: 1px solid currentColor;

    &:hover,
    &:focus {
      color: darken(#707070, 20%);
      text-decoration: none;
    }
  }
}

.dashboardReportTimer {
  max-width: 26.8rem;
}

.dashboardReportTimerTitle {
  margin-bottom: 0.4rem;

  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.dashboardReportTimerBar {
  height: 0.7rem;
  border-radius: 0.3rem;
  background-color: #ddd;
}

.dashboardReportTimerBarProgress {
  height: 100%;
  width: 55%;

  border-radius: 0.3rem;
  background-color: #33c739;
}