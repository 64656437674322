.pro_img_wrap {
  @include bp($point_2, min) {
    width: 23.6rem;
    height: 24.4rem;
    margin: 0 auto 4rem;
  }

  @include bp($point_3) {
    width: 15rem;
    height: 15rem;
    margin: 0 auto 2rem;
  }
}

.pro_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.pro_lottie_wrap {
  margin-bottom: 4.4rem;

  &.margin_mod {
    margin-bottom: -2.4rem;
  }
}

.section_availability {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0;
}

#available_weekends {
  padding: 1rem 1rem 1rem 6.2rem;
}

#availability_earliest {
  font-family: $font_1;
  width: 49%;
  min-height: unset;
  height: 3.1em;
}

#availability_latest {
  font-family: $font_1;
  width: 49%;
  min-height: unset;
  height: 3.1em;
}

.time_options {
  max-height: 8em;
}
