.account_sidebar_nav {
  @include bp($point_3) {
    // display: none;
  }
}

.account_sidebar {
  width: 100%;
}

.account_sidebar_photo_block {
  @extend %global_flex_block_column_wrap_flex-start_center;

  width: 100%;
  min-width: 15rem;
  text-align: center;

  @include bp($point_2, min) {
    //margin-bottom: 5.8rem;
    margin: 2em 0 1.8em;
  }
}

.account_sidebar_photo_w {
  @include bp($point_2, min) {
    margin-bottom: 1.4rem;
  }

  @include bp($point_3) {
    margin: 0 0 0.4rem;
  }
}

.account_sidebar_name {
  margin-bottom: 4px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.6;
}

.account_sidebar_post {
  color: $lilac;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}
