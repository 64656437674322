.confirmed_popup {
  @extend %transition_opacity;

  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(popup);

  padding: var(--height-header) 0 5.3rem;
  width: 100%;
  height: 100vh;

  background: $white;
  opacity: 0;

  pointer-events: none;

  &.confirmed_popup--open {
    opacity: 1;

    pointer-events: auto;
  }
}

.confirmed_popup__top {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: fixed;
  top: 0;
  left: 0;

  padding: 0 1.6rem;
  width: 100%;
  height: var(--height-header);

  &.confirmed_popup__top--shadow {
    box-shadow: 0 1px 4px 0 rgba($black, 0.2);
  }
}

.confirmed_popup__scroll_area {
  @extend %global_flex_block_row_wrap_center_center;

  align-content: stretch;

  height: 100%;

  overflow-y: auto;
}

.confirmed_popup__content {
  @extend %global_flex_block_column_nowrap_flex-start_center;

  padding: 1.6rem;
  max-width: 32rem;
}

.confirmed_popup_image {
  margin-bottom: 2.2rem;
  width: 9.9rem;
  height: 9.9rem;

  border-radius: 50%;

  overflow: hidden;
}

.confirmed_popup_image__in {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.confirmed_popup_title {
  margin-bottom: 1.1rem;

  font-weight: 500;
  font-size: 3rem;
  line-height: 1.4;
  font-family: $font_1;
  text-align: center;
  color: $black_2;
}

.confirmed_popup_date {
  margin-bottom: 1.4rem;

  font-weight: 300;
  font-size: 3rem;
  line-height: 1.2;
  font-family: $font_1;
  text-align: center;
  color: $black_2;
}

.confirmed_popup_subtitle {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.4;
  font-family: $font_1;
  color: $black;
  text-align: center;
}

.confirmed_popup_button {
  @extend %global_flex_block_row_nowrap_center_center;
  @extend %transition_background;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: z-index(confirmed_popup);

  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.6;
  font-family: $font_1;
  color: $white;

  width: 100%;
  height: 5.3rem;

  background: $black;

  &:hover {
    background: rgba($black, 0.9);

    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}
