@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.root {
  background-color: #f8f8f8;

  @include bp($point_2, min) {
    padding: 2.6rem 3.6rem 3rem 3.2rem;
    border-radius: 0.7rem;
    // max-height: 16rem;
  }

  @include bp($point_3) {
    padding: 1.6rem 4rem 1.6rem 2rem;
    height: 100%;
  }
}

.item {
  &:not(:last-child) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
}
