.arrow_back {
  @extend %transition_opacity;

  display: block;
  width: 2.1rem;
  height: 2.7rem;

  color: #414141;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
