.requests_user_block {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  width: 100%;
  min-height: 45px;

  &.border_mod {
    border-bottom: 1px solid $gray;
    @include bp($point_2, min) {
      margin-bottom: 3.2rem;
      padding-bottom: 2.6rem;
    }

    @include bp($point_3) {
      margin-bottom: 1.8rem;
      padding-bottom: 1.6rem;
    }
  }

  &.offset_mod {
    @include bp($point_2, min) {
      margin: 2.8rem 0;
    }

    @include bp($point_3) {
      margin: 1.8rem 0;
    }
  }

  &.wrap_mod {
    flex-wrap: wrap;
  }
}

.requests_in {
  margin-bottom: 1.6rem;
  width: 100%;
}

.call_block {
  max-height: 50em;
  min-height: 8em;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 5%;
  position: relative;

  &.confirmed_mod {
    //max-height: 30vh;
    margin-bottom: 0%;
  }
  &.chat_mod {
    max-height: 75vh;
  }

  &.border_mod {
    border-bottom: 1px solid $gray;
    @include bp($point_2, min) {
      margin-bottom: 3.2rem;
      padding-bottom: 2.6rem;
    }

    @include bp($point_3) {
      margin-bottom: 1.8rem;
      padding-bottom: 1.6rem;
    }
  }

  &.extend_mod {
    height: calc(100% - 160px);
    max-height: unset;

    @include bp($point_1 - 1) {
      height: 100%;
    }
  }
}

.requests_user_info {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  margin-right: 2rem;

  max-width: 60%;

  @include bp($point_3) {
    flex-shrink: 0;
  }
}

.requests_user_info_col {
  @extend %global_flex_block_column_nowrap_flex-start;

  & + & {
    @include bp($point_2, min) {
      //margin-left: 1.6rem;
    }

    @include bp($point_3) {
      margin-left: 0.6rem;
    }
  }

  &.v1_mod {
    width: 7rem;
    min-width: 7rem;

    @include bp($point_3) {
      width: 6rem;
    }
  }

  &.v2_mod {
    max-width: 100%;
    overflow-x: hidden;
  }
}

.requests_user_info_item {
  & + & {
    @include bp($point_2, min) {
      margin-top: 0.6rem;
    }
    @include bp($point_3) {
      margin-top: 0.2rem;
    }
  }

  &.v1_mod {
    font-size: 1.4rem;
    font-weight: 700;
  }

  &.v2_mod {
    font-size: 1.4rem;
    color: $lilac;
  }

  &.v3_mod {
    @extend %global_flex_block_row_nowrap_flex-start;
    font-size: 1.4rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }

  // &:nth-child(1) {
  // 	width: 7rem;
  // 	margin-right: 1rem;
  // }

  // &:nth-child(2) {
  // 	width: calc(100% - 7rem);
  // }

  // .requests_user_info_row.top_mod & {
  // 	&:nth-child(1) {
  // 		font-size: 1.4rem;
  // 		font-weight: 700;
  // 	}

  // 	&:nth-child(2) {
  // 		@extend %global_flex_block_row_wrap_flex-start;

  // 		font-size: 1.4rem;
  // 		font-weight: 400;
  // 	}
  // }

  // .requests_user_info_row.bottom_mod & {
  // 	&:nth-child(1) {
  // 		span {
  // 			margin-right: .6rem;
  // 		}
  // 	}

  // 	font-size: 1.4rem;
  // 	color: $lilac;
  // }
}

.requests_user_right {
  @extend %global_flex_block_row_wrap_flex-end_center;

  margin: 0 -0.1rem -0.6rem auto;
  min-width: max-content;
}

.requests_user_btn_w {
  padding: 0 0.8rem 0.6rem 0;
  z-index: 1000 !important;
}

.requests_user_btn {
  @extend %global_flex_block_row_wrap_center_center;

  height: 2.8rem;

  color: $lilac;

  border-radius: 4px;
  background-color: #ffffff;
  border: 1px grey solid;
  cursor: pointer;

  @include bp($point_3) {
    height: 1.8rem;
  }

  &:last-child {
    margin-right: 0;
  }

  &.icon_mod {
    width: 2.8rem;

    @include bp($point_3) {
      width: 1.8rem;
    }
  }

  &.text_mod {
    min-width: 6.5rem;
    padding: 0 10px;
    z-index: 1000 !important;
    margin-right: 0.5em;

    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.8rem;

    @include bp($point_3) {
      font-size: 1rem;
      min-width: 4rem;
    }
  }

  .icon {
    width: 1rem;
    height: 1rem;

    @include bp($point_3) {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  &.confirmed {
    background-color: green;
  }

  &.canceled {
    background-color: red;
  }
}

.requests_user_icon_block {
  margin-right: 1.6rem;

  @include bp($point_3) {
    margin-right: 0.6rem;
  }
}

.requests_user_icon_w {
  @extend %global_flex_block_row_wrap_center_center;

  width: 3.8rem;
  height: 3.8rem;

  border-radius: 1rem;
  border: 1px solid $gray;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.requests_list_head {
  @include bp($point_2, min) {
    margin-bottom: 1.6rem;
  }

  @include bp($point_3) {
    margin-bottom: 1.8rem;
  }
}

.requests_empty_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0 3.8rem;
  font-size: 1.7rem;
  color: $gray_7;

  &.margin_mod {
    @include bp($point_5) {
      margin: 1.3rem 0 3.8rem;
    }
  }
}

.user_status_w {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  // position: absolute;
  // top: .9rem;
  // right: 4px;
  margin: 0.2rem 0 0 0.5rem;

  &.v2_mod {
    margin: 0.8rem 0 0 0.5rem;
  }
}

.user_status_w {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  margin: 0.2rem 0 0 0.5rem;

  &.v2_mod {
    margin: 0.8rem 0 0 0.5rem;
  }
}

.user_status_item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 2px;

  border-radius: 50%;
  background-color: $gray_3;

  &:last-child {
    margin-right: 0;
  }

  &.active_mod {
    background-color: #b620e0;

    .user_status_w.v2_mod & {
      background-color: $pink;
    }
  }

  .user_status_w.v2_mod & {
    width: 0.6rem;
    height: 0.6rem;
  }
}

.section_schedule_title {
  width: 100%;
  margin: 0 0 0.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: $black_2;
}

.section_schedule_text {
  font-size: 1.4rem;
  font-weight: 500;

  a {
    color: #d71ded;
    text-decoration: none;
  }
}

.confirmed_image_w {
  width: 100%;
  height: 26em;
  position: relative;

  @include bp($point_5) {
    height: 20em;
  }
}

.confirmed_image_check {
  width: 13%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.confirmed_image_bg {
  width: 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
