// buttons

.btn_base {
  @extend %green_btn_1;
  @extend %btn_size_1;
  font-family: $font_1;

  &.width_mod {
    width: 17.2rem;

    @include bp($point_3) {
      max-width: 100%;
    }
  }

  &.offset_mod {
    margin-top: 2rem;
  }

  &.login_mod {
    margin: 5% 0;
  }

  &.request_mod {
    margin: 5% 0;
  }

  &.position_relative_mod {
    position: relative;
  }
}

.btn_base_green {
  @extend %green_btn_1;
  @extend %btn_size_1;
  font-family: $font_1;

  &.width_mod {
    width: 17.2rem;

    @include bp($point_3) {
      max-width: 100%;
    }
  }

  &.offset_mod {
    margin-top: 2rem;
  }

  &.login_mod {
    margin: 5% 0;
  }

  &.weight_mod {
    font-weight: 700;
  }

  &.request_mod {
    margin: 5% 0;
  }

  &.position_relative_mod {
    position: relative;
  }
}

.subscription_info {
  @include bp($point_0, min) {
    padding: 1.4rem;
    margin: 1.4rem;
  }

  @include bp($point_0) {
    margin-bottom: 2rem;
  }

  &.title {
    font-weight: 600;
  }
}

.subscription_info_row {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subscription_info_in {
  background-color: #007eff;
  border-radius: 0.7rem;

  @include bp($point_0, min) {
    min-height: 8.4rem;

    font-size: 2rem;
  }

  @include bp($point_0 - 1) {
    min-height: 7.4rem;

    font-size: 1.6rem;
  }
}

.subscription_info_content {
  color: $white;
  font-weight: 500;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &.in {
    font-weight: 300;
    margin-left: 5rem;
    margin-right: 7rem;
  }

  @include bp($point_0, min) {
    font-size: 2rem;
  }

  @include bp($point_0 - 1) {
    font-size: 1.6rem;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.btn_v2 {
  @extend %liliac_btn_1;
  @extend %btn_size_2;
  //font-family: $font_1;

  &.width_mod {
    width: 12.2rem;

    @include bp($point_3) {
      max-width: 100%;
    }
  }

  &.font_mod {
    font-size: 1.5em;
  }
}

.btn_v3 {
  @extend %white_btn_1;
  @extend %btn_size_1;
  //font-family: $font_1;

  &.width_mod {
    width: 17.2rem;

    @include bp($point_3) {
      max-width: 100%;
    }
  }

  &.pos_relative {
    position: relative;
  }

  &.user_join_mod {
    justify-content: flex-start;
    text-align: left;
    color: $lilac;

    padding-left: 1.8rem;

    &.user_join_padding_mod {
      padding-left: 1.9rem;
    }

    &:hover,
    &:focus {
      color: $black_2;
    }
  }

  &.welcome_mod {
    border: 2px solid $green_1;
    margin-left: 5%;
  }

  &.signup_mod {
    height: 4.4rem;
    border: 2px solid $green_1;
    padding: 0em 0em 0em 0em;
    margin: 0em 0em 0em 0em;
    width: 8.2em;
  }

  &.join_page_mod {
    justify-content: flex-start;
    text-align: left;
    color: $lilac;
    max-width: none;
    width: 100%;

    padding-left: 1.8rem;

    &.user_join_padding_mod {
      padding-left: 1.9rem;
    }

    &:hover,
    &:focus {
      color: $black_2;
    }
  }
}

.btn_v4 {
  @extend %gray_btn_1;
  @extend %btn_size_3;
  font-family: $font_1;
  color: #ffffff;
}

.btn_v5 {
  @extend %white_btn_1;
  @extend %btn_size_4;
  font-family: $font_1;

  &.margin_mod {
    margin-right: 1em;
  }
}

.btn_v6 {
  @extend %green_btn_1;
  @extend %btn_size_5;
  font-family: $font_1;
  font-size: 1.8rem;
  border-radius: 40px;
}

.btn_v7 {
  @extend %green_btn_1;
  @extend %btn_size_4;
  font-family: $font_1;
}

.btn_v8 {
  @extend %green_btn_2;
  @extend %btn_size_4;
  font-family: $font_1;

  &.margin_mod {
    margin-right: 1em;
  }

  &.header_mod {
    color: black;
    padding: 0em 0em 0em 0em;

    &:hover {
      background-color: #ffffff;
    }
  }

  &.help_mod {
    margin-right: -1.9em;
  }
}

.btn_v7_upper {
  @extend %green_btn_2;
  @extend %btn_size_6;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  font-family: $font_1;
  border: 1px solid $green_1;
  border-bottom: none;

  &.call_mod {
    color: white;
    background-color: #339f78;
    border: 1px solid white;
    transition: 0.3s ease;
    animation: rise-up 0.5s ease-in forwards;
    @include st_btn();

    &:hover {
      background: darken(#339f78, 10%);
      color: white;
    }

    &.alert {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
      background-color: red;
      padding: 0.2em;
      margin: 0 0.4em;
      max-width: intrinsic;
      font-size: 1.6em;
      display: flex;
    }
  }

  &.remove {
    display: none;
  }
}

.btn_v7_lower {
  @extend %green_btn_2;
  @extend %btn_size_6;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  font-family: $font_1;
  position: absolute;
  top: 96%;
  border: 1px solid $green_1;
  border-top: none;
}

.btn_v9 {
  @extend %blue_btn_5;
  @extend %btn_size_8;
}

.btn_v10 {
  @extend %gray_btn_2;
  @extend %btn_size_9;
}

.btn_v11 {
  @extend %green_btn_3;
  @extend %btn_size_10;
}

.btn_wrap {
  &.center_mod {
    @extend %global_flex_block_row_nowrap_center;
  }

  &.offset_mod {
    margin: 2.8rem auto 0;
  }

  &.offset_2_mod {
    margin: 1.6rem auto 0;
  }

  &.offset_3_mod {
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.width_mod {
    margin: 0 -2rem;
    width: 100%;
  }

  &.offset_4_mod {
    margin: 2.4rem auto 0;
  }

  &.offset_5_mod {
    margin: 2.6rem auto 0;
  }

  &.full_mod {
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  }

  &.offset_4_mod {
    margin: 3rem 0 1rem;
  }
}

.btn_img_wrap {
  display: block;
  width: 9.4rem;
  height: 2.6rem;
}

.btn_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

//images
.inner_img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  z-index: -10;
}

.cover_img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

//---------------------------------------------page_popup
.page_popup {
  position: fixed;
  top: var(--height-header);
  left: 0;

  height: calc(var(--full-screen) - var(--height-header));
  width: 100%;

  opacity: 0;
  transition: opacity 0.3s ease;

  &.active_state {
    opacity: 1;
    pointer-events: auto;
  }
}

.page_popup_close {
  position: absolute;
  top: 2.6rem;
  right: calc((100vw - #{$page_width}) / 2);
  z-index: 10;

  width: 2rem;
  height: 2rem;

  color: #cdcdcd;

  cursor: pointer;
  transition: color 0.3s ease;

  &:before {
    position: absolute;
    top: -1.4rem;
    left: -1.4rem;
    bottom: -1.4rem;
    right: -1.4rem;

    content: '';
  }

  &:hover,
  &:focus {
    color: $lilac;
  }
}

//---------------------------------------------page_popup###

//---------------------------------------------pro join page
.pro_join_block {
  margin: auto 0;
  max-width: 42rem;
}

.pro_join_block_form_in {
  margin-bottom: 3.8rem;
}

.pro_join_block_form_w_input {
  //margin-bottom: 3.2rem;
}

//---------------------------------------------pro join page###

//user image block
.user_img_w {
  position: relative;

  overflow: hidden;

  &.v1_mod {
    width: 4rem;
    height: 4rem;

    border-radius: 0.8rem;
  }

  &.v2_mod {
    width: 11.4rem;
    height: 11.4rem;
    border-radius: 2rem;
  }

  &.category_icon {
    width: 8em;
    height: 5em;
  }

  &.role_pro {
    height: 24.4rem;
    width: 24.4rem;
    border-radius: 2rem;
  }

  &.role_user {
    height: 24.4rem;
    width: 22rem;
    border-radius: 2rem;
  }

  &.v3_mod {
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 1rem;
  }

  &.v4_mod {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 1rem;
  }

  &.offset_mod {
    margin-left: auto;
  }

  &.v5_mod {
    width: 8.4rem;
    height: 8.4rem;
    border-radius: 1.2rem;
  }

  &.v6_mod {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 0.8rem;
  }

  &.v7_mod {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 0.8rem;
  }

  &.min_size_mod {
    min-width: 38px;
  }

  &.margin_right_mod {
    margin-right: 1.5em;
  }
}

.time_picker {
  height: 70vh;
  overflow-y: scroll;
}

.google_img {
  height: 5em;
  width: 12em;
}

.user_img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

// analytics

.analytics {
  @extend %global_flex_block_row_nowrap_space_between;
}

.analytics_item {
  &+& {
    @include bp(1366px, min) {
      margin-left: 2.6rem;
    }

    @include bp(1366px) {
      margin-left: 1rem;
    }
  }
}

.analytics_values {
  @extend %global_flex_block_row_nowrap_center;
  align-items: flex-end;
  height: 12rem;
  margin: 0 0 2rem;
}

.analytics_value {
  width: 3px;
  height: 100%;
  border-radius: 0.4rem;

  &+& {
    margin-left: 0.4rem;
  }

  &.col_1_mod {
    background-color: $lilac_2;

    .analytics_values.v1_mod & {
      height: 25%;
    }

    .analytics_values.v2_mod & {
      height: 15%;
    }

    .analytics_values.v3_mod & {
      height: 35%;
    }

    .analytics_values.v4_mod & {
      height: 50%;
    }

    .analytics_values.v5_mod & {
      height: 100%;
    }

    .analytics_values.v6_mod & {
      height: 25%;
    }

    .analytics_values.v7_mod & {
      height: 90%;
    }

    .analytics_values.v8_mod & {
      height: 75%;
    }
  }

  &.col_2_mod {
    background-color: $gray;

    .analytics_values.v1_mod & {
      height: 15%;
    }

    .analytics_values.v2_mod & {
      height: 45%;
    }

    .analytics_values.v3_mod & {
      height: 65%;
    }

    .analytics_values.v4_mod & {
      height: 100%;
    }

    .analytics_values.v5_mod & {
      height: 55%;
    }

    .analytics_values.v6_mod & {
      height: 95%;
    }

    .analytics_values.v7_mod & {
      height: 10%;
    }

    .analytics_values.v8_mod & {
      height: 55%;
    }
  }
}

//---------------user_join

.user_join_btn_icon_w {
  position: relative;

  margin-right: 3rem;
  width: 4.5rem;
  height: 1.6rem;

  &::after {
    @extend %vert_centr;

    position: absolute;
    right: -1.4rem;

    content: '';

    width: 1px;
    height: 4.8rem;

    background: #eeeeee;
  }

  &.size_mod {
    height: auto;
    width: 1.6rem;

    &::after {
      right: -1.8rem;
    }
  }
}

// social

.social_list {
  @extend %global_flex_block_row_nowrap_flex-start;
}

.social_item {
  &+& {
    margin: 0 0 0 1.4rem;
  }
}

.social_link {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 3rem;
  height: 3rem;

  color: $white;

  border-radius: 50%;
  background-color: #bfbfbf;
}

@keyframes rise-up {
  from {
    height: 0%;
  }

  to {
    height: 5%;
  }
}