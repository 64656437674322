html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

a {
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: none;
  }
}

:focus {
  outline: 0;
}

input[type='text'],
textarea {
  -webkit-appearance: none;

  &::-ms-clear {
    display: none;
  }
}

input,
textarea {
  border-radius: 0;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: $input_placeholder_color;
  }

  &::-moz-placeholder {
    color: $input_placeholder_color;
  }
  &:-ms-input-placeholder {
    color: $input_placeholder_color;
  }

  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}

textarea {
  resize: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
}
//-------------------------------------------------reset*/
