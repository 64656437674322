@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_project_extends.scss';

// dropdown

.dropdown {
  // position: absolute;
  // top: calc(100% - 1px);
  // left: 0;
  position: relative;

  margin: -1px -1px 0;

  height: auto;
  padding: 1.6rem 1.6rem 2.2rem;

  background-color: $white;
  border: 1px solid $gray_4;
  border-top: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.6rem;
    right: 1.6rem;

    height: 1px;
    background-color: $gray_4;
  }
}

.dropdownHover {
  z-index: 100;
  position: relative;

  margin: -1px -1px 0;

  height: auto;
  padding: 1.6rem 1.6rem 2.2rem;

  background-color: $white;
  border: 1px solid $gray_4;
  border-top: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.6rem;
    right: 1.6rem;

    height: 1px;
    background-color: $gray_4;
  }
}

.dropdownItemOverlay {
  z-index: 1;
  position: relative;

  margin: -1px -1px 0;

  height: auto;
  padding: 1.6rem 1.6rem 2.2rem;

  background-color: $white;
  border: 1px solid $gray_4;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.6rem;
    right: 1.6rem;

    height: 1px;
    background-color: $gray_4;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.dropdownItemTrigger {
  display: flex;
  align-items: center;
}

.dropdownItemAvatar {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.6rem;
  height: 2.6rem;
  margin-right: 0.7rem;

  font-size: 1.5rem;
  color: $white;
  text-transform: uppercase;

  border-radius: 50%;
  background-color: #808080;

  transition: background-color;

  .dropdownItemTrigger:hover & {
    background-color: $blue_3;
  }
}

.dropdownItemName {
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 300;
  color: #6a6a6a;

  transition: color 0.3s;

  .dropdownItemTrigger:hover & {
    color: $blue_3;
  }
}

.dropdownItemEmail {
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 300;
  color: #aaaaaa;

  transition: color 0.3s;

  .dropdownItemTrigger:hover & {
    color: $blue_3;
  }
}

.dropdownItemClose {
  width: 0.8rem;
  height: 0.8rem;
  margin: -1rem 0 0 auto;

  color: #909090;
  transition: color 0.3s;

  cursor: pointer;

  &:hover,
  &:focus {
    color: $black;
  }
}
