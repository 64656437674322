.profile {
  display: flex;
  min-height: 100%;
}

.profile__cols {
  @extend %global_flex_block_row_wrap_flex-start_stretch;

  @include bp($point_2, min) {
    .book_page & {
      overflow: hidden;
      height: 100%;
    }
  }
}

.profile__col {
  @include bp($point_2, min) {
    min-height: calc(100vh - var(--height-header));

    .book_page & {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
    }

    &.profile__col--sidebar {
      width: 28.2%;
    }

    &.profile__col--time,
    &.profile__col--calendar {
      position: relative;
      width: 39.4%;

      &:before {
        content: '';
        position: absolute;

        background-color: $gray;

        top: 1rem;
        left: 0;

        width: 1px;
        height: calc(100% - 1rem);
      }

      .signup__col_in {
        margin-top: 6rem;
      }
    }

    &.profile__col--time {
      padding: 2rem 4.2rem;
    }

    &.profile__col--tabs {
      position: fixed;
      right: 0;

      margin-top: calc(var(--height-header) * -1);
      min-height: 100vh;
      height: 100vh;
      width: 32.4%;
      padding: 7.8rem 5.4rem;

      overflow-y: auto;
      background-color: $black_4;
    }

    &.profile__col--timepicker {
      @include bp($point_2, min) {
        display: none;
      }
    }
  }

  @include bp($point_3) {
    width: 100%;

    &.profile__col--time,
    &.profile__col--calendar {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 1.6rem;

        width: calc(100% - 3.2rem);
        height: 1px;
        background-color: $gray;
      }
    }

    &.profile__col--time,
    &.profile__col--timepicker {
      padding: 2rem 2.6rem;
    }

    &.profile__col--tabs {
      padding: 1.8rem 2.6rem;
      background-color: $black_4;
    }
  }
}

.profile_tabs {
  position: relative;

  min-height: 100%;

  @include bp($point_2, min) {
    padding: 3rem 2.2rem;
  }

  @include bp($point_3) {
    padding-top: 2rem;
  }
}

.profile_tabs__head {
  @extend %global_flex_block_row_wrap_flex-start;

  @include bp($point_2, min) {
    margin-bottom: 3.2rem;
  }

  @include bp($point_3) {
    margin-bottom: 1.2rem;
  }
}

.profile_tabs__item {
  @include bp($point_2, min) {
    margin-right: 3rem;
  }

  @include bp($point_3) {
    // flex-grow: 1;
    margin-right: 5.2rem;
  }
}

.profile_tabs__link {
  display: block;
  font-size: 1.5rem;
  color: $blue_3;
  font-weight: 500;

  transition: color 0.3s;

  @include bp($point_2, min) {
    // opacity: 0.23;
  }

  @include bp($point_3) {
    // background-color: $black_4;
    // opacity: 0.43;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $black;
  }

  &.profile_tabs__link--active {
    color: #b1b1b1;
    @include bp($point_2, min) {
      opacity: 1;
    }

    @include bp($point_3) {
      // background-color: $black_4;
      opacity: 1;
    }
  }

  @include bp($point_3) {
    width: 100%;
    // padding: 1.6rem 1rem;

    font-size: 1.8rem;
    text-align: center;
    color: $white;
  }
}

.profile_tabs__content {
  // @include bp($point_3) {
  // 	background-color: $black_4;
  // }
}

.profile_tabs__content_item {
  display: none;

  &.profile_tabs__content_item--active {
    display: block;
  }

  @include bp($point_3) {
    // padding: 2.2rem 0;
  }
}

// media

.profile_media {
  @include bp($point_2, min) {
    max-width: 38.2rem;
  }
}

.profile_media__list {
  // @extend %global_flex_block_row_wrap_flex-start;
  // margin: 0 -2rem -2.2rem 0;
}

.profile_media__iframe_item {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-bottom: 52%;
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba($white, 0.23);
    margin-bottom: 0.8rem;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;
  }
}

.profile_media__item {
  display: block;
  // width: 50%;
  width: 100%;
  // padding: 0 2rem 2.2rem 0;
  text-align: left;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.profile_media__img {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-bottom: 56%;
  }
}

.profile_media__img_in {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.6s;

  .profile_media__item:hover & {
    transform: scale(1.15);
  }
}

.profile_media__title {
  padding-left: 0.4rem;

  font-size: 1.5rem;
  line-height: 1.3;
  color: $blue_3;
  font-weight: 500;
}

.profile_media__duration {
  display: none;

  font-size: 1.4rem;
  color: $gray_9;
}

// events

.profile_events {
  // padding: 0 2rem;
}

.profile_events__item {
  position: relative;

  padding: 1.4rem 4rem 1.4rem 1.6rem;

  color: #151515;

  border: 1px solid #ddd;

  transition: border-color 0.3s;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: $black;
  }

  &:not(:last-child) {
    margin-bottom: 2.8rem;
  }
}

.profile_events__info_icon {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;

  width: 1.2rem;
  height: 1.2rem;

  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.profile_events__content {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: 1px;
    background-color: $white;
    opacity: 0.23;
  }

  @include bp($point_2, min) {
    padding-bottom: 2.2rem;
    margin-bottom: 2.8rem;
  }

  @include bp($point_3) {
    padding-bottom: 2.2rem;
    margin-bottom: 2.2rem;
  }

  .profile_events__item:last-child & {
    padding: 0;
    margin: 0;
    &:after {
      display: none;
    }
  }
}

.profile_events__date {
  margin-bottom: 0.6rem;

  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 500;
}

.profile_events__title {
  margin-bottom: 0.4rem;

  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 500;
}

.profile_events__info {
  .profile_events__hover & {
    border-bottom: 1px solid rgba($white, 0.23);

    @include bp($point_2, min) {
      padding-bottom: 2.6rem;
    }

    @include bp($point_3) {
      padding-bottom: 1.6rem;
    }
  }

  &:not(:last-child) {
    @include bp($point_2, min) {
      margin-bottom: 2.4rem;
    }

    @include bp($point_3) {
      margin-bottom: 1.2rem;
    }
  }
}

.profile_events__info_list {
  @extend %global_flex_block_row_wrap_flex-start;

  margin: 0 -0.8rem -0.8rem 0;
}

.profile_events__info_item {
  padding: 0 0.8rem 0.8rem 0;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 500;

  color: #00915f;
}

.profile_events__buttons {
  &.profile_events__buttons--offset {
    margin-bottom: 2rem;
  }
}

.profile_events__buttons_list {
  @extend %global_flex_block_row_wrap_flex-start;
  margin: 0 0 -1.6rem 0;
}

.profile_events__button_item {
  padding: 0 1.8rem 1.6rem 0;
}

.profile_events__button_link {
  @extend %btn_size_7;
  @extend %blue_btn_3;

  .profile_events__button_item:last-child & {
    background: #fff;
    color: #2b2b2b;
    border-color: #fff;
  }

  .profile_events__hover &:not(:hover) {
    background: $white;
    color: #2b2b2b;
    border-color: $white;
  }
}

.profile_events__button_icon {
  width: 1rem;
  height: 1.2rem;
  margin-right: 0.4rem;
}

.profile_events__hover {
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 2px);
  height: 100%;

  padding: 2.6rem 2.6rem 2.6rem 6.6rem;

  background-color: #2b2b2b;
  overflow-y: auto;

  z-index: 3;

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s;

  .profile_events__item--popup_open & {
    opacity: 1;
    pointer-events: initial;
  }
}

.profile_events__text {
  margin-bottom: 3rem;
  font-size: 1.6rem;
  font-weight: 500;

  @include bp($point_2, min) {
    max-width: 24.4rem;
  }

  &:last-child {
    margin: 0;
  }

  p {
    margin-bottom: 3rem;

    &:last-child {
      margin: 0;
    }
  }
}

.profile_events__hover_back {
  position: absolute;
  top: 2.6rem;
  left: 3rem;

  width: 1.6rem;
  height: 2rem;

  color: $white;
  transition: opacity 0.3s;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

// .footer {
// 	display: none;
// }

// calendar

.profile_calendar {
  position: relative;
  display: flex;
  flex-direction: column;

  @include bp($point_2, min) {
    padding: 3.4rem 2.4rem 2.4rem 1.4rem;
    height: 100%;
  }

  @include bp($point_3) {
    padding: 2.6rem 2.2rem;
  }
}

.profile_calendar__block {
  margin-bottom: 1rem;
}

.profile_calendar__select {
  margin-top: auto;

  @include bp($point_2, min) {
    padding: 0 1.4rem 0 2.4rem;
  }

  @include bp($point_3) {
    max-width: 33.2rem;
  }
}

.profile_calendar__title {
  display: none;

  font-size: 2.2rem;
  color: $black_2;

  @include bp($point_2, min) {
    margin-bottom: 0.4rem;
  }

  @include bp($point_3) {
    margin-bottom: 0.8rem;
  }
}

.profile_calendar__info {
  @include bp($point_2, min) {
    padding: 2.4rem 2.8rem;
    // border: 1px solid $gray;
    background-color: #efefef;
  }
}

.profile_calendar__info_title {
  margin-bottom: 1rem;

  font-size: 1.8rem;
  font-weight: 500;
}

.profile_calendar__info_item {
  position: relative;

  padding-left: 2rem;
  margin-bottom: 0.8rem;

  font-size: 1.6rem;
  color: #2b2b2b;
  font-weight: 300;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;

    width: 0.6rem;
    height: 0.6rem;
    margin-top: -0.3rem;

    border-radius: 50%;
    background-color: currentColor;
  }

  &:last-child {
    margin: 0;
  }
}

// profile_bottom

.profile_bottom {
  @extend %global_flex_block_row_nowrap_center_center;

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  min-height: 5.2rem;
  padding: 1rem 2.6rem;

  background-color: $blue_3;
}

.profile_bottom__title {
  font-size: 1.6rem;
  font-weight: 500;
  color: $white;
  text-align: center;
}
