@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------WhyNeedAccount
.component {
  @include flex_block(row, nowrap, flex-start, center);
}

.col {
  &:not(:last-child) {
    margin-right: 1.6rem;
  }

  &:last-child {
    max-width: 38rem;
  }
}

.imageWrap {
  @include bp($point_2, min) {
    width: 5rem;
    height: 5rem;
  }

  @include bp($point_3) {
    width: 7rem;
    height: 6.8rem;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.title {
  margin-bottom: 0.4rem;

  font-size: 1.3rem;
  font-weight: 700;
  color: $black_4;
  line-height: 1.5;
}

.descr {
  font-size: 1.3rem;
  font-weight: 400;
  color: $black_4;
  line-height: 1.4;
}
//---------------------------------------------WhyNeedAccount###
