@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.root {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px !important;
  grid-gap: 20px !important;
  width: 100%;
  margin-right: 0 !important;

  @include bp($point_2, min) {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  @include bp($point_3) {
    display: flex;
    flex-direction: column-reverse;
  }

  @include bp($point_4 - 1) {
    display: block !important;
  }

  &.rootGridMod {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    flex-wrap: nowrap !important;

    @include bp($point_3) {
      margin-top: 2.6rem;
    }

    @include bp($point_4, min) {
      gap: 3rem;
    }
  }
}

.item {
  &:not(:last-child) {
    @include bp($point_2, min) {
      margin-bottom: 2.8rem;

      margin-bottom: 0 !important;
    }
  }

  .rootGridMod & {
    margin: 0;

    @include bp($point_4, min) {
      width: calc(50% - 1.5rem);
      width: auto !important;
    }

    @include bp($point_4 - 1) {
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
        padding-bottom: 2.8rem;
        margin-bottom: 2.2rem;

        border-bottom: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 30px !important;
      }
    }
  }
}

.slider {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  z-index: 50;

  :global(.swiper-slide) {
    height: auto;
    background: $white;
  }
}

.sliderClose {
  position: absolute;
  top: 0.9rem;
  right: 1.2rem;

  width: 1.8rem;
  height: 1.8rem;

  z-index: 3;
}