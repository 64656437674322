.profile_form {
  width: 100%;
}

.profile_form_in {
  @extend %global_flex_block_row_wrap_flex-start;
  width: 100%;

  padding-bottom: 4rem;

  @include bp($point_2, min) {
    margin: 0 -7.8rem -2rem 0;
  }

  @include bp($point_3) {
    margin: 0 0 -1.6rem;
    padding-bottom: 2rem;
  }

  @include bp($point_5) {
    padding-bottom: 4rem;
  }
}

.profile_form_btn_block {
  @extend %global_flex_block_row_nowrap_flex-start;
  width: 19em;
  margin-top: 2rem;

  @include bp($point_2, min) {
    margin-right: -5rem;
  }

  @include bp($point_4) {
    margin-top: 0rem;
  }

  @include bp($point_4 - 1) {
    width: 100%;
  }

  // @include bp($point_3) {
  //   margin-right: -1.4rem;
  //   width: 19em;
  // }
}

.profile_form_btn_w {
  @include bp($point_2, min) {
    padding-right: 5rem;
    padding-left: 1em;
  }

  @include bp($point_3) {
    width: 100%;
    padding-right: 1.4rem;
    text-align: center;
  }
}

.profile_form_icon_w {
  position: absolute;
  right: 0;
  bottom: 2.4rem;

  content: '';

  width: 1.8rem;
  height: 2rem;

  &.v1_mod {
    color: $lilac;
  }

  &.v2_mod {
    color: #1d1d24;
  }

  &.v3_mod {
    color: #28c345;
  }

  &.pos_mod {
    bottom: 1.4rem;
  }
  &.login_mod {
    width: fit-content;
  }
}

.event_form_sec {
  display: flex;
}

.event_form_input_s {
  @include bp($point_2, min) {
    padding: 0.3em 1.6em;
    width: 50%;
  }

  @include bp($point_3) {
    width: 100%;
    padding: 0 0 1.6rem;
  }

  &.min_height_mod {
    min-height: 15.4em;
  }
}

.event_form_input_w {
  @include bp($point_2, min) {
    padding: 0.3em 1.6em;
    width: 100%;
  }

  @include bp($point_3) {
    width: 100%;
    padding: 0 0 1.6rem;
  }

  &.min_height_mod {
    min-height: 15.4em;
  }
}

.profile_form_input_w {
  @include bp($point_2, min) {
    padding: 0 4rem 2rem 1rem;
    width: 50%;
  }

  @include bp($point_3) {
    width: 100%;
    padding: 0 0 1.6rem;
  }

  &.min_height_mod {
    min-height: 15.4em;
  }
}

.profile_hamburger_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.profile_hamburger {
  //display: inline-block;
  color: black;
  //font-style: normal;
  font-size: 4em;
  width: fit-content;
  height: fit-content;
  //margin: 1em 1.5em 0 0;
  text-decoration: none !important;
  margin: -0.2em 0.27em -0.2em 0;
}
