// time_select_section
.time_select_section {
  @extend %global_flex_block_row_nowrap_center;

  height: calc(100vh - var(--height-header));
}

.time_select_section__in {
  padding: 5rem var(--gl-block-indent) 0;
  // max-width: 37rem;
  width: 100%;
  height: 100%;

  overflow-y: auto;
}

// time_select_section_back
.time_select_section_back {
  @extend %global_flex_block_row_nowrap_flex-start_center;
  @extend %transition_color;

  position: fixed;
  top: calc(var(--height-header) + 5rem);
  left: 10%;

  color: $blue;

  cursor: pointer;

  @include bp($point_3) {
    display: none;
  }

  &:hover {
    color: rgba($blue, 0.8);
  }
}

.time_select_section_back__icon {
  margin-right: 1.1rem;
  width: 2.2rem;
  height: 2.7rem;
}

.time_select_section_back__text {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.13;
  font-family: $font_1;
}
