@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.meetingsAndCalls {
  @include bp($point_2, min) {
    display: flex;
    padding-top: 1.2rem;
  }
  @include bp($point_3) {
    padding: 0 1.2rem;
  }
}

.meetingsAndCallsCol {
  @include bp($point_2, min) {
    width: 100%;
    overflow-y: scroll;

    &:last-child {
      padding-top: 0.6rem;
    }

    &:not(:last-child) {
      padding-right: clamp(2rem, 8%, 8%);
    }
  }
  @include bp($point_3) {
    width: 100%;
    padding: 2rem 1.2rem 0;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &:last-child {
      width: calc(100% + (24px + 1.2rem) * 2);
      margin: 0 calc((24px + 1.2rem) * -1);
      padding: 0 24px;
    }
  }
}
