body.logged-in, .DashboardHeader_header__2iy7w, .DashboardHeader_header__Owgub {
	background: #202D3D !important;
    color: #fff !important;
}
body.logged-in * {
    scrollbar-color: #414E61 transparent !important;
}
/*.TalkerPage_dashboard__2IILq{
    background: #202D3D !important;
    color: #fff !important;
}
.TalkerPage_col__2K3cd{   
    scrollbar-color: #414E61 transparent !important;
}*/
.DashboardMobileMenu_DashboardMobileMenu__1W3p9, 
.DashboardMobileMenu_DashboardMobileMenu__2jxtA{
	background-color: #202D3D !important;
	color: #fff;
	border-bottom: 1px solid #394D65;
    margin-bottom: 15px !important;
    padding-top: 15px !important;
} 
.Menu_menuButton__BUP6a, .Menu_menuButton__1z_9j{
	border: 1px solid #22C19B;
	color: #22C19B;
	border-radius: 4px;
}
.Menu_menuButton__BUP6a:focus, .Menu_menuButton__BUP6a:hover,
.Menu_menuButton__1z_9j:focus, .Menu_menuButton__1z_9j:hover{
	background-color: #22C19B;
}
.Menu_menuButton__BUP6a span, .Menu_menuButton__1z_9j span{
	margin-bottom: 5px;
}
.Menu_menuLink__8Z2Oc, .Menu_menuLink__3BNrA {
	color: #fff;
}
.Menu_menuLink__8Z2Oc.Menu_menuLinkActive__1z8Dk, .Menu_menuLink__8Z2Oc:focus, 
.Menu_menuLink__8Z2Oc:hover,
.Menu_menuLink__3BNrA.Menu_menuLinkActive__3RO-t, .Menu_menuLink__3BNrA:focus, 
.Menu_menuLink__3BNrA:hover  {
	opacity: 1;
    color: #4B6483;
}
.DashboardAccount_dashboardAccountName__1iv8b, 
.DashboardAccount_dashboardAccountName__2aUEw{
	color: #fff;
}
.DashboardAccount_dashboardAccountLink__2MUS1, 
.DashboardAccount_dashboardAccountLink__3kLKe{
	color: #8083A3;
}
.DashboardReport_dashboardReport__2pPWu, .DashboardReport_dashboardReport__pltpd{
	border: 1px solid #33465D !important;
}
.DashboardReport_dashboardReportContent__27oxP, 
.DashboardReport_dashboardReportContent__2gJ6Q{
	color: #6987AC !important;
}
.DashboardReport_dashboardReportTitle__2NQlo, 
.DashboardReport_dashboardReportTitle__KGIZR{
	color: #fff !important;
}
.DashboardTimeLeft_bar__3x1w2, .DashboardTimeLeft_bar__sFNAU{
	background-color: #394D65 !important;
}
.DashboardSchedule_dashboardSchedule__1wCqI, 
.DashboardSchedule_dashboardSchedule__2qpMl {
    margin: 25px 0;
    padding-top: 0 !important;
}
/*.DashboardSchedule_dashboardScheduleItem__3HhGR:first-child 
.DashboardSchedule_dashboardScheduleHeading__2hv_s,
.DashboardSchedule_dashboardScheduleItem__3YZWn:first-child .DashboardSchedule_dashboardScheduleHeading__2dBts {
    display: none;
}*/
.startMeetingHeading{
    display: none;
}
.dashboardStartMeeting {
    color: #fff;
}
.dashboardMeetingWrap .startButtonWrap{
	display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
.startButtonWrap .startButton {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 9.6rem;
    max-width: 100%;
    height: 3.2rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    border: 1px solid #1F2D3D;
    border-radius: 4px;
    transition: all .3s;
    color: #1F2D3D;
}
.LiveChatLink_button__Efe9h, .LiveChatLink_button__LefkT{
	background-color: #1CC69D !important;
}
.DashboardSchedule_dashboardScheduleEventItem__3n62Q,
.DashboardSchedule_dashboardScheduleEventItem__1dxHd {
    border: 1px solid #394D65 !important;
}
.DashboardMeeting_dashboardMeetingAvatar__29Gt0,
.DashboardMeeting_dashboardMeetingAvatar__2TeMq {
    background-color: #22C19B !important;
}
.DashboardMeeting_dashboardMeetingCol__1Rsvg > *,
.DashboardMeeting_dashboardMeetingCol__24bUj > *{
    color: #fff !important;
}
.DashboardSchedule_dashboardScheduleDate__3pGw-, 
.DashboardRecentCalls_DashboardRecentCallsTitle__2QJqk,
.DashboardSchedule_dashboardScheduleDate__2GzEl{
    color: #fff !important;
}
.DashboardRecentCalls_DashboardRecentCallsSubtitle__1GDRc{
    color: #394D65 !important;
}
.dashboardCreate_bottom_text{
    display: none;
}
.DashboardMobileMenu_DashboardMobileMenuItemTitle__3v0Zz,
.DashboardMobileMenu_DashboardMobileMenuItemTitle__n1yq2{
    color: #fff !important; 
}
.DashboardMobileMenu_DashboardMobileMenuItemActive__ejW4u .DashboardMobileMenu_DashboardMobileMenuItemTitle__3v0Zz,
.DashboardMobileMenu_DashboardMobileMenuItemActive__2HmzT .DashboardMobileMenu_DashboardMobileMenuItemTitle__n1yq2 {
    color: #4B6483 !important;
}
.DashboardUserInfo_slider__1HzY7 .swiper-slide,
.DashboardUserInfo_slider__3AQm_ .swiper-slide{
    background-color: transparent !important;
}
.DashboardSearch_DashboardSearchHead__1WDXa,
.DashboardSearch_DashboardSearchHead__3Di7u {
    background-color: #202D3D;
    border: 1px solid #394D65;
    border-radius: 7px;
}
.DashboardSearch_DashboardSearchInput__ALOnU{
    font-weight: 300;
    color: #6F90B7;
}
input.DashboardSearch_DashboardSearchInput__ALOnU::placeholder{
    color: #6F90B7;
}
.DashboardSearch_DashboardSearchClose__1hxPL,
.DashboardSearch_DashboardSearchClose__25Ypq{
    color: transparent;
}
.talks_list_item .talks_message, 
.talks_list_item .talks_message:hover, .talks_list_item .talks_message:focus{
    background-color: transparent;    
}
.talks_list_item .talks_message_descr .talks_message_user_name {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
}
.talks_list_item .talks_message_descr .talks_message_date {
    color: #6F90B7;
    font-size: 14px;
    line-height: 18px;
}
.DashboardChat_dashboardChat__yUsKF,
.DashboardChat_dashboardChat__2gPbt{
    background-color: #202D3D;
}
.DashboardChat_dashboardChatBack__1cEK8, 
.DashboardChat_dashboardChatHeadInfo__1hs3E > *,
.DashboardChat_dashboardChatBack__3ZwpK,
.DashboardChat_dashboardChatHeadInfo__LBJXo > *{
    color: #fff;
}
.DashboardChat_dashboardChatBack__1cEK8:hover, 
.DashboardChat_dashboardChatBack__1cEK8:focus,
.DashboardChat_dashboardChatBack__3ZwpK:hover,
.DashboardChat_dashboardChatBack__3ZwpK:focus{
    color: #fff;
}
.DashboardReminder_dashboardReminderIn__13yr5,
.DashboardReminder_dashboardReminderIn__2ws5B{
    border-bottom: none;
    background-color: #00FFC2;
}
.DashboardReminder_dashboardReminderContent__3mhx4,
.DashboardReminder_dashboardReminderLink__2flwq,
.DashboardReminder_dashboardReminderContent__3A4ct,
.DashboardReminder_dashboardReminderLink__3Lgfi{
    color: #1F2D3D;
    font-weight: 400;
}
.DashboardReminder_dashboardReminderLink__2flwq{
    border-radius: 4px;
    padding: 8px 16px;
}
.DashboardReminder_dashboardReminderLink__2flwq:hover, .DashboardReminder_dashboardReminderLink__2flwq:focus{
    color: initial;
}
.DashboardReminder_dashboardReminder__3RhUU:not(:last-child),
.DashboardReminder_dashboardReminder__1tTr2:not(:last-child){
    border-bottom: none;
}
.talks_message_user_photo{
    background-color: #547598 !important;
}






.ClassesPopup_button__NwZIn{

}
.ClassesPopup_button__NwZIn:focus, .ClassesPopup_button__NwZIn:hover {

}
.ClassesPopup_btnWrap__36r6Y {

}
.ClassesPopup_tableItem__2IMhv:first-child {
    width: 20%;
}
.ClassesPopup_tableItem__2IMhv:nth-child(2) {

}
.ClassesPopup_tableItem__2IMhv:nth-child(3) {

}
.ClassesPopup_tableItem__2IMhv:nth-child(4) {

}
.ClassesPopup_tableHead__3hDB0{

}
.ClassesPopup_tableItem__2IMhv{

}
.DashboardSchedule_dashboardInfo__1ECF5{

}
.DashboardSchedule_dashboardSchedule__1wCqI{

}
/*.DashboardUserInfo_item__8HU3J {
    display: inline-flex;
    width: 46%;
    float: left;
}
.DashboardUserInfo_item__8HU3J:nth-of-type(odd) {
    margin-right: 25px;
}*/
.dashboard_ai_assistant {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}
.ai_item.ai_image {
    width: 48%;
    position: relative;
}
.ai_item.ai_content{
    width: 51%;
}
.ai_item.ai_logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 15.6%;
    padding-top: 15px;
}
.ai_item {
    padding: 10px;
}
.ai_item.ai_content {
    padding: 31px 2px 29px;
}
.content_li {
    display: flex;
    align-items: center;
    margin-bottom: 1.1rem;
    padding-right: 2rem;
}
.li_icon {
    margin-right: 15px;
    width: 15px;
    height: 15px;
}
.li_icon .icon_image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.li_title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #707070;
}
.ai_heading {
    margin-bottom: 15px;
    color: #333333;
    font-size: 22px;
    line-height: 30px;
}
.DashboardAccount_dashboardAccount__2Rwhj{

}
.Popup_container__1wnHP.Popup_containerV4__31Z02{

}
.HelpPopup_helpSearch__22DAK{
}
.HelpPopup_helpSearchInput__3xsyF{

}
.ai_image .image_container{
    text-align: center;
}
.ai_image .image_assistant{
    max-width: 366px;
    bottom: 0;
    position: absolute;
    left: -29px;
}
.DashboardReport_dashboardReport__2pPWu{

}
.ai_logo .image_logo {
    width: -webkit-fill-available;
}
.DashboardTimeLeft_progress__3luJj{

}
.DashboardTimeLeft_block__2QEKT {

}
.DashboardTimeLeft_title__3mg86{
}
.DashboardReport_dashboardReportIndicator__VB-1J {

}
.DashboardReport_dashboardReportTitle__2NQlo {

}
.timeLeft_block {
    width: 100%;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    margin: 10px 0 22px;
}
.timeLeft_bar {
    height: 7px;
    border-radius: 10px;
    background-color: #394D65;
    width: 33.3333%;
}

.timeLeft_bar:first-child {
    background-color: #1CC69D;
}

.timeLeft_bar:not(:last-child){
    margin-right: 12px;
}
.timeLeft_progress{
    height: 100%;
    width: 0;
    border-radius: 10px;
    background-color: #1CC69D;
}
.DashboardUserInfo_item__s7vNR:nth-child(2) .DashboardTimeLeft_bar__sFNAU .DashboardTimeLeft_progress__3s2lL{
    width: 8% !important;
    background-color: #1CC69D;
}
.DashboardMeeting_dashboardMeeting__1BUhk{
}
.DashboardMeeting_dashboardMeetingCol__24bUj > * {
}
.DashboardMeeting_dashboardMeetingDescr__2FjdE{
}
.dashboardMeetingAction {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
}
.dashboardMeetingAction > * {
    margin-right: 16px;
    display: flex;
    cursor: pointer;
}
.actionWrap .btn_edit {
    width: 17px;
    height: 17px;
}
.actionWrap .btn_cancel {
    width: 24px;
    height: 24px;
}
.actionWrap {
    transition: opacity .3s;
}
.actionWrap:hover {
    opacity: .7;
}
.DashboardSchedule_dashboardScheduleEventItem__1dxHd {

}
.DashboardSchedule_dashboardScheduleEventItem__1dxHd:not(:last-child) {

}
.dashboardStartMeeting {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 19px 15px;
    cursor: pointer;
}
.dashboardMeetingWrap {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
}
.startIconWrap {
    display: flex;
    margin-right: 15px;
}
.startIconWrap .start_icon {
    width: 40px;
    height: 40px;
}
.startContentWrap {
    font-size: 18px;
}
.DashboardSchedule_dashboardScheduleItem__3HhGR:not(:last-child) {

}
.DashboardSchedule_dashboardScheduleItem__3HhGR:first-child .DashboardSchedule_dashboardScheduleHeading__2hv_s{
}
.dashboardCreateMeetingWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    cursor: pointer;
}
.dashboardCreateMeetingWrap .createIconWrap {
    margin-bottom: 10px;
}
.createIconWrap .start_icon {
    width: 45px;
    height: 45px;
}
/*.TalkerPage_col__1MwKU.TalkerPage_talksCol__342Nb{
    background: linear-gradient(130deg, rgba(161, 109, 157, 1) 5%, rgba(197, 85, 135, 1) 60%);
}*/
.dashboardCreate_bottom_text{
    text-align: right;
    margin-bottom: 22px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}
.DashboardUserInfo_root__14SU- {

}
.DashboardUserInfo_root__14SU-.DashboardUserInfo_rootGridMod__NnyXG{

}
.DashboardMeeting_dashboardMeetingTime__179Cg, .DashboardMeeting_dashboardMeetingName__1qI-V, .DashboardMeeting_dashboardMeetingDescr__2FjdE {

}
.BillingPopup_billing__3g1pm {

}
.BillingPopup_tableHead__piMmH {

}
/*.BillingPopup_tableItem__1F3F1:first-child{
    width: 16% !important;
}
.BillingPopup_tableItem__1F3F1:nth-child(2) {
    width: 26% !important;
}
.BillingPopup_tableItem__1F3F1:nth-child(3) {
    width: 29% !important;
}
.BillingPopup_tableItem__1F3F1:nth-child(4) {
    width: 29% !important;
}*/
.BillingPopup_tableItem__1F3F1 .cost{
}
.BillingPopup_tableItem__1F3F1 {

}
.BillingPopup_billingTitle__1wCmP, .BillingPopup_billingValue__3-5LH {

}
.BillingPopup_billingCol__3-oIa:first-child .BillingPopup_billingValue__3-5LH, 
.BillingPopup_billingCol__3-oIa .BillingPopup_billingTitle__1wCmP{
    margin-right: 30px;
}
.BillingPopup_billingCol__3-oIa{
}
.BillingPopup_tableRow__1bbhU > *{
}
.BillingPopup_tableRow__1bbhU > *:not(:last-child) {
}

.bottomFooter_title {
    margin-bottom: 1px;
    line-height: 18px;
}
.bottom_footer .bottomFooter_title .bottomFooter_logo {
    width: 58px;
    height: auto;
}
.bottom_menuList .bottom_menuItem:not(:last-child) {
    margin-bottom: -1px;
}
.bottom_menuList .bottom_menuItem{
    line-height: 24px;
}
.bottom_menuList .bottom_menuItem .bottom_menuLink {
    color: #6f90b7;
    text-decoration: none;
    font-size: 11px;
}

.DashboardSearch_DashboardSearchItemText__1leVY,
.DashboardSearch_DashboardSearchItemTitle__31bEB,
.DashboardSearch_DashboardSearchInput__ALOnU,
.DashboardSearch_DashboardSearchItemText__1CVMV,
.DashboardSearch_DashboardSearchItemTitle__P4VYN,
.DashboardSearch_DashboardSearchInput__8oVfw
{
    color: #fff;
}


@media only screen and (max-width: 1199px){
    .swiper .DashboardReport_dashboardReport__pltpd,
    .swiper .DashboardReport_dashboardReport__2pPWu{
        background-color: #202D3D !important;
    }
    .DashboardChat_dashboardChatHead__DBnW2,
    .DashboardChat_dashboardChatHead__1-z_Y{
        background-color: #202D3D;
    }
    .DashboardChat_dashboardChat__yUsKF,
    .DashboardChat_dashboardChat__2gPbt{
        z-index: 100;
    }
    .DashboardSearch_DashboardSearchClose__1hxPL,
    .DashboardSearch_DashboardSearchClose__25Ypq{
        margin-right: 1rem;
        margin-top: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media screen and (max-width: 1023px){
    .DashboardSearch_DashboardSearchHead__1WDXa, .DashboardSearch_DashboardSearchHead__3Di7u {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #394D65;
    }
    .DashboardSidebar_header__2OpUm,
    .DashboardSidebar_header__mm1cq{
        background: #202D3D;
        border-bottom: 1px solid #394D65;
    }
    .DashboardSidebar_container__xNuRR,
    .DashboardSidebar_container__1d9Yh{
        background: #202D3D;
    }
    .DashboardRecentCalls_DashboardRecentCalls__lKtVT  {}

    .TalkerPage_dashboard__2UxFA .Popup_container__3uGgG,
    .TalkerPage_dashboard__2F6NX .Popup_container__1wnHP {
        display: block;
    }
    .TalkerPage_dashboard__2UxFA .Popup_closeButton__1_dMQ,
    .TalkerPage_dashboard__2F6NX .Popup_closeButton__ZLIIy{
        margin-bottom: 1rem;
    }
    .DashboardSidebar_header__mm1cq{
    }
}
@media screen and (max-width: 767px){
    .DashboardSchedule_dashboardInfo__1ECF5{
    }
    .dashboard_ai_assistant {
        display: block;
    }
    .ai_item{
        width: 100% !important;
    }
    .ai_item.ai_image{
        margin-left: -20px;
    }
    .ai_image .image_assistant{
        position: inherit;
    }
    .ai_item.ai_content{
        padding: 25px 10px;
    }
    .ai_logo .image_logo {
        width: auto;
    }
    .DashboardUserInfo_root__14SU-{
    }
    .DashboardUserInfo_rootGridMod__NnyXG .DashboardUserInfo_item__8HU3J:not(:last-child){

    }
    .DashboardMeeting_dashboardMeetingCol__24bUj>* {
    }
    .dashboardMeetingAction{
        top: inherit;
    }
    .DashboardSchedule_dashboardScheduleEventItem__1dxHd{
    }
    .DashboardSchedule_dashboardScheduleEventItem__3n62Q,
    .DashboardSchedule_dashboardScheduleEventItem__1dxHd{
        padding: 16px 15px !important;
    }
}
@media only screen and (max-height: 539px){
    .bottom_footer {
        margin-bottom: 25px;
    }
}


@media only screen and (min-height: 540px){
    .bottom_footer {
        position: fixed;
        bottom: 28px;
        z-index: 100;
    }
}

@media screen and (min-width: 768px){
	.Popup_container__1wnHP.Popup_containerV2__3N4Kq {
	}
    .DashboardUserInfo_rootGridMod__NnyXG .DashboardUserInfo_item__8HU3J{
    }
    .Popup_container__1wnHP.Popup_containerV2__3N4Kq {
    }
}

@media screen and (min-width: 1024px){
   
    .DashboardUserInfo_item__8HU3J:not(:last-child) {
    }
    .DashboardAccount_dashboardAccount__2Rwhj:not(:last-child){

    }
    
    .TalkerPage_col__2K3cd.TalkerPage_sidebarCol__2UVLl, 
    .TalkerPage_col__1MwKU.TalkerPage_sidebarCol__1CBY8{
        border-right: 1px solid #394D65;
    }
    .TalkerPage_col__2K3cd.TalkerPage_sidebarCol__2UVLl:after, 
    .TalkerPage_col__1MwKU.TalkerPage_sidebarCol__1CBY8:after{
        height: 0 !important;
    }
    .TalkerPage_col__2K3cd.TalkerPage_talksCol__23bET,
    .TalkerPage_col__1MwKU.TalkerPage_talksCol__342Nb{
        border-left: 1px solid #394D65;
    }
    .TalkerPage_col__2K3cd.TalkerPage_talksCol__23bET:before,
    .TalkerPage_col__1MwKU.TalkerPage_talksCol__342Nb:before{
        height: 0 !important;
    }
    .TalkerPage_col__2K3cd.TalkerPage_sidebarCol__2UVLl,
    .TalkerPage_col__1MwKU.TalkerPage_sidebarCol__1CBY8{
        padding-bottom: 2.8rem !important;
        width: 16% !important;
        padding-right: 2.6rem !important;
    }
    .TalkerPage_col__2K3cd.TalkerPage_sidebarCol__2UVLl .DashboardSidebar_sidebar__UWWDM,
    .TalkerPage_col__1MwKU.TalkerPage_sidebarCol__1CBY8 .DashboardSidebar_sidebar__2wOyq{
        float: right;
    }
    .TalkerPage_col__2K3cd.TalkerPage_infoCol__287xe{
        padding: 1.8rem !important;
    }
    .TalkerPage_col__2K3cd.TalkerPage_talksCol__23bET{
        padding: 2.8rem 2.2rem 2rem 2.2rem !important;
    }
    .TalkerPage_col__2K3cd.TalkerPage_talksCol__23bET{
        width: 29.4% !important;
    }
    .DashboardSearch_DashboardSearch__2tJtc,
    .DashboardSearch_DashboardSearch__1sfHg{
        padding: 0 !important;
    }
    .DashboardSearch_DashboardSearchHead__1WDXa,
    .DashboardSearch_DashboardSearchHead__3Di7u{
        padding: 14px 10px !important;
    }
    .DashboardSearch_DashboardSearch__2tJtc.DashboardSearch_DashboardSearchActive__IzQrD,
    .DashboardSearch_DashboardSearch__1sfHg.DashboardSearch_DashboardSearchActive__3yIdN {
        background: transparent;
    }
}

@media screen and (min-width: 1200px){
    .DashboardReminder_dashboardReminder__3RhUU,
    .DashboardReminder_dashboardReminder__1tTr2{
        padding-bottom: 0;
        margin-bottom: 18px;
        padding-top: 15px;
    }
}

@media screen and (min-width: 1400px){
    .TalkerPage_col__2K3cd.TalkerPage_sidebarCol__2UVLl,
    .TalkerPage_col__1MwKU.TalkerPage_sidebarCol__1CBY8{
        padding-left: 12.8rem !important;
    }
}