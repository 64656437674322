@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------BaseNavigation

.navigation {
  &.authNavigation {
    position: absolute;
    bottom: 2rem;
    left: 0;

    // max-width: var(--side-col);
    width: 100%;
    // padding-left: 9.2rem;
    padding: 0 1.8rem;

    @include bp($point_3) {
      display: none;
    }
  }
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin: 0 -1.8rem -1rem 0;
}

.item {
  position: relative;
  padding: 0 1.8rem 1rem 0;

  &::after {
    position: absolute;
    display: block;
    right: 0.9rem;
    top: calc(50% - 0.5rem);
    width: 2px;
    height: 2px;
    background-color: #a7a7a7;
    content: '';
  }

  &:last-child::after {
    display: none;
  }
}

.link {
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;

  &:after {
    position: absolute;
    bottom: -2px;
    left: 0;

    display: block;
    height: 1px;
    width: 100%;

    opacity: 0;
    background-color: #a7a7a7;
    transition: opacity ease-in 0.3s;
    content: '';
  }

  &:hover {
    text-decoration: none;
  }

  &:hover:after {
    opacity: 1;
  }
}
//---------------------------------------------BaseNavigation###
