.react-calendar {
  // margin-top: -3rem;
  width: 100%;
  max-width: 100%;
  background: white;
  font-family: $font_1;
  line-height: 1.125em;

  button {
    font-family: $font_1;
  }
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  justify-content: flex-start;

  @include bp($point_2, min) {
    margin-bottom: 3rem;
    padding-left: 1rem;
  }

  @include bp($point_3) {
    margin: 0 0 1.6rem;
  }
}
.react-calendar__navigation button {
  min-width: 1.6rem;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: none;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;

  @include bp($point_3) {
    margin-bottom: 1rem;
  }
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 1.4rem;
  font-weight: 300;
  color: #959595;

  @include bp($point_3) {
    font-size: 1.2rem;
  }

  abbr {
    border: none;
    text-decoration: none;
  }
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days {
  @include bp($point_3) {
    padding: 0 0.3rem;
  }
}
.react-calendar__month-view__days__day--weekend {
  // color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile:disabled {
  abbr {
    background-color: transparent;
    color: rgba($black, 0.2);
    border-color: transparent !important;
  }
}
.react-calendar__tile:enabled {
  abbr {
    border: 1px solid $blue_3;
    background-color: $blue_3;
    color: $white;
  }
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  abbr {
    background-color: $blue_2;
    border-color: $blue_2;
  }
}
.react-calendar__tile--now {
  abbr {
    background: #ffff76;
  }
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  abbr {
    background: #ffffa9;
  }
}
.react-calendar__tile--hasActive {
  background: $green_1;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  abbr {
    background: $green_1;
  }
}
.react-calendar__tile--active:enabled {
  abbr {
    background-color: $white;
    color: $black;
  }
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  abbr {
    background: $white;
    border-color: $blue_3;
    color: $blue_3;
  }
}
.react-calendar--selectRange .react-calendar__tile--hover {
  abbr {
    background-color: #e6e6e6;
  }
}

//

.react-calendar__navigation__label {
  text-align: left;
  flex-grow: 0 !important;
  color: $blue_3;
}

.react-calendar__navigation__label__labelText {
  font-size: 1.5rem;
  color: $blue_3;
  font-weight: 500;
  text-transform: capitalize;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  // order: 2;
  margin-right: 0.8rem !important;

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.react-calendar__navigation__next-button {
  order: 3;
  margin-left: 0.4rem !important;

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  position: relative;

  font-size: 0;

  &:before,
  &:after {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 2px;
    height: 0.8rem;

    background-color: $blue_3;
    border-radius: 1rem;
    transform-origin: 50% 50%;
  }

  &:before {
    margin: -0.6rem 0 0;
  }

  &:after {
    margin: -0.2rem 0 0;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  // opacity: 0;
  // pointer-events: none;
}

.react-calendar__tile {
  @extend %global_flex_block_row_nowrap_center_center;

  border-radius: 1rem;

  @include bp($point_2, min) {
    padding: 1.1rem 0.4rem;
    font-size: 1.5rem;
  }

  @include bp($point_3) {
    padding: 0.2rem 0.2rem;
    font-size: 1.4rem;
  }

  abbr {
    @extend %global_flex_block_row_nowrap_center_center;

    @include bp($point_2, min) {
      width: 4.4rem;
      height: 4.4rem;
    }

    @include bp($point_3) {
      width: 4.3rem;
      height: 4.3rem;
    }
  }

  // &.border_mod {
  //   abbr {
  //     border: 1px solid $blue_3;
  //   }
  // }
}

.calender_wrap {
  @include bp($point_2, min) {
    margin: 0 0 2.5rem;
  }
}

.calendar_border {
  @include bp($point_4 - 1) {
    width: 100%;
    margin: 0 2rem;
    border: 1px solid #eceef5;
  }
}
