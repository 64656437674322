@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AuthLayoutCol
.col {
  &:first-child {
    @include bp($point_2, min) {
      position: relative;

      max-width: var(--side-col);
      width: 100%;
      height: 100%;

      border-right: 1px solid $gray_4;
    }

    @include bp($point_3) {
      display: none;
    }
  }

  &:last-child {
    display: flex;
    height: 100%;

    overflow: hidden;
    overflow-y: auto;

    @include bp($point_2, min) {
      flex-direction: row;

      padding: 2.8rem 2rem 4rem 5rem;
      flex-grow: 1;
    }

    @include bp($point_3) {
      width: 100%;
      padding: 2.4rem $gl_mobile_indent 4rem $gl_mobile_indent;
    }
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.colIn {
  width: 100%;
  margin: auto 0;

  @include bp($point_2, min) {
    max-width: 48.4rem;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
//---------------------------------------------AuthLayoutCol###
