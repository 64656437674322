@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------CreateClassForm
$item_hr_offset: 2.2rem;
$item_vr_offset: 2rem;

.container {
  margin-bottom: 2rem;
}

.list {
  @include bp($point_4, min) {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: wrap;

    margin-right: -$item_hr_offset;
    margin-bottom: -$item_vr_offset;
  }
}

.item {
  @include bp($point_4, min) {
    width: 100%;

    padding-right: $item_hr_offset;
    padding-bottom: $item_vr_offset;
  }

  @include bp($point_4 - 1) {
    &:not(:last-of-type) {
      margin-bottom: $item_vr_offset;
    }
  }

  &.itemHalfMod {
    @include bp($point_4, min) {
      width: 50%;
    }
  }

  &.price_mod {
    display: flex;
  }

  &.itemCalendar {
    position: relative;
  }
}

//---------------------------------------------CreateClassForm###
