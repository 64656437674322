@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------SummaryPopup

.item {
	position: relative;

	&:not(:last-child) {
		margin-bottom: 1.6rem !important;
	}
}

.block {
	position: relative;
	margin-bottom: 0 !important;
}

.countBtn {
	position: absolute;
	top: 50%;
	right: 1.6rem;

	z-index: 3;

	width: 1.5rem;
	height: 1.5rem;
	transform: translateY(-50%);

	color: #BABABA;
	transition: color .3s;

	cursor: pointer;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	&:hover {
		color: $black;
	}
}