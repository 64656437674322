@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AuthHeader
.header {
  &.offsetMod {
    margin-bottom: 2.8rem;
  }

  &.dividerMod {
    padding-bottom: 1.8rem;
    border-bottom: 1px solid $gray_10;
    margin-bottom: 2rem;
  }
}

.title {
  // margin-bottom: 0.5rem;
  color: $black_2;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.29;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
}

.adress {
  margin-bottom: 1.4rem;
  color: $blue_3;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 0.88;
}

.subTitle {
  font-size: 1.3rem;
  font-weight: 500;
  color: #898989;
}

.logo {
  display: block;
  margin-bottom: 1.6rem;

  @include bp($point_2, min) {
    width: 3.7rem;
    height: 2.6rem;
  }

  @include bp($point_3) {
    width: 3.2rem;
    height: 2.4rem;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}
//---------------------------------------------AuthHeader###
