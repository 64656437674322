//---------------------------------------------sections

.section_wrap {
  display: flex;
  flex-direction: column;

  height: calc(100vh - var(--height-header));
  overflow-y: auto;

  .base.no_header_mod & {
    height: 100%;
  }

  &.overflow_mod {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.profile_mod {
    @include bp($point_2, min) {
      margin-top: calc(var(--height-header) * -1);
      padding-top: var(--height-header);
      padding-bottom: calc(var(--height-header) + 3rem);
      height: 100vh;
    }

    @include bp($point_3) {
      padding: 1.4rem 0 5.2rem;
    }
  }
}

.section {
  position: relative;
  width: 100%;
  // height: calc(var(--full-screen) - var(--height-header));
  flex-grow: 1;

  // overflow: hidden;

  font-size: 1rem;

  // &.height_mod {
  // 	height: auto;
  // 	overflow: auto;
  // }

  &.overflow_mod {
    overflow: scroll;
  }

  &.full_mod {
    height: var(--full-screen);
  }

  &.full_mod_v2 {
    min-height: 100vh;
  }

  // &.scroll_mod {
  // 	overflow-y: auto;
  // 	height: 100%;
  // }

  &.popup_scroll_mod {
    overflow-y: auto;
    height: 100%;
  }

  &.wrap_mod {
    @extend %global_flex_block_row_wrap_flex-start;

    // @include bp($point_3) {
    // 	overflow-y: auto
    // }
    //max-height: 80vh;
  }

  &.center_mod {
    @extend %global_flex_block_row_nowrap_flex-start;
  }

  &.pos_relative {
    position: relative;
  }

  &.default_mod {
    // @include bp($point_2, min) {
    // 	overflow-y: auto;
    // }

    @include bp($point_6 + 1, min) {
      padding: 4.4rem $gl_indent;
    }

    @include bp($point_6) {
      padding: 4.4rem 2.2rem;
    }
  }

  &.default_mod_v2 {
    // @include bp($point_2, min) {
    // 	overflow-y: auto;
    // }

    @include bp($point_6 + 1, min) {
      padding: 4.4rem $gl_indent;
    }

    @include bp($point_6) {
      padding: 2.4rem 2.2rem 3rem;
    }
  }

  &.active_call_width {
    width: 25vw;
    height: 100%;
    position: absolute;
    right: 0;
  }

  // &.mob_full_mod {
  // 	@include bp($point_3) {
  // 		display: flex;
  // 		flex-direction: column;
  // 		height: calc(var(--full-screen) - var(--height-header));
  // 		padding: 0 1.6rem;

  // 		overflow: hidden;
  // 	}
  // }

  @include bp($point_3) {
    // overflow-y: auto;
  }
}

.section_google {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: max-content;
  align-items: center;
  padding-top: 2em;
  border-top: 2px solid rgb(238, 237, 237);
}

.section_google_in {
  margin-left: 3em;
  display: flex;
  flex-direction: column;
}

.section_role_pro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section_role_text {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(173, 170, 170);
}

.section_col_in {
  @include bp($point_3) {
    height: 100%;
    padding: 2.8rem 0 0;
    overflow-y: auto;
    overflow-x: hidden;

    &.no_offset_mod {
      padding: 0;
    }
  }

  &.height_mod {
    height: 100%;
  }
}

.scroll_wrap {
  &.flex_mod {
    @extend %global_flex_block_row_wrap_flex-start;
  }

  height: 100%;

  @include bp($point_3) {
    flex-grow: 1;
    margin: 0 -2rem;
    padding: 0 2rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.section_col {
  height: 100%;
  max-height: 100%;

  // overflow-x: hidden;
  // overflow-y: auto;

  @include bp($point_3) {
    width: 100%;
  }

  &.pos_relative_mod {
    position: relative;
  }

  &.text_mod {
    @include bp($point_1, min) {
      width: 38%;
      padding: 3rem 5.6rem 4rem 7.6rem;
    }

    @include bp($point_1 - 1) {
      @include flex_block(row, wrap, center, center, center);

      z-index: 2;
      position: relative;
      width: 100%;
      padding: 2.2em;
    }
  }

  &.role_mod {
    border: 2px solid rgb(238, 237, 237);
    border-radius: 5px;
    padding: 1em 5em 2em 5em;
    max-width: 20em;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    font-weight: 700;
    margin: 1em;
    height: auto;
    cursor: pointer;
    z-index: 1;

    &.cat_mod {
      border: 3px solid #4e9aa9;
      padding: 1em 0.5em;
    }
  }

  &.role_mod_inner {
    flex-direction: column;
  }

  &.signup_mod {
    @include bp($point_1, min) {
      width: 61%;
      padding: 18rem 8.6rem 0rem 0rem;
    }
  }

  &.login_mod {
    @include bp($point_1, min) {
      width: 64%;
      padding: 0rem 0rem 0rem 9rem;
    }
    @include bp($point_1 - 1) {
      @include flex_block(row, wrap, center, center, center);

      z-index: 2;
      position: relative;
      width: 100%;
      padding: 2.2em;
    }
    @include bp($point_4) {
      @include flex_block(row, wrap, center, center, center);
      z-index: 2;
      position: relative;
      width: 100%;
      padding: 5.2em;
    }
  }

  &.image_mod {
    @include bp($point_1, min) {
      width: 36%;
    }

    @include bp($point_1 - 1) {
      z-index: 1;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      &:before {
        position: absolute;
        top: 0;
        left: 0;

        content: '';

        width: 100%;
        height: 100%;

        background-color: $white;

        opacity: 0.8;
      }
    }
  }

  &.col_mod {
    @extend %global_flex_block_column_nowrap_flex-start_stretch;
  }

  &.sidebar_mod_no_border {
    position: relative;
    padding: 1.8rem 1.6rem;

    width: var(--sidebar-indent);
  }

  &.sidebar_mod {
    position: relative;
    padding: 1.8rem 1.6rem;

    width: var(--sidebar-indent);

    @include bp($point_3) {
      height: auto;
      width: 100%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;

      content: '';

      width: 1px;
      height: 100%;

      background-color: var(--border-color);

      @include bp($point_3) {
        display: none;
      }
    }

    &.no_border {
      // margin-top: -10em;

      &::before {
        width: 0px;
      }
    }

    &.both_bar_mod {
      height: 100%;

      @include bp($point_3) {
        height: fit-content;
      }

      &:after {
        position: absolute;
        top: 0;
        right: 0;

        content: '';

        width: 1px;
        height: 100%;

        background-color: var(--border-color);

        @include bp($point_3) {
          display: none;
        }
      }
    }

    &.left_bar_mod {
      &:before {
        left: auto;
        right: 0;
      }
    }

    &.right_bar_mod {
      &:before {
        left: 0;
      }
    }
  }

  &.sidebar_2_mod {
    @media (max-width: 1200px) and (min-width: 1024px) {
      width: 25rem;
    }
  }

  &.content_mod {
    @include bp($point_2, min) {
      width: calc(100% - var(--sidebar-indent));
      padding: 1.4rem 3.2rem 3.4rem 4rem;
    }

    @include bp($point_3) {
      width: 100%;
      padding: 1.4rem 1.6rem;
      overflow: hidden;
      height: auto;
      max-height: none;
    }
  }

  &.content_mod_v2 {
    @include bp($point_2, min) {
      width: calc(100% - var(--sidebar-indent));
      padding: 1.4rem 3.2rem 0 4rem;
    }

    @include bp($point_3) {
      width: 100%;
      padding: 1.4rem 1.6rem;
      overflow: hidden;
      height: auto;
      max-height: none;
    }
  }

  &.content_2_mod {
    @include bp($point_1 + 1, min) {
      // width: calc(100% - var(--sidebar-indent) - 9rem);
      width: calc(100% - var(--sidebar-indent));
      // border-right: 1px solid var(--border-color);
    }

    @include bp($point_1) {
      width: calc(100% - var(--sidebar-indent));
    }

    @include bp($point_3) {
      width: 100%;
    }
    padding: 2.6rem 2rem;
  }

  &.content_3_mod {
    width: calc(100% - (var(--sidebar-indent)) * 2);
    padding: 2.6rem 2rem;

    @include bp($point_1) {
      flex-grow: 1;
    }

    @include bp($point_3) {
      width: 100%;
      height: auto;
    }
  }

  &.border_right_mod {
    border-right: 1px solid var(--border-color);
  }

  &.offset_mod {
    @include bp($point_2, min) {
      padding: 2.8rem 3.4rem;
    }

    @include bp($point_3) {
      padding: 2.8rem 2rem;
    }
  }

  &.offset_mod_v2 {
    @include bp($point_2, min) {
      padding: 2.8rem 3.4rem;
    }

    @include bp($point_3) {
      padding: 2.8rem 2rem 0;
    }
  }

  &.mob_full_mod {
    @include bp($point_3) {
      display: flex;
      flex-direction: column;
      height: calc(var(--full-screen) - var(--height-header));
      padding: 0 2rem;

      overflow: hidden;
    }
  }

  &.no_mob_offset {
    @include bp($point_3) {
      padding-bottom: 0;
    }
  }

  &.no_mob_top_offset {
    @include bp($point_3) {
      padding-top: 0;
    }
  }

  &.no_offset_bottom {
    padding-bottom: 0;
  }

  &.width_mod {
    width: 100%;
    padding-top: 0;
    //align-items: center;
    //border-right: none;
  }

  &.active_call_width {
    height: 100vh;
  }

  &.height_auto {
    height: auto;
  }

  &.remove {
    display: none;
  }

  &.padding_mod {
    padding: 2.6rem 0rem 2.6rem 4rem;

    @include bp($point_5) {
      padding: 2.6rem 1rem 2.6rem 1rem;
    }
  }
}

.section_in {
  .section.center_mod & {
    @include bp($point_2, min) {
      margin: auto;
    }
  }

  .section.mob_full_mod & {
    @include bp($point_3) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &.wrap_mod {
    @extend %global_flex_block_row_wrap_flex-start_stretch;

    margin: 0 -3.2rem 0 0;
  }

  &.v2_mod {
    max-width: 55.4rem;
    margin: 0 auto;
  }

  &.v3_mod {
    max-width: 42rem;
    margin: 0 auto;
  }

  &.wrap_2_mod {
    @extend %global_flex_block_row_wrap_flex-start_stretch;
  }

  &.flex_mod {
    @extend %global_flex_block_row_nowrap_flex-start_stretch;

    @include bp($point_1 - 1) {
      flex-wrap: wrap;
    }
  }

  &.height_mod {
    height: 100%;
  }

  &.height_mod_v2 {
    height: calc(100% - 195px);

    @include bp($point_1) {
      height: calc(100% - 213px);
    }
  }

  &.overflow_hidden {
    overflow: auto;
  }

  &.overflow_mod {
    overflow: hidden;
  }

  &.wrap_3_mod {
    padding: 2.2rem 2.2rem 0;
    width: 100%;
    border-radius: 1rem;

    @include bp($point_2, min) {
      border: 1px solid $gray;
    }
  }

  &.wrap_4_mod {
    padding: 2.2rem 2.2rem;
    width: 100%;

    border-radius: 1rem;

    @include bp($point_2, min) {
      border: 1px solid $gray;
    }
  }

  &.wrap_5_mod {
    padding: 2.2rem 2.2rem 0;
    width: 100%;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    height: 100%;

    @include bp($point_2, min) {
      border-top: 1px solid $gray;
      border-left: 1px solid $gray;
      border-right: 1px solid $gray;
    }

    @include bp($point_5) {
      padding: 2.2rem 0rem 0;
    }
  }

  &.offset_mod {
    margin-bottom: 3.2rem;
  }

  &.mobile_max_width {
    max-width: 100%;
  }
}

.section_heading {
  text-align: center;

  &.offset_mod {
    margin-bottom: 2rem;
  }

  &.offset_v2_mod {
    margin-bottom: 2.6rem;
  }

  &.offset_v3_mod {
    margin-bottom: 6rem;
  }

  &.hr_lvl_mod {
    text-align: left;
  }
}

.section_in_col {
  width: 50%;
  padding: 0 3.2rem 0 0;

  &.border_mod {
    border-right: 1px solid $gray;
  }

  &.v2_mod {
    width: 25%;

    .wrap_2_mod & {
      padding: 4rem 3.2rem 0 0;
    }
  }

  &.v3_mod {
    width: 75%;
    padding: 0 3.2rem;

    .wrap_2_mod & {
      padding: 4rem 3.2rem;
    }
  }

  // REFACTOR
  &.v4_mod {
    width: calc(50% + 5.4rem);

    &:last-child {
      width: calc(50% - 5.4rem);
      padding-right: 0;
    }
  }

  &.v5_mod {
    @include bp($point_1, min) {
      width: 51.8%;
    }

    @include bp($point_1 - 1) {
      width: 100%;
      padding-right: 0;
    }
  }

  &.height_mod {
    height: 100%;
  }

  &.v6_mod {
    width: 48.2%;
    padding-right: 0;
  }
}

.section_title {
  width: 100%;

  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.4;
  color: $black_2;

  &.xxl_mod {
    font-size: 3.6em;
  }

  &.xl_mod {
    font-size: 2.2rem;
    line-height: 1.45;
  }

  &.l_mod {
    font-size: 1.8rem;
    line-height: 1.45;
    padding-top: 1rem;
    color: #4e9aa9;
  }

  &.md_mod {
    @include bp($point_2, min) {
      font-size: 1.7rem;
    }

    @include bp($point_3) {
      font-size: 1.4rem;
    }
  }

  &.inline_mod {
    overflow: visible;
  }

  &.sm_mod {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &.center_mod {
    text-align: center;
  }

  &.center_lvl_mod {
    text-align: left;
  }

  &.offset_mod {
    margin-bottom: 1rem;
  }

  &.offset_2_mod {
    @include bp($point_2, min) {
      margin-bottom: 2.4rem;
    }

    @include bp($point_3) {
      margin-bottom: 0.6rem;
    }
  }

  &.offset_3_mod {
    margin-bottom: 1.6rem;
  }

  &.offset_4_mod {
    @include bp($point_2, min) {
      margin-bottom: 5.4rem;
    }

    @include bp($point_3) {
      margin: 0 0 2rem;
    }
  }

  &.offset_5_mod {
    margin-bottom: 2.7rem;
  }

  // @include bp($point_3) {
  // 	font-size: 2.6rem;
  // }

  &.call_live {
    font-size: 1.8rem;
  }
}

.section_descr {
  width: 100%;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: $lilac;

  @include bp($point_3) {
    margin-bottom: 5rem;
  }

  &.center_lvl_mod {
    text-align: center;
  }

  &.role_mod {
    font-size: 1.6rem;
    font-weight: 400;
  }

  &.cat_text_mod {
    font-size: 1.8em;
    font-weight: 400;
  }

  &.sm_mod {
    font-size: 1.4rem;
  }

  &.offset_mod {
    // margin: 0 0 2.6rem;
    margin-bottom: 2.6rem;
  }

  &.offset_v1_mod {
    margin-bottom: 1.8rem;
  }

  &.offset_v2_mod {
    margin-bottom: 6rem;
  }

  &.offset_v3_mod {
    margin-bottom: 4.6rem;
  }

  &.offset_v4_mod {
    margin-bottom: 2.8rem;
  }

  &.offset_v5_mod {
    margin: 2.8rem 0;

    &.offset_extra_top {
      margin-top: 1.8rem;
    }
  }

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.color_gray_mod {
    opacity: 0.6;
    margin: 0.1em 0 0.5em;
  }

  &.mobile_margin_mod {
    margin-bottom: 0;
  }

  &.reset_password_mod {
    color: $green_1;
    cursor: pointer;
    padding-right: 1.5rem;
  }

  &.mobile_margin_mod_v2 {
    @include bp($point_5) {
      margin-bottom: 0;
    }
  }

  &.pro_login_mod {
    position: absolute;
    top: 2em;
    right: 2em;
    display: flex;
    justify-content: flex-end;
    font-size: 1.6em;
  }

  &.text_overflow_mod {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.center_decor_mod {
    @extend %global_flex_block_row_wrap_center;

    span {
      position: relative;

      background-color: $white;
      z-index: 1;

      &::before,
      &::after {
        @extend %vert_centr;

        position: absolute;

        content: '';

        width: 8.2rem;
        height: 1px;

        background-color: #ececf2;
      }

      &::before {
        right: calc(100% + 1.8rem);
      }

      &::after {
        left: calc(100% + 1.8rem);
      }
    }
  }
}

.section_row {
  @include flex_block(row, wrap, flex-start, center);
  height: 100%;

  &.join_mod {
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 1.7rem;
    @include bp($point_3) {
      align-items: baseline;
      flex-direction: column;
    }
  }

  &.role_mod {
    flex-direction: row;
    width: max-content;
    align-items: baseline;
    max-height: 50rem;
  }

  &.cat_mod {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }

  &.v2_mod {
    @extend %global_flex_block_row_wrap_flex-start;
  }
}

.section_w_img {
  &.full_mod {
    height: 100%;
    width: 100%;
  }
}

.section_link {
  @extend %global_flex_block_row_wrap_center_center;

  padding: 1rem 2rem;
  min-height: 6.8rem;

  font-size: 1.7rem;
  font-weight: 700;
  color: #262626;

  border-radius: 0.6rem;
  border: 1px solid $gray;
  transition: all 0.3s;
  text-align: center;

  &.v2_mod {
    font-size: 2rem;
    font-weight: 400;
    color: #707070;

    @include bp($point_3) {
      font-size: 1.7rem;
    }
  }

  &.v3_mod {
    position: relative;

    padding: 1rem 9rem;
    font-size: 3.2rem;

    @include bp($point_3) {
      font-size: 2.2rem;
      padding: 1rem 4rem;
      min-height: 5.5rem;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    border-color: $black;
    color: $black;
  }

  @include bp($point_3) {
    display: block;
    min-height: 4rem;
    text-align: center;
  }
}

.section_link_in {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: absolute;
  top: 0;
  right: 1rem;

  height: 100%;

  font-size: 2rem;
  color: #707070;
  opacity: 0.64;

  @include bp($point_3) {
    font-size: 1.4rem;
  }

  &.input_mod {
    color: black;
    border: none;
    font-size: 36px;
    text-align: center;
    box-shadow: none;
    left: 5%;
    @include bp($point_6) {
      left: -3%;
    }
    @include bp($point_7) {
      left: -7%;
    }
    @include bp($point_8) {
      left: -17%;
    }
  }

  &.app_mod {
    left: -40%;
  }

  &.currency {
    color: black;
    right: 65%;
    font-size: 24px;
    opacity: 1;
    @include bp($point_7) {
      right: 70%;
    }
  }
}

.section_link_block {
  border-bottom: 1px solid $gray;

  @include bp($point_2, min) {
    margin: 0 0 3.4rem;
    padding: 0 0 4.8rem;
  }

  @include bp($point_3) {
    padding: 0 0 3.5rem;
    margin: 0 0 3rem;
  }

  &.no_border_mod {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0.5em;
  }
}

//BOOKING SIDE BAR - UNDER 1024PX
.bookSideBar {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border-bottom: var(--border-color) 2px solid;
  padding-bottom: 2.8rem;

  &-photo {
    grid-column: 1 / span 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;

    img {
      border-radius: 50%;
      object-position: center;

      @media screen and (min-width: 768px) {
        height: 12rem;
      }
    }
  }

  &-info {
    grid-column: 3 / span 3;
    align-content: space-between;
    justify-self: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_name {
      font-size: 3rem;

      @media screen and (min-width: 768px) {
        font-size: 4rem;
      }
    }

    &_rate {
      font-size: 1.6rem;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
      }
    }
  }
}

//BOOKING SIDEBAR - 1024PX AND ABOVE
.bookSideBarLg {
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(5, minmax(0, 1fr));

  &-top {
    grid-row: 1 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: var(--border-color) 2px solid;
    padding-bottom: 2rem;

    &_photo {
      img {
        border-radius: 50%;
        object-position: center;
        height: 10rem;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 3.7rem;

      #bookHeader-short {
        padding-top: 0.75rem;
        font-size: 1.7rem;
        color: #9a9ba6;
      }

      #bookHeader-name {
        font-size: 4rem;
      }

      #bookHeader-rate {
        font-size: 2.4rem;
        font-weight: 500;
        color: #888ba9;

        span {
          font-size: 1.75rem;
        }
      }
    }
  }

  &-mid {
    grid-row: 3 / span 1;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: rgb(100, 103, 103);
    font-weight: 500;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: var(--border-color) 2px solid;

    a {
      color: #40ab7b;
    }
  }

  &-bottom {
    grid-row: 4 / span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;

    &_detail {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 1rem 0 1rem 0;

      span {
        font-size: 1.7rem;
      }
      &.link {
        cursor: pointer;
        color: $green_1;
      }
      &.link:hover {
        text-decoration: underline;
      }
    }

    &_icon {
      width: 2.5rem;
      height: 2.5rem;
      fill: #b5b5b5;
      margin-right: 2rem;
    }

    &_social {
      margin-top: 1.5rem;

      #social_icon_link {
        background-color: #40ab7b;
      }

      &-icon {
        height: 2rem;
        fill: #40ab7b;
      }
    }
  }
}
