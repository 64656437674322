@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.loadingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: center;
}

.loadingLogo {
	width: 4rem;
	height: 2.8rem;

	color: $white;
	opacity: .19;

	&:not(:last-child) {
		margin-bottom: 2.2rem;
	}
}

.loadaingTitle {
	font-size: 2rem;
	text-align: center;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}
}

.loadaingInfo {
	font-size: 1.5rem;
	text-align: center;

	&:not(:last-child) {
		margin-bottom: 3.6rem;
	}
}

.loadaingBar {
	position: relative;
	width: 26.2rem;
	height: 2px;
	margin: 0 auto;
}

.loadaingBarIn {
	position: absolute;
	width: 100%;
	height: 100%;

	background-color: $green_3;
	box-shadow: 0 0 1rem $green_3;
	border-right: .4rem solid $white;

	animation: loading 2.5s ease-out infinite;
	transform-origin: 0 0;
}

@keyframes loading {
	0% {
		transform: scaleX(0);
	}

	100% {
		opacity: 0;
		transform: scaleX(100%);
	}
}