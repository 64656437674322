//---------------------------------------------talks_sidebar
$talks_message_hr_padding: 1.4rem;
$talks_message_vr_padding: 1.05rem;
$talks_message_mobile_vr_padding: 1.4rem;

.talks_sidebar {}

.talks_sidebar_heading {
  padding-left: $talks_message_hr_padding;
  padding-bottom: 2.8rem;
  margin-bottom: 1.9rem;
  margin-top: 0.7rem;
  border-bottom: 1px solid $gray;
}

.talks_list {
  // max-height: 65vh;
  // overflow-y: scroll;
  min-height: 10rem;
  // background-color: tomato;

  &.limit_mod {
    max-width: 37.8rem;
    margin: 0 auto;

    @include bp($point_3) {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}

.talks_list_item {
  width: 100%;
  margin-bottom: 0.8rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.talks_list_item>button {
  width: 100%;
}

.talks_no_previous {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  color: $gray_7;

  @include bp($point_2, min) {
    margin: 10rem 0;
  }

  @include bp($point_3) {
    margin: 4rem 0;
  }
}

.talks_message {
  @include flex_block(row, nowrap, flex-start, center);

  position: relative;

  width: 100%;

  border-radius: 1.2rem;
  background-color: $white;

  cursor: pointer;
  transition: background-color 0.3s ease;

  min-width: none;

  @include bp($point_0, min) {
    padding: $talks_message_vr_padding 1.8rem;
  }

  @include bp($point_0 - 1, $point_2) {
    padding: $talks_message_vr_padding .4rem;
  }

  @include bp($point_3) {
    padding: $talks_message_mobile_vr_padding 0;
    background: none;
  }

  &:hover,
  &:focus {
    background-color: $gray_6;
  }
}

.talks_message_user_photo {
  flex-shrink: 0;
  width: 3.6rem;
  height: 3.6rem;
  background-color: #e0e0e0;

  border-radius: 2rem;
  overflow: hidden;
}

.talks_message_user_photo_in {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.talks_message_descr {
  padding-left: 1.7rem;
  flex: 1 1 3.6rem;
  padding-right: 1.6rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 300;

  .talks_message_no_record_state & {
    // padding-right: 8rem;
  }
}

.talks_message_user_name {
  max-width: 19rem;

  display: flex;
  flex-wrap: wrap;
  color: $black_2;
  font-weight: 400;
  font-size: 1.4rem;
}

.talks_message_user_name__item {
  max-width: 100%;

  &:nth-child(n + 3) {
    display: none;
  }

  &:after {
    content: ',';
    margin-right: .4rem;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.talks_message_user_name__more {
  color: #007EFF;
}

.talks_message_date {
  color: $lilac;
  color: #8083A3;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.talks_message_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3.6rem;
  height: 3.6rem;

  color: $lilac;
  border: 1px solid $gray;
  border-radius: 1rem;
  background-color: $white;

  &.position_mod {
    position: absolute;
    top: 50%;
    right: $talks_message_hr_padding;

    margin-top: -1.8rem;

    @include bp($point_3) {
      right: 0;
    }
  }
}

.talks_message_no_record {
  position: absolute;

  font-size: 1.4rem;
  color: #c9c9c9;

  @include bp($point_2, min) {
    top: $talks_message_vr_padding;
    right: $talks_message_hr_padding;
  }

  @include bp($point_3) {
    top: $talks_message_mobile_vr_padding;
    right: 0;
  }
}

//---------------------------------------------talks_sidebar###