//---------------------------------------------Logo
.logo {
  display: block;
  width: 3.8rem;
  height: 2.6rem;

  margin-bottom: 5.4rem;
}

.logoImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
//---------------------------------------------Logo###
