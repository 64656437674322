//-------------------------------------------------------inputs and selects
%form_field_default {
  display: block;
  width: 100%;
  height: $input_height;
  padding: $input_offset;
  font-size: $input_font_size;
  font-weight: 700;
  font-family: $font_1;
  color: $input_text_color;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;

  &::-webkit-input-placeholder {
    color: $input_placeholder_color;
  }

  &::-moz-placeholder {
    color: $input_placeholder_color;
  }

  &:-ms-input-placeholder {
    color: $input_placeholder_color;
  }
}

// %form_field_default_hover {

// }

%form_field_default_active {
  border-color: $green_1;
}

.form_rate {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 5.4rem;
  border: 1px solid #d8d8d8;
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #007eff;
  font-family: 'FuturaPT', sans-serif;
  box-shadow: 0 0 0 1px #d8d8d8;
  background-color: #ffffff;
}

.form_cell,
.form_field {
  position: relative;

  &.hidden_field {
    display: none;
  }

  &.offset_mod {
    margin-bottom: 1.4rem;
  }

  // &.form_cell_v1_mod {}
}

.form_cell_title,
.form_field_label {
  &.hline_hide_mod,
  &.hidden_mod {
    display: none;
  }

  // &.form_v1_mod {}
}

.form_field_wrap {
  position: relative;

  // &.form_v1_mod {}
}

.form_field {
  height: fit-content;

  &.default_mod,
  &_input {
    @extend %form_field_default !optional;
    //word-break: break-word;
    //height: fit-content;
    //line-break: loose;
    //word-wrap: break-word;
    //overflow-wrap: break-word;
    &.text_box {
      height: 14vh;
      margin-bottom: -14vh;
      padding-top: 10px !important;

      @include bp($point_1) {
        height: 9vh;
      }
      @include bp($point_4) {
        height: 10vh;
      }
      @include bp($point_5) {
        height: 15vh;
      }
    }
    // &:focus {
    //   @extend %form_field_default_active !optional;
    // }

    &:hover {
      @extend %form_field_default_hover !optional;
    }

    &.color_mod {
      color: $black_2;
    }

    &.color_mod_v2 {
      color: $black_2;
      background-color: white !important;
    }

    &.app_mod {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 0;
    }

    &.border_mod {
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 0 0.5em;
      margin-top: 0.2em;
      width: 100%;

      @include bp($point_5) {
        width: 100%;
      }
    }

    &.password_letter_spacing {
      letter-spacing: 0px;
    }

    &.height_mod {
      height: 4rem !important;
    }

    &.bg_mod {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>")
        no-repeat;
      background-size: 12px;
      background-position: calc(100% - 20px) center;
      background-repeat: no-repeat;
      background-color: white;
    }

    .form_field.profile_mod & {
      padding: 1.7rem 2rem 2rem 2rem;
      height: 5rem;
      border: 1px solid #ddd;

      @include bp($point_1 - 1) {
        padding: 1rem 2rem;
      }

      &.padding_mod {
        @include bp($point_1 - 1) {
          padding: 0 0;
          text-align: center;
        }
      }
    }

    .form_field.icon_mod & {
      padding: 1.7rem 2rem 2rem 1.6rem;
    }

    &.dropdown_width {
      width: 50%;
      @include bp($point_4) {
        width: 100%;
      }
    }

    &.letter_space_mod {
      letter-spacing: -5px;
    }

    &.padding_mod {
      padding: 0 1em;
    }
  }

  &.border_bottom_mod {
    border-bottom: 1px solid $gray;
  }

  &.profile_mod {
    position: relative;

    padding-bottom: 0.8rem;
  }

  &.profile_mod_v2 {
    position: relative;

    padding-bottom: 0.4rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #ddd;

    &.margin_mod {
      margin-bottom: 1rem;
    }
  }

  &_search {
    display: block;
    width: 100%;
    height: 4.4rem;
    padding: 0 5rem 0 2.2rem;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: $font_1;
    color: $black_2;
    border: 1px solid $gray;
    border-radius: 1rem;

    &::-webkit-input-placeholder {
      color: rgba($black, 0.3);
    }

    &::-moz-placeholder {
      color: rgba($black, 0.3);
    }

    &:-ms-input-placeholder {
      color: rgba($black, 0.3);
    }
  }
}

.menuLink {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: $black_2;

  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &.menuLinkActive {
    text-decoration: none;
    opacity: 0.5;
  }
}

.confirm_button {
  display: flex;
  flex-direction: column;
}

.cancel_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.form_error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5.1rem;
  margin-bottom: 1em;
  padding: 0.8rem 1rem;
  width: 100%;
  background-color: red;
  color: $white;
  font-family: $font_1;
  font-size: 1.9em;
  border-radius: 1rem;
  text-align: center;
}

.form_span_wrap {
  display: flex;

  @include bp($point_4 - 1) {
    margin-bottom: 1em;
  }
}

.form_span_wrap_v2 {
  display: flex;
  font-size: 1.4em;
  padding: 1.7em 0;
}

.form_span_wrap_v3 {
  display: flex;
  font-size: 1.4em;

  &.profile_mod {
    margin: 0.7em 0 0.7em 0;
  }
}

.form_term_conditions {
  margin-top: -0.7em;

  &.profile_mod {
    color: #747474;
    font-weight: 500;
    font-size: 1.3rem;
  }
}

.form_field_label {
  color: $lilac;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: $font_1;

  @include bp($point_3) {
    display: block;
    // margin: 0 0 -1rem;
  }

  &.radio_field {
    margin: 0;
  }

  &.no_border_mod {
    border: none;
    font-size: 1.7rem;
  }

  &.no_border_mod_v2 {
    border: none;
    font-size: 1.4rem;
  }

  // .form_field.profile_mod & {
  // 	position: absolute;

  // 	top: 0;
  // 	left: 0;
  // }
}

.form_field_icon {
  position: absolute;
  top: 50%;

  cursor: pointer;

  transition: color 0.3s ease;

  &.search_mod {
    right: 1.4rem;

    width: 2.6rem;
    height: 2.6rem;
    margin-top: -1.3rem;

    color: $gray_7;

    &:hover,
    &:focus {
      color: $black_2;
    }
  }
}

//---------------------------------------------inputs and selects###

//---------------------------------------------radio-buttons

.radio_field {
  display: block;
  width: 100%;

  border-radius: 1rem;
  border: 1px solid $gray;
  background-color: $white;
}

.form_field_radiocheck {
  display: none;
}

.form_field_text_radio {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: relative;
  padding: 1rem 1rem 1rem 6.2rem;
  min-height: 5rem;
  width: 100%;

  font-size: 1.4rem;
  color: #555555;

  cursor: pointer;

  &:hover,
  &:focus {
    &:before {
      background-color: rgba($black, 0.3);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.8rem;

    width: 1.6rem;
    height: 1.6rem;
    margin: -0.8rem 0 0;
    border-radius: 100%;
    background-color: #e8e8e8;
    cursor: pointer;
    transition: all 0.3s;

    .form_field_radiocheck:checked + & {
      background-color: $green_1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 5rem;
    height: 100%;
    width: 1px;
    background-color: $gray;
  }
}

.radio_icon {
  height: 2rem;
}

.radio_icon_img {
  display: block;
  height: 100%;
  max-width: 100%;
}

//------------------------time_picker_radio-buttons

.form_field.time_picker_mod {
  @include bp($point_2, min) {
    margin-bottom: 2rem;
  }

  @include bp($point_3) {
    margin: 0 0 1.2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .form_field_text_radio {
    @extend %transition_all;

    height: 4.2rem;
    padding: 1rem;

    color: $green_1;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 7.2rem;

    box-shadow: inset 0 0 0 1px $gray;
    border-radius: 1rem;
    background-color: transparent;

    @include st_btn();

    &::before,
    &::after {
      display: none;
    }

    @include bp($point_3) {
      height: 3.8 rem;
      min-height: 3.4rem;
      font-size: 1.4rem;
      line-height: 3.4rem;
    }
  }

  .form_field_radiocheck:checked + .form_field_text_radio,
  .form_field_text_radio:hover,
  .form_field_text_radio:focus {
    background-color: $green_1;
    box-shadow: none;
    color: white;
    &.inactive_mod {
      background-color: #555555;
    }
  }
}

//---------------------------------radio-buttons##########

//---------------------------------------------checkboxes
.form_field_radiocheck {
  display: none;
}

.form_field_text_check {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  position: relative;
  cursor: pointer;
  padding-left: 3.2rem;

  &:hover,
  &:focus {
    &:before {
      background-color: rgba($black, 0.3);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;

    width: 2rem;
    height: 2rem;
    margin-top: -1rem;

    border-radius: 100%;
    background-color: #f0f0f3;
    cursor: pointer;
    transition: all 0.3s;

    .form_field_radiocheck:checked + & {
      background-color: $green_1;
    }
  }
}

//---------------------------------------------checkboxes ###
