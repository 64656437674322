@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------InputLabel
.label {
  font-size: 1.6rem;
  // font-weight: 500;
  font-family: $font_1;
  color: #bababa;
  letter-spacing: -0.03rem;
}

.labelVariantSuffix {
  display: block;

  @include bp($point_4, min) {
    position: absolute;
    top: 50%;
    right: 1.4rem;

    transform: translateY(-50%);
    background-color: $white;
  }

  @include bp($point_4 - 1) {
    margin-top: 0.6rem;
  }
}

.labelVariantPreffix {
  margin-right: 2rem;
  flex-shrink: 0;

  font-size: 1.3rem;
  font-weight: 700;
  color: $black;
}

//---------------------------------------------InputLabel###
