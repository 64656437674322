//---------------------------------------------Logo
.logo {
  display: inline-flex;
}

.image {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.logoPrimaryVariant {
  color: #414141;
}

.logoSecondaryVariant {
  color: #fff;
}

.logoBaseSize {
  width: 3.6rem;
  height: 2.6rem;
}

.logoLgSize {
  width: 5rem;
  height: 3.6rem;
}
//---------------------------------------------Logo###
