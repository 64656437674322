@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.DashboardMobileMenu {
  display: flex;
  align-items: center;

  margin: 0 calc(#{$gl_mobile_indent} * -1) -0.4rem;
  padding: 0 16px 2rem 20px;
  background-color: #ededed;

  @include bp($point_2, min) {
    display: none;
  }
}

.DashboardMobileMenuAdd {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 2.4rem;
  margin-top: -0.2rem;
}

.DashboardMobileMenuList {
  display: flex;
}

.DashboardMobileMenuItem {
  display: flex;
  align-items: center;

  &.DashboardMobileMenuHidden {
    display: none;
  }

  &:not(:last-child) {
    margin-right: 2.4rem;
  }
}

.DashboardMobileMenuItemTitle {
  font-size: 1.6rem;
  font-weight: 500;
  color: #414141;

  .DashboardMobileMenuItemActive & {
    color: #007eff;
  }

  &:not(:last-child) {
    margin-right: 0.4rem;
  }
}

.DashboardMobileMenuItemCounter {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.2rem;
  height: 2.2rem;

  font-size: 1.2rem;
  font-weight: 500;
  color: #414141;

  border-radius: 50%;
  background-color: #dbdbdb;
}

.DashboardRecentCallsTalks {
  @include bp($point_2, min) {
    max-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px !important;
      border-radius: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset -3px 0 0px #e0e4f4;
      background-color: transparent;
      border-radius: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
