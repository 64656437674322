// select_time_popup
.select_time_popup {
  background: $white;

  @include bp($point_2, min) {
    position: fixed;
    top: 0;
    left: 0;

    padding-top: var(--height-header);
    width: 100%;
    height: 100vh;

    opacity: 0;

    pointer-events: none;

    transition: opacity 0.3s ease;
  }

  &.select_time_popup--open {
    @include bp($point_2, min) {
      opacity: 1;

      pointer-events: auto;
    }
  }
}

// select_time_popup ###

// select_time_popup_back
.select_time_popup_back {
  @extend %global_flex_block_row_nowrap_flex-start_center;
  @extend %transition_color;

  position: fixed;
  top: calc(var(--height-header) + 5rem);
  left: 10%;

  color: $blue;

  cursor: pointer;

  @include bp($point_3) {
    display: none;
  }

  &:hover {
    color: rgba($blue, 0.8);
  }
}

.select_time_popup_back__icon {
  margin-right: 1.1rem;
  width: 2.2rem;
  height: 2.7rem;
}

.select_time_popup_back__text {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.13;
  font-family: $font_1;
}

// select_time_popup_back ###

// select_time_popup_scroll_area
.select_time_popup_scroll_area {
  @extend %global_flex_block_row_wrap_center;

  align-content: stretch;

  width: 100%;
  height: 100%;

  overflow-y: auto;

  @include bp($point_4 - 1) {
    justify-content: flex-start;
  }
}

// select_time_popup_scroll_area ###

// select_time_popup_content
.select_time_popup_content {
  @extend %global_flex_block_column_nowrap_flex-start_center;

  box-sizing: content-box;
  width: 100%;

  @include bp($point_2, min) {
    padding: 5rem var(--gl-block-indent);
  }

  @include bp($point_3) {
    padding: 3.2rem 1.6rem;
  }

  @include bp($point_4 - 1) {
    align-items: flex-start;
  }

  @include bp($point_5, min) {
    max-width: 32rem;
  }
}

// select_time_popup_content ###

// select_time_popup_title
.select_time_popup_title {
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 0.77;
  font-family: $font_1;
  color: $black_2;

  @include bp($point_2, min) {
    margin-bottom: 1.6rem;
  }

  @include bp($point_3) {
    margin-bottom: 1.4rem;
  }
}

// select_time_popup_title ###

// select_time_popup_date_select
.select_time_popup_date_select {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  @include bp($point_2, min) {
    margin-bottom: 4rem;
  }

  @include bp($point_3) {
    margin-bottom: 2.5rem;
  }
}

.select_time_popup_date_select__button {
  @extend %transition_color;

  width: 0.8rem;
  height: 1.2rem;

  color: $blue;

  cursor: pointer;

  @include bp($point_4 - 1) {
    display: none;
  }

  &:hover {
    color: rgba($blue, 0.8);
  }
}

.select_time_popup_date_select__selected {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.13;
  font-family: $font_1;
  color: $black_2;

  @include bp($point_4, min) {
    margin: 0 1.6rem;
  }
}

// select_time_popup_date_select ###

// select_time_popup_times
.select_time_popup_times {
  width: 100%;
}

.select_time_popup_times__item {
  &:not(:last-child) {
    margin-bottom: 2.3rem;
  }
}

.select_time_popup_times__item_label {
  @extend %global_flex_block_row_nowrap_center_center;

  width: 100%;
  height: 5.4rem;

  font-weight: 500;
  font-size: 1.6rem;
  font-family: $font_1;
  text-transform: uppercase;
  color: $blue;

  border: 0.1rem solid $blue;
  border-radius: 0.6rem;
  cursor: pointer;

  transition: color 0.3s ease, background 0.3s ease;

  &:hover,
  .select_time_popup_times__item_input:checked + & {
    color: $white;

    background: $blue;
  }
}

.select_time_popup_times__item_input {
  display: none;
}

// select_time_popup_times ###
