.spinner_container {
  @extend %global_flex_block_column_wrap_flex-start_center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: fit-content;

  &.spinner_button {
    position: static;
    transform: none;
    -webkit-transform: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.spinner {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  border: 3px solid $green_1;
  border-radius: 50%;
  border-top-color: $white;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  &.btn_mod {
    width: 40px;
    height: 40px;
    border: 6px solid $white;
    border-top-color: $green_1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
