@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

//---------------------------------------------DashboardSidebar
.sidebar {
  height: 100%;

  @include bp($point_2, min) {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 2.8rem - 5.4rem);
  }

  @include bp($point_3) {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background-color: rgba($gray_5, 0.4);

      z-index: -1;
    }

    // opacity: 0;
    // pointer-events: none;

    @include z-index(sidebar);
  }
}

.sidebarContainer {
  @include bp($point_2, min) {
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  @include bp($point_3) {
    width: 82%;
    height: 100%;
    margin-left: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: auto;

  @include bp($point_3) {
    // width: 82%;
    height: calc(100% - #{$dashboard_header});
    padding: 2rem $gl_mobile_indent 2.6rem;

    background-color: $white;

    overflow: hidden;
    overflow-y: auto;
  }
}

.header {
  @include bp($point_3) {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $dashboard_header;
    padding: 0 $gl_mobile_indent;
    // width: 82%;

    background-color: $gray_10;

    justify-content: flex-end;
  }
}

.closeButton {
  position: relative;

  width: 1.4rem;
  height: 1.4rem;

  cursor: pointer;

  &:before {
    position: absolute;
    top: -2rem;
    left: -2rem;
    right: -2rem;
    bottom: -2rem;

    content: '';
  }
}

.footer {
  margin-top: auto;
}

.notification {
  font-size: 1.4rem;
}

.qrBlock {
  display: block;
  width: 11rem;
  height: 11rem;
}

//---------------------------------------------DashboardSidebar###
