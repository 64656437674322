@import 'styles/helpers/_variables.scss';

//---------------------------------------------Link
.link {
  color: $blue_3;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 0.88;

  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    text-decoration: none;

    border-color: currentColor;
  }
}
//---------------------------------------------Link###
