.signup__form {
  @include bp($point_2, min) {
    max-width: 48.5rem;
  }
}

.form_group {
  position: relative;
  font-family: $font_1;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &.profile_mod {
    flex-direction: column;
  }

  &.form_group--separator_mod {
    margin-top: -1rem;
    margin-bottom: 1.2rem;
  }

  &.form_group--session_mod {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  &.form_group--border_mod {
    padding-bottom: 1.8rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 1.8rem;
  }

  &.form_group--offset_mod {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &.form_group--offset_v2_mod {
    &:not(:last-child) {
      margin-bottom: 1.8rem;
    }
  }

  &.form_group--offset_v3_mod {
    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }
  }
}

.form_group__separator {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 300;

  color: #9a9a9a;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;

    height: 1px;

    background-color: #e5e5e5;
  }

  &:before {
    margin-right: 3rem;
  }

  &:after {
    margin-left: 3rem;
  }
}

.form_group__descr {
  font-size: 1.5rem;
  font-size: 300;
  color: #707070;

  a {
    font-weight: 500;
    color: $blue_3;
  }
}

.form_group__label {
  display: block;
  margin-bottom: 0.6rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: #747474;

  &.form_group__label--v2_mod {
    font-size: 1.3rem;

    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    strong {
      display: block;
      font-weight: 700;
    }
  }
}

.form_group__field_wrapper {
  position: relative;

  display: flex;
  align-items: center;
}

.form_group__field_block {
  position: relative;
  width: 100%;
}

.form_group__error {
  margin-top: 1rem;

  font-size: 1.4rem;
  font-weight: 300;
  color: $error_color;
}

.form_group__field {
  @extend %signup_input;

  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &.form_group__field--button_mod {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: box-shadow 0.3s;

    color: $blue_3;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px $blue_3;
    }
  }

  .form_group--amount_mod & {
    @include bp($point_2, min) {
      padding-left: 36%;
      padding-right: 46%;
    }

    @include bp($point_3) {
      padding-left: 36%;
      padding-right: 38%;
    }
  }

  .form_group--icon_mod & {
    padding-left: 5rem;
  }

  .form_group--session_mod & {
    padding-left: 2.8rem;

    font-size: 1.3rem;

    width: 13.4rem !important;
  }

  .form_group--name_mod & {
    @include bp($point_2, min) {
      padding-right: 40%;
    }

    @include bp($point_3) {
      padding-right: 45%;
    }
  }
}

.form_group__field--lg_mod {
  height: 147px;
}

textarea.form_group__field {
  min-height: 10.7rem;
  resize: vertical;
  color: black;
}

textarea.form_group__field::placeholder {
  color: $blue_3;
}

select.form_group__field {
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  box-shadow: 0 0 0 1px $blue_3;
}

.form_group__icon {
  @extend %global_flex_block_row_wrap_center_center;

  position: absolute;
  left: 1.7rem;
  top: 1.7rem;
  width: 1.9rem;
  height: 1.9rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.form_row {
  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }

  &.form_row--no_offset_mod {
    margin-bottom: 8rem;
  }

  &.form_row--session_mod {
    display: flex;

    @include bp($point_4 - 1) {
      flex-wrap: wrap;
    }
  }
}

.form_row .form_group {
  @extend %global_flex_block_row_nowrap_space_between;
}

.form_row .form_group__field {
  @include bp($point_4, min) {
    width: calc(50% - 1.15rem);
  }

  @include bp($point_4 - 1) {
    width: 100%;
  }
}

.form_row--grid_mod {
  @extend %global_flex_block_row_wrap_space_between;

  gap: 2rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
    // @include bp($point_4, min) {
    //   margin-bottom: -0.8rem;
    // }

    // @include bp($point_4 - 1) {
    //   margin-bottom: 1.2rem;
    // }
  }
}

.form_row--grid_mod .form_group {
  @include bp($point_4, min) {
    width: calc(50% - 1.15rem);
    margin-bottom: 0;
  }

  @include bp($point_4 - 1) {
    width: 100%;
  }
}

.form_row--grid_mod .form_group__field {
  width: 100%;
}

.form_group__button_icon {
  width: 2.8rem;
  height: 3rem;
  margin-right: 1.4rem;
  flex-shrink: 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.form_group__button {
  position: relative;

  height: 5.4rem;
  width: 100%;
  text-align: center;
  padding: 2rem 2.3rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-family: $font_1;
  background-color: $black_5;
  cursor: pointer;
  appearance: none;

  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background: darken($black_5, 10%);
  }

  &.size_v2_mod {
    font-size: 1.4rem;
  }

  &.style_v2_mod {
    padding: 0 1rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: $green_3;

    &:hover {
      background-color: darken($green_3, 10%);
    }
  }

  &.spinner_mod {
    margin-top: 3em;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.book_mod {
    background: #00000000;
    border: #00000000 1px solid;
    color: #00000000;
    padding-bottom: 1em;

    &:hover {
      color: #fff;
      background: #00000000;
    }
  }

  &.share_mod {
    background: #00000000;
    border: #000 1px solid;
    color: #000;
    padding-bottom: 1em;

    &:hover {
      color: #fff;
      background: #000;
    }
  }

  &.cancel_mod {
    background: #fff;
    border: #000 1px solid;
    color: #000;
    margin-top: 1em;

    &:hover,
    &:focus {
      background-color: $black;
      color: $white;
    }
  }

  &.form_group__button--icon_mod {
    padding: 2rem 5.2rem;
    border: 1px solid $blue_3;
    color: $blue_3;

    overflow: hidden;
  }

  &.form_group__button--icon_2_mod {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.4rem 5.2rem;
    border: 1px solid $blue_3;
    color: $blue_3;

    overflow: hidden;

    &:hover,
    &:focus {
      background-color: $blue_3;
      color: $white;
    }
  }

  &.form_group__button--bg_mod {
    background-color: $white;
  }

  &.form_group__button--blue_mod {
    color: $white;
    background-color: $blue_3;

    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: darken($blue_3, 10%);
    }
  }

  .form_group__button_img {
    position: absolute;
    left: 2.1rem;
    top: 50%;

    width: 2.8rem;
    height: 2.8rem;

    display: inline-block;
    transform: translateY(-50%);

    &.form_group__button_img--v2_mod {
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      margin-right: 1.2rem;
    }

    &.form_group__button_img--size_mod {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      margin-top: -0.4rem;
    }
  }

  .form_group__button_img_in {
    @extend %image_in;

    object-fit: contain;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.form_group__preffix,
.form_group__suffix {
  position: absolute;
  left: 50%;
  bottom: 1.9rem;

  font-size: 1.3rem;
  font-weight: 500;
  font-family: $font_1;
  color: #898989;

  @include bp($point_4 - 1) {
    left: 0;
  }
}

.form_group__preffix {
  transform: translate3d(-8.6rem, 0.2rem, 0);

  @include bp($point_4 - 1) {
    transform: translate3d(3.2rem, 0.2rem, 0);
  }

  .form_group--amount_mod & {
    @include bp($point_3) {
      transform: translate3d(10rem, 0, 0);
    }
  }

  .form_group--session_mod & {
    left: 1.8rem;
    transform: translateY(0.2rem) !important;

    font-size: 1.3rem;
    color: $gray_8;

    transform: none;
  }
}

.form_group__suffix {
  transform: translate3d(2.4rem, 0, 0);

  &.session_mod {
    @include bp($point_4, min) {
      transform: translate3d(-3.6rem, 0, 0);
    }

    @include bp($point_4 - 1) {
      transform: translate3d(7rem, 0, 0);
    }
  }

  .form_group--name_mod & {
    font-weight: 300;

    @include bp($point_2, min) {
      transform: translate3d(10rem, 0, 0);
    }

    @include bp($point_3) {
      transform: translate3d(3rem, 0, 0);
      left: auto;
      right: 11%;
    }
  }

  .form_group--amount_mod & {
    @include bp($point_3) {
      transform: translate3d(11vw, 0, 0);
    }
  }

  .form_group--session_mod & {
    position: static;
    transform: none;

    margin-left: 2.4rem;

    font-size: 1.3rem;
    color: #292929;
    font-weight: 700;
    transform: none;
  }
}

.form_group__divider {
  position: relative;
  margin-bottom: 2rem;
}

.form_group__divider_in {
  display: inline-block;
  position: relative;
  padding-right: 2rem;
  background-color: #fff;
  font-size: 1.5rem;
  color: #9a9a9a;
  font-weight: 300;
  z-index: 2;
}

.form_group__divider:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #eee;
}

.form_group_checkbox_w {
  @extend %global_flex_block_row_wrap_flex-start;

  .form_group__field {
    display: none;
  }

  .form_group__label {
    cursor: pointer;

    span {
      color: $blue_3;
    }
  }

  .form_group_checkbox {
    position: relative;
    display: block;

    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;

    border: 1px solid $gray_4;

    &:after {
      position: absolute;
      top: 3px;
      left: 3px;
      display: block;

      width: 1.4rem;
      height: 1.4rem;

      background-color: $blue_3;
      opacity: 0;

      content: '';
    }
  }

  .form_group__field:checked ~ .form_group_checkbox:after {
    opacity: 1;
  }
}

.form_payments {
  @include bp($point_4, min) {
    margin-left: auto;
    margin-right: 1.6rem;
  }

  @include bp($point_4 - 1) {
    width: 100%;
    margin-top: 2rem;
  }
}

.form_payments__title {
  font-size: 1rem;
  color: $gray_8;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
}

.form_payments__pic {
  max-width: 20.8rem;
}

.form_payments__img {
  display: block;
  width: 100%;
}
