@import 'styles/helpers/_variables.scss';

//---------------------------------------------AuthLayoutItem
.item {
  &:not(:last-of-type) {
    margin-bottom: 1.8rem;
  }
}

.itemDivider {
  &:not(:last-of-type) {
    border-bottom: 1px solid $gray_10;

    padding-bottom: 1.8rem;
    margin-bottom: 1.6rem;
  }
}
//---------------------------------------------AuthLayoutItem###
