@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AuthContent
.content {
  color: $gray_8;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
//---------------------------------------------AuthContent###
