//---------------------------------------------fonts
$font_path: '../../fonts/';
// $font_1: 'FuturaPT', sans-serif;
$font_1: 'Sohne', -apple-system, BlinkMacSystemFont, 'SegoeUI', sans-serif;
// $font_1: -apple-system, BlinkMacSystemFont, 'SegoeUI', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #ffffff;
$black: #000000;
$gray_6: #f8f9fc;
$purple: #de16fc;
$black_3: #2f2e41;
$lilac: #4d4d58;
$lilac_5: #7a7a7a;
$gray_3: #e2e2e2;
$pink_2: #ffb8b8;
$gray: #eceef5;
$gray_7: #bbbbbb;
$pink_3: #ffb9b9;
$lilac_3: #9698d6;
$lilac_4: #575a89;
$lilac_2: #6b59cc;
$gray_5: #f6f6f6;
$lilac_6: #7d809f;
$purple_2: #de1ffc;
$purple_3: #b620e0;
$pink: #ffa0af;
$black_2: #1a1c1d;
$black_4: #2b2b2b;
$orange: #f6933e;
$green: #28c345;
$green_1: #339f78;
$green_2: #1bebb9;
$green_3: #1CC69D;
$green_4: #00FFC2;
$blue: #4e9aa9;
$blue_2: #6ad3e8;
$blue_3: #007eff;
$gray_4: #d8d8d8;
$gray_5: #555555;
$gray_8: #747474;
$gray_9: #707070;
$gray_10: #eeeeee;
$gray_11: #484848;
$black_5: #2c2e2f;
$blue_4: #1f2d3d;

$error_color: #dd0e21;

$color_text_base: $black_2;
$color_link_base: #4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
  popup: 101,
  header: 100,
  footer: 100,
  sidebar: 101,
  live-chat-button: 100,
  talker-section: 110,
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 8.7rem;
$height_header: 8.4rem;
$dashboard_header: 5.6rem;
$page_width: 96rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 3rem;
// $gl_mobile_indent: 2rem;
$gl_mobile_indent: 24px;
$gl_radius: 0.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_0: 1200px;
$point_1: 1024px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1280px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4.5rem;
$input_signup_height: 5.4rem;
$input_offset: 0 10rem 0 0;
$input_font_size: 1.6rem;
$input_border_color: rgb(88, 80, 80);
$input_border_color_active: rgba($lilac, 0.34);
$input_placeholder_color: rgba($lilac, 0.34);
$input_text_color: $lilac;
$input_radius: $gl_radius;
//---------------------------------------------forms###