@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.message {
  max-width: 90%;

  &.messageV2 {
    margin-left: auto;
  }

  @include bp($point_2, min) {
    padding-bottom: 1.2rem;
  }

  @include bp($point_3) {
    padding-bottom: 1rem;
  }

  &.primaryVariant {
    width: 26rem;
  }

  &.secondaryVariant {
    width: 75%;
  }
}

.head {
  display: flex;
  align-items: center;
  padding-left: 1.6rem;

  font-size: 1rem;
  color: #a8a8a8;

  .messageV2 & {
    justify-content: flex-end;
  }

  .primaryVariant & {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }

  .secondaryVariant & {
    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }
}

.author {
  &:not(:last-child) {
    margin-right: 0.6rem;
  }
}

.text {
  line-height: 1.4;

  .primaryVariant & {
    position: relative;

    padding: 0.7rem 1.2rem 1rem;

    font-size: 1.5rem;
    line-height: 1.35;
    font-weight: 300;
    color: $white;

    background-color: #404040;
    border-radius: 0.7rem;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 1.2rem;

      border-style: solid;
      border-width: 1rem 1.6rem 0 0;
      border-color: #404040 transparent transparent transparent;
    }
  }

  .primaryVariant.messageV2 & {
    padding-bottom: 2.1rem;

    background-color: #0a84ff;
    border-color: #0a84ff;

    &:after {
      right: 1rem;

      border-width: 0 1.6rem 1rem 0;
      border-color: transparent #0a84ff transparent transparent;
    }
  }

  .secondaryVariant & {
    padding: 0.5rem 1.2rem;

    color: #414141;
    font-size: 1.5rem;
    letter-spacing: -0.01rem;

    border-radius: 0.7rem;
    border: 1px solid #ededed;
  }

  .secondaryVariant.messageV2 & {
    background-color: #f5f5f5;
    border: none;
  }
}
