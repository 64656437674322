@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AvailableDay
.day {
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 6rem;
  border-bottom: 1px solid $gray_10;
  padding: 0 1.2rem;

  :global(li):first-child & {
    border-top: 1px solid $gray_10;
  }
}

.col {
  width: 50%;
}

.fieldWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -1.2rem;
}

.field {
  padding-right: 1.2rem;
  width: 50%;
}

//---------------------------------------------AvailableDay###
