@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.wrap {
  position: fixed;
  bottom: 3.2rem;
  right: 4rem;

  @include z-index(live-chat-button);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;

  border-radius: 50%;
  background-color: $blue_3;

  transition: background-color 0.3s;
  cursor: pointer;

  .wrapOpen & {
    background-color: #cbcbcb;
  }

  &:hover {
    background-color: darken($blue_3, 15);
  }
}

.buttonIcon {
  width: 4.6rem;
  height: 2.6rem;
}

.buttonIconImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.error {
  font-size: 1.6rem;
  color: red;
}

// tooltip

.tooltip {
  position: absolute;
  bottom: calc(100% + 2.6rem);
  right: -0.6rem;

  width: 45.2rem;
  max-width: calc(100vw - 4rem);
  padding: 2.8rem 3rem 3rem;

  background-color: #f4f4f4;
  box-shadow: 0 0.3rem 0.6rem rgba($black, 0.16);
  border-radius: 1.5rem;

  opacity: 0;
  transform: translateY(1rem);
  pointer-events: none;

  transition: transform 0.3s, opacity 0.3s;

  .wrapOpen & {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial;
  }
}

.tooltipText {
  font-size: 1.6rem;
  line-height: 1.3;
  color: #8083a3;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 0.7rem;
  }
}

.tooltipLinkWrap {
  display: flex;
  justify-content: center;
}

.tooltipLink {
  // width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 1.9rem;
  text-align: center;
  color: $blue_3;

  text-align: center;
}

.tooltipClose {
  position: absolute;
  top: 0.3rem;
  right: 1rem;

  width: 1rem;
  height: 1rem;

  cursor: pointer;
}

.spinner {
  position: relative;
  height: 2.8rem;
  width: 100%;

  :global(.spinner) {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
}
