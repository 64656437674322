.talker_user_info_block {
  //@extend %global_flex_block_row_wrap_flex-start_center;

  width: 100%;
  padding: 1.6rem 0;

  border-radius: 1rem;
  background-color: $gray_6;

  @include flex_block(row, wrap, flex-start, center, center);

  // @include bp($point_1 + 1, min) {
  //  @include flex_block(row, wrap, flex-start, center, center);
  // }

  //@include bp($point_) {}

  &.offset_mod {
    margin-bottom: var(--gl-block-indent);
  }

  &.offset_mod_top {
    margin-top: 1.8rem;
  }

  @include bp($point_4 - 1) {
    display: none;
  }
}

.talker_user_info_block_col {
  position: relative;
  width: 26%;

  &:first-child {
    &:before {
      position: absolute;

      content: '';

      background-color: var(--border-color);
      top: 0.8rem;
      right: 0;
      bottom: 0.8rem;

      width: 1px;
    }

    @include flex_block(row, wrap, flex-start);

    width: 41%;
    padding: 0 1.6rem;
    //padding: 0 2.4rem;

    // @include bp($point_1) {
    //   //padding: 0 2.4rem 3rem;
    //   // display: flex;
    //   // flex-direction: row;
    //   // &:before {
    //   //   left: 2.4rem;
    //   //   right: 2.4rem;
    //   //   bottom: 0;

    //   //   height: 1px;

    //   //   margin-bottom: 1.5em;
    //   // }
    // }
  }

  &:last-child {
    &:before {
      position: absolute;

      content: '';

      background-color: var(--border-color);
      top: 0rem;
      left: 0;
      bottom: 0rem;

      width: 1px;
    }

    width: 33%;
    padding: 0 2em;

    // @include bp($point_1) {
    //   &:before {
    //     left: 2.4rem;
    //     right: 2.4rem;
    //     top: 0;

    //     height: 1px;
    //     margin-top: 1.5em;
    //   }
    // }

    @include bp($point_1) {
      //padding: 3rem 2.4rem 0;
      padding: 0 1.4rem;
      margin-left: 1em;
      width: 31%;
    }
  }

  // @include bp($point_1) {
  //   width: 100%;
  // }

  &.mobile_resp {
    @include bp($point_3) {
      display: flex;
      flex-direction: row;
      padding-bottom: 1.5rem;
    }
  }
}

.talker_user_bio_w {
  height: 3.5em;
  width: 100%;
}

.talker_user_bio {
  font-size: 1.2em;
  height: 3.5em;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.talker_user_bio_edit {
  color: #339f78;
  padding-top: 0.2em;
  font-size: 1.2em;
  cursor: pointer;
  width: fit-content;
}

.talker_user_photo {
  margin-right: 1.6rem;
  @include bp($point_1) {
    margin-right: 1.8rem;
  }

  // @include bp($point_6) {
  //   margin-bottom: 3rem;

  //   .user_img_w {
  //     margin: 0 auto;
  //   }
  // }

  // @include bp($point_3) {
  //   width: 40%;
  //   margin-right: 10%;
  // }
}

.talker_user {
  width: calc(100% - 14.2rem);
  height: 100%;
  min-height: 100%;

  @include bp($point_3) {
    width: 50%;
  }
}

.talker_user_name_w {
  position: relative;

  display: inline-flex;
  max-width: 100%;
  //padding-right: 4rem;
}

.talker_user_name {
  width: 100%;
  margin-right: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 1.7rem;
  font-weight: 700;
  color: $black_2;
}

.talker_user_status {
  @extend %global_flex_block_row_nowrap_flex-start_center;
}

.talker_user_status_item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 2px;

  border-radius: 50%;
  background-color: $gray_3;

  &:last-child {
    margin-right: 0;
  }

  &.active_mod {
    background-color: #b620e0;
  }
}

.talker_user_since_info {
  font-size: 1.4rem;
  color: $lilac;

  &.offset_mod {
    margin-bottom: 0.4rem;
  }
}

.talker_user_cost {
  font-size: 3.2rem;
  color: $black_2;

  &.offset_mod {
    margin-bottom: 0.4rem;
  }
}

.talker_user_payout {
  font-size: 1.4rem;
  color: $lilac;
}

.talk_user_statistic_list {
}

.talk_user_statistic_item {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  margin-bottom: 1.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.column_mod {
    flex-direction: column;
    text-align: left;
    margin: 0 2em;
  }
}

.talk_user_statistic_item_col {
  &.graph_mod {
    margin-left: auto;
  }

  &.margin_top_mod {
    margin-top: 0.5em;
  }

  &.align_mod {
    display: flex;
    width: 100%;
    text-align: left;
  }

  &.mobile_mod {
    @include bp($point_5) {
      display: flex;
      align-items: center;
      height: 3em;
    }
  }
}

.talk_user_icon {
  width: 100%;

  &.size_mod {
    width: 80%;
  }
}

.talk_user_icon_w {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.7em;
  width: 1.7em;
  margin-right: 1em;
}

.talk_statistic {
  text-align: right;
}

.talk_statistic_value {
  display: block;
  margin-bottom: 0.8rem;

  color: $black_2;
  font-size: 1.7rem;
  font-weight: 700;
}

.talk_statistic_graph {
  position: relative;

  width: 5.6rem;
  height: 0.4rem;

  background-color: $gray;
  border-radius: 0.3rem;
  overflow: hidden;
}

.talk_statistic_graph_in {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  border-radius: 0.3rem;

  .orange_mod & {
    background-color: $orange;
  }

  .green_mod & {
    background-color: $green;
  }
}
