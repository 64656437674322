@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

.card {
  height: 100%;

  @include bp($point_4, min) {
    padding: 1.6rem 3.6rem 3rem;

    background-color: #f8f8f8;
  }
}

.title {
  margin-bottom: 1rem;

  font-size: 2rem;
  font-weight: 500;
  color: #1a1c1d;
}

.item {
  &:not(:last-child) {
    @include bp($point_2, min) {
      margin-bottom: 0.4rem;
    }

    @include bp($point_3) {
      margin-bottom: 0.7rem;
    }
  }
}

.link {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 500;
  color: #007eff;

  transition: color 0.3s;

  &.linkCompleted {
    color: #707070;
    font-weight: 300;
    text-decoration: line-through;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $black;
  }
}

.icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.element {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 300;
  color: #707070;
}
