//---------------------------------------------Image
.image {
  &.default {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &.cover,
  &.contain {
    width: 100%;
    height: 100%;
  }

  &.cover {
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;
  }
}
//---------------------------------------------Image###
