// Edit these at random intervals to change the animation.
@keyframes loader {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

// Bar ============================================
.progress-bar {
  border-radius: 60px;
  overflow: hidden;
  width: 100%;

  span {
    display: block;
  }
}

.bar {
  background: rgba(0, 0, 0, 0.075);
}

.progress {
  animation: loader 1s ease running infinite;
  // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
  background: $green_1;
  color: #fff;
  padding: 2px;
  width: 0;
}

// Vertical Centering ==============================
// You don't need this to make it work on your site.
.progress-bar {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  &.talks_mod {
    top: -2%;
  }
}
