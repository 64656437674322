@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';
@import 'styles/helpers/_project_extends.scss';

//---------------------------------------------AuthLayoutRow
.row {
  height: 100vh;

  font-size: 1rem;

  overflow: hidden;

  @include bp($point_2, min) {
    @include flex_block(row, nowrap, flex-start, stretch);
  }

  @include bp($point_3) {
    @include flex_block(row, wrap, center, stretch);
  }
}
//---------------------------------------------AuthLayoutRow###
