@import 'styles/helpers/_variables.scss';
@import 'styles/helpers/_mixins.scss';

$transition: 0.5s ease-in-out;

.notes {
  max-height: 54rem;
  min-height: 34rem;

  font-size: 1.5rem;
  line-height: 1.32;
  font-weight: 300;
  color: inherit;
  padding: 1.4rem;

  // overflow: hidden;
  overflow-y: auto;

  li {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  @include bp($point_4, min) {
    padding: 2.8rem 3.5rem 1rem;
  }

  @include bp($point_4 - 1) {
    padding: 2rem 2.2rem;
  }

  p:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.container {
  // position: relative;
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  .editMode & {
    z-index: 3;
  }
}

.triggerWrap {
  position: absolute;

  right: 1.3rem;
  bottom: 1.7rem;
  z-index: 5;

  margin-left: auto;
  margin-right: auto;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5.2rem;
  height: 5.2rem;
  margin-left: auto;

  border-radius: 50%;
  overflow: hidden;

  // border: 1px solid $gray_9;
  background-color: #516f92;
  color: $white;

  transition: background 0.3s ease;
  cursor: pointer;

  // @include bp($point_4, min) {
  // 	margin-right: 2.4rem;
  // }

  // @include bp($point_4 - 1) {
  // 	margin-right: 3.8rem;
  // }

  &:hover,
  &:focus {
    background: lighten($gray_11, 8%);
  }
}

.icon {
  display: block;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.8rem;
}

.label {
  font-size: 1.4rem;
  line-height: 0;
}

.textarea {
  display: block;
  width: 100%;
  height: 100%;

  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;

  color: inherit;
  caret-color: currentColor;
  background-color: #202d3d;

  border-radius: 0;
  padding: 0;

  border: none;
  // background-clip: padding-box;
  appearance: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity $transition;

  @include bp($point_4, min) {
    padding: 2.8rem 3.5rem 1rem;
  }

  @include bp($point_4 - 1) {
    padding: 2rem 2.2rem;
  }

  .editMode & {
    opacity: 1;
    pointer-events: initial;
  }
}

.text {
  position: relative;
  z-index: 2;

  transition: opacity $transition;

  .editMode & {
    opacity: 0;
    pointer-events: none;
  }
}
